export default [
  {
    id: 2,
    pid: 1,
    district: "河南省",
    level: 1,
    childrens: [
      {
        id: 3,
        pid: 2,
        district: "洛阳市",
        level: 2,
        childrens: [
          {
            id: 4,
            pid: 3,
            district: "新安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 5,
            pid: 3,
            district: "栾川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 6,
            pid: 3,
            district: "偃师区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 7,
            pid: 3,
            district: "瀍河回族区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 8,
            pid: 3,
            district: "洛龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 9,
            pid: 3,
            district: "嵩县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 10,
            pid: 3,
            district: "涧西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 11,
            pid: 3,
            district: "伊川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 12,
            pid: 3,
            district: "孟津区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 13,
            pid: 3,
            district: "西工区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 14,
            pid: 3,
            district: "老城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 15,
            pid: 3,
            district: "汝阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 16,
            pid: 3,
            district: "宜阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 17,
            pid: 3,
            district: "洛宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 18,
        pid: 2,
        district: "三门峡市",
        level: 2,
        childrens: [
          {
            id: 19,
            pid: 18,
            district: "卢氏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 20,
            pid: 18,
            district: "湖滨区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 21,
            pid: 18,
            district: "渑池县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 22,
            pid: 18,
            district: "义马市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 23,
            pid: 18,
            district: "陕州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 24,
            pid: 18,
            district: "灵宝市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 25,
        pid: 2,
        district: "漯河市",
        level: 2,
        childrens: [
          {
            id: 26,
            pid: 25,
            district: "临颍县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 27,
            pid: 25,
            district: "召陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 28,
            pid: 25,
            district: "源汇区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 29,
            pid: 25,
            district: "舞阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 30,
            pid: 25,
            district: "郾城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 31,
        pid: 2,
        district: "许昌市",
        level: 2,
        childrens: [
          {
            id: 32,
            pid: 31,
            district: "建安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 33,
            pid: 31,
            district: "鄢陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 34,
            pid: 31,
            district: "禹州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 35,
            pid: 31,
            district: "长葛市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 36,
            pid: 31,
            district: "襄城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 37,
            pid: 31,
            district: "魏都区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 38,
        pid: 2,
        district: "南阳市",
        level: 2,
        childrens: [
          {
            id: 39,
            pid: 38,
            district: "卧龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 40,
            pid: 38,
            district: "西峡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 41,
            pid: 38,
            district: "唐河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 42,
            pid: 38,
            district: "南召县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 43,
            pid: 38,
            district: "镇平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 44,
            pid: 38,
            district: "淅川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 45,
            pid: 38,
            district: "内乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 46,
            pid: 38,
            district: "宛城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 47,
            pid: 38,
            district: "新野县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 48,
            pid: 38,
            district: "桐柏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 49,
            pid: 38,
            district: "邓州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 50,
            pid: 38,
            district: "方城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 51,
            pid: 38,
            district: "社旗县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 52,
        pid: 2,
        district: "信阳市",
        level: 2,
        childrens: [
          {
            id: 53,
            pid: 52,
            district: "罗山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 54,
            pid: 52,
            district: "淮滨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 55,
            pid: 52,
            district: "商城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 56,
            pid: 52,
            district: "浉河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 57,
            pid: 52,
            district: "固始县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 58,
            pid: 52,
            district: "平桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 59,
            pid: 52,
            district: "潢川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 60,
            pid: 52,
            district: "新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 61,
            pid: 52,
            district: "光山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 62,
            pid: 52,
            district: "息县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 63,
        pid: 2,
        district: "济源市",
        level: 2,
        childrens: [
          {
            id: 64,
            pid: 63,
            district: "济源市坡头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 65,
            pid: 63,
            district: "济源市梨林镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 66,
            pid: 63,
            district: "济源市思礼镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 67,
            pid: 63,
            district: "济源市大峪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 68,
            pid: 63,
            district: "济源市五龙口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 69,
            pid: 63,
            district: "济源市王屋镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 70,
            pid: 63,
            district: "济源市轵城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 71,
            pid: 63,
            district: "济源市玉泉街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 72,
            pid: 63,
            district: "济源市济水街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 73,
            pid: 63,
            district: "济源市沁园街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 74,
            pid: 63,
            district: "济源市下冶镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 75,
            pid: 63,
            district: "济源市克井镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 76,
            pid: 63,
            district: "济源市天坛街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 77,
            pid: 63,
            district: "济源市邵原镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 78,
            pid: 63,
            district: "济源市北海街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 79,
            pid: 63,
            district: "济源市承留镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 80,
        pid: 2,
        district: "濮阳市",
        level: 2,
        childrens: [
          {
            id: 81,
            pid: 80,
            district: "台前县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 82,
            pid: 80,
            district: "南乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 83,
            pid: 80,
            district: "范县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 84,
            pid: 80,
            district: "清丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 85,
            pid: 80,
            district: "华龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 86,
            pid: 80,
            district: "濮阳县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 87,
        pid: 2,
        district: "鹤壁市",
        level: 2,
        childrens: [
          {
            id: 88,
            pid: 87,
            district: "淇县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 89,
            pid: 87,
            district: "浚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 90,
            pid: 87,
            district: "山城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 91,
            pid: 87,
            district: "鹤山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 92,
            pid: 87,
            district: "淇滨区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 93,
        pid: 2,
        district: "郑州市",
        level: 2,
        childrens: [
          {
            id: 94,
            pid: 93,
            district: "登封市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 95,
            pid: 93,
            district: "新郑市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 96,
            pid: 93,
            district: "上街区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 97,
            pid: 93,
            district: "中牟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 98,
            pid: 93,
            district: "巩义市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 99,
            pid: 93,
            district: "惠济区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 100,
            pid: 93,
            district: "管城回族区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 101,
            pid: 93,
            district: "金水区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 102,
            pid: 93,
            district: "中原区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 103,
            pid: 93,
            district: "二七区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 104,
            pid: 93,
            district: "新密市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 105,
            pid: 93,
            district: "荥阳市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 106,
        pid: 2,
        district: "焦作市",
        level: 2,
        childrens: [
          {
            id: 107,
            pid: 106,
            district: "孟州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 108,
            pid: 106,
            district: "沁阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 109,
            pid: 106,
            district: "马村区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 110,
            pid: 106,
            district: "博爱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 111,
            pid: 106,
            district: "中站区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 112,
            pid: 106,
            district: "温县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 113,
            pid: 106,
            district: "武陟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 114,
            pid: 106,
            district: "解放区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 115,
            pid: 106,
            district: "修武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 116,
            pid: 106,
            district: "山阳区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 117,
        pid: 2,
        district: "开封市",
        level: 2,
        childrens: [
          {
            id: 118,
            pid: 117,
            district: "杞县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 119,
            pid: 117,
            district: "禹王台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 120,
            pid: 117,
            district: "祥符区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 121,
            pid: 117,
            district: "通许县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 122,
            pid: 117,
            district: "顺河回族区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 123,
            pid: 117,
            district: "龙亭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 124,
            pid: 117,
            district: "鼓楼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 125,
            pid: 117,
            district: "尉氏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 126,
            pid: 117,
            district: "兰考县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 127,
        pid: 2,
        district: "安阳市",
        level: 2,
        childrens: [
          {
            id: 128,
            pid: 127,
            district: "内黄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 129,
            pid: 127,
            district: "龙安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 130,
            pid: 127,
            district: "北关区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 131,
            pid: 127,
            district: "林州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 132,
            pid: 127,
            district: "汤阴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 133,
            pid: 127,
            district: "文峰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 134,
            pid: 127,
            district: "安阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 135,
            pid: 127,
            district: "滑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 136,
            pid: 127,
            district: "殷都区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 137,
        pid: 2,
        district: "商丘市",
        level: 2,
        childrens: [
          {
            id: 138,
            pid: 137,
            district: "宁陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 139,
            pid: 137,
            district: "永城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 140,
            pid: 137,
            district: "睢县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 141,
            pid: 137,
            district: "夏邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 142,
            pid: 137,
            district: "虞城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 143,
            pid: 137,
            district: "柘城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 144,
            pid: 137,
            district: "民权县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 145,
            pid: 137,
            district: "梁园区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 146,
            pid: 137,
            district: "睢阳区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 147,
        pid: 2,
        district: "平顶山市",
        level: 2,
        childrens: [
          {
            id: 148,
            pid: 147,
            district: "卫东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 149,
            pid: 147,
            district: "鲁山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 150,
            pid: 147,
            district: "湛河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 151,
            pid: 147,
            district: "舞钢市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 152,
            pid: 147,
            district: "叶县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 153,
            pid: 147,
            district: "新华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 154,
            pid: 147,
            district: "石龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 155,
            pid: 147,
            district: "汝州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 156,
            pid: 147,
            district: "宝丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 157,
            pid: 147,
            district: "郏县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 158,
        pid: 2,
        district: "周口市",
        level: 2,
        childrens: [
          {
            id: 159,
            pid: 158,
            district: "项城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 160,
            pid: 158,
            district: "川汇区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 161,
            pid: 158,
            district: "郸城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 162,
            pid: 158,
            district: "鹿邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 163,
            pid: 158,
            district: "商水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 164,
            pid: 158,
            district: "淮阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 165,
            pid: 158,
            district: "西华县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 166,
            pid: 158,
            district: "扶沟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 167,
            pid: 158,
            district: "太康县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 168,
            pid: 158,
            district: "沈丘县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 169,
        pid: 2,
        district: "新乡市",
        level: 2,
        childrens: [
          {
            id: 170,
            pid: 169,
            district: "凤泉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 171,
            pid: 169,
            district: "牧野区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 172,
            pid: 169,
            district: "获嘉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 173,
            pid: 169,
            district: "原阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 174,
            pid: 169,
            district: "封丘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 175,
            pid: 169,
            district: "卫辉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 176,
            pid: 169,
            district: "延津县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 177,
            pid: 169,
            district: "长垣市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 178,
            pid: 169,
            district: "红旗区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 179,
            pid: 169,
            district: "辉县市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 180,
            pid: 169,
            district: "新乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 181,
            pid: 169,
            district: "卫滨区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 182,
        pid: 2,
        district: "驻马店市",
        level: 2,
        childrens: [
          {
            id: 183,
            pid: 182,
            district: "新蔡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 184,
            pid: 182,
            district: "泌阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 185,
            pid: 182,
            district: "遂平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 186,
            pid: 182,
            district: "确山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 187,
            pid: 182,
            district: "驿城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 188,
            pid: 182,
            district: "正阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 189,
            pid: 182,
            district: "上蔡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 190,
            pid: 182,
            district: "汝南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 191,
            pid: 182,
            district: "平舆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 192,
            pid: 182,
            district: "西平县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 193,
    pid: 1,
    district: "广东省",
    level: 1,
    childrens: [
      {
        id: 194,
        pid: 193,
        district: "汕头市",
        level: 2,
        childrens: [
          {
            id: 195,
            pid: 194,
            district: "澄海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 196,
            pid: 194,
            district: "濠江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 197,
            pid: 194,
            district: "南澳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 198,
            pid: 194,
            district: "龙湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 199,
            pid: 194,
            district: "金平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 200,
            pid: 194,
            district: "潮阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 201,
            pid: 194,
            district: "潮南区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 202,
        pid: 193,
        district: "佛山市",
        level: 2,
        childrens: [
          {
            id: 203,
            pid: 202,
            district: "三水区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 204,
            pid: 202,
            district: "高明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 205,
            pid: 202,
            district: "顺德区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 206,
            pid: 202,
            district: "禅城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 207,
            pid: 202,
            district: "南海区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 208,
        pid: 193,
        district: "肇庆市",
        level: 2,
        childrens: [
          {
            id: 209,
            pid: 208,
            district: "广宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 210,
            pid: 208,
            district: "封开县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 211,
            pid: 208,
            district: "怀集县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 212,
            pid: 208,
            district: "四会市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 213,
            pid: 208,
            district: "德庆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 214,
            pid: 208,
            district: "高要区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 215,
            pid: 208,
            district: "端州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 216,
            pid: 208,
            district: "鼎湖区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 217,
        pid: 193,
        district: "惠州市",
        level: 2,
        childrens: [
          {
            id: 218,
            pid: 217,
            district: "龙门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 219,
            pid: 217,
            district: "博罗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 220,
            pid: 217,
            district: "惠东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 221,
            pid: 217,
            district: "惠阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 222,
            pid: 217,
            district: "惠城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 223,
        pid: 193,
        district: "深圳市",
        level: 2,
        childrens: [
          {
            id: 224,
            pid: 223,
            district: "宝安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 225,
            pid: 223,
            district: "南山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 226,
            pid: 223,
            district: "福田区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 227,
            pid: 223,
            district: "罗湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 228,
            pid: 223,
            district: "龙岗区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 229,
            pid: 223,
            district: "光明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 230,
            pid: 223,
            district: "龙华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 231,
            pid: 223,
            district: "盐田区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 232,
            pid: 223,
            district: "坪山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 233,
        pid: 193,
        district: "珠海市",
        level: 2,
        childrens: [
          {
            id: 234,
            pid: 233,
            district: "香洲区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 235,
            pid: 233,
            district: "斗门区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 236,
            pid: 233,
            district: "金湾区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 237,
        pid: 193,
        district: "湛江市",
        level: 2,
        childrens: [
          {
            id: 238,
            pid: 237,
            district: "吴川市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 239,
            pid: 237,
            district: "廉江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 240,
            pid: 237,
            district: "雷州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 241,
            pid: 237,
            district: "麻章区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 242,
            pid: 237,
            district: "徐闻县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 243,
            pid: 237,
            district: "坡头区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 244,
            pid: 237,
            district: "霞山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 245,
            pid: 237,
            district: "遂溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 246,
            pid: 237,
            district: "赤坎区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 247,
        pid: 193,
        district: "江门市",
        level: 2,
        childrens: [
          {
            id: 248,
            pid: 247,
            district: "鹤山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 249,
            pid: 247,
            district: "江海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 250,
            pid: 247,
            district: "开平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 251,
            pid: 247,
            district: "台山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 252,
            pid: 247,
            district: "恩平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 253,
            pid: 247,
            district: "新会区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 254,
            pid: 247,
            district: "蓬江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 255,
        pid: 193,
        district: "阳江市",
        level: 2,
        childrens: [
          {
            id: 256,
            pid: 255,
            district: "阳春市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 257,
            pid: 255,
            district: "江城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 258,
            pid: 255,
            district: "阳西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 259,
            pid: 255,
            district: "阳东区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 260,
        pid: 193,
        district: "云浮市",
        level: 2,
        childrens: [
          {
            id: 261,
            pid: 260,
            district: "郁南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 262,
            pid: 260,
            district: "罗定市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 263,
            pid: 260,
            district: "新兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 264,
            pid: 260,
            district: "云安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 265,
            pid: 260,
            district: "云城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 266,
        pid: 193,
        district: "河源市",
        level: 2,
        childrens: [
          {
            id: 267,
            pid: 266,
            district: "和平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 268,
            pid: 266,
            district: "龙川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 269,
            pid: 266,
            district: "连平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 270,
            pid: 266,
            district: "东源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 271,
            pid: 266,
            district: "紫金县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 272,
            pid: 266,
            district: "源城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 273,
        pid: 193,
        district: "汕尾市",
        level: 2,
        childrens: [
          {
            id: 274,
            pid: 273,
            district: "陆河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 275,
            pid: 273,
            district: "城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 276,
            pid: 273,
            district: "陆丰市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 277,
            pid: 273,
            district: "海丰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 278,
        pid: 193,
        district: "茂名市",
        level: 2,
        childrens: [
          {
            id: 279,
            pid: 278,
            district: "化州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 280,
            pid: 278,
            district: "电白区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 281,
            pid: 278,
            district: "信宜市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 282,
            pid: 278,
            district: "高州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 283,
            pid: 278,
            district: "茂南区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 284,
        pid: 193,
        district: "揭阳市",
        level: 2,
        childrens: [
          {
            id: 285,
            pid: 284,
            district: "普宁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 286,
            pid: 284,
            district: "惠来县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 287,
            pid: 284,
            district: "揭东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 288,
            pid: 284,
            district: "榕城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 289,
            pid: 284,
            district: "揭西县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 290,
        pid: 193,
        district: "潮州市",
        level: 2,
        childrens: [
          {
            id: 291,
            pid: 290,
            district: "饶平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 292,
            pid: 290,
            district: "湘桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 293,
            pid: 290,
            district: "潮安区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 294,
        pid: 193,
        district: "韶关市",
        level: 2,
        childrens: [
          {
            id: 295,
            pid: 294,
            district: "南雄市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 296,
            pid: 294,
            district: "仁化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 297,
            pid: 294,
            district: "乳源瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 298,
            pid: 294,
            district: "始兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 299,
            pid: 294,
            district: "武江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 300,
            pid: 294,
            district: "曲江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 301,
            pid: 294,
            district: "浈江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 302,
            pid: 294,
            district: "翁源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 303,
            pid: 294,
            district: "新丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 304,
            pid: 294,
            district: "乐昌市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 305,
        pid: 193,
        district: "清远市",
        level: 2,
        childrens: [
          {
            id: 306,
            pid: 305,
            district: "连州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 307,
            pid: 305,
            district: "连南瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 308,
            pid: 305,
            district: "连山壮族瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 309,
            pid: 305,
            district: "佛冈县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 310,
            pid: 305,
            district: "英德市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 311,
            pid: 305,
            district: "阳山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 312,
            pid: 305,
            district: "清城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 313,
            pid: 305,
            district: "清新区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 314,
        pid: 193,
        district: "东莞市",
        level: 2,
        childrens: [
          {
            id: 315,
            pid: 314,
            district: "莞城街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 316,
            pid: 314,
            district: "常平镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 317,
            pid: 314,
            district: "望牛墩镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 318,
            pid: 314,
            district: "麻涌镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 319,
            pid: 314,
            district: "松山湖管委会",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 320,
            pid: 314,
            district: "凤岗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 321,
            pid: 314,
            district: "东莞生态园",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 322,
            pid: 314,
            district: "桥头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 323,
            pid: 314,
            district: "樟木头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 324,
            pid: 314,
            district: "石龙镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 325,
            pid: 314,
            district: "寮步镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 326,
            pid: 314,
            district: "高埗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 327,
            pid: 314,
            district: "塘厦镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 328,
            pid: 314,
            district: "厚街镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 329,
            pid: 314,
            district: "谢岗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 330,
            pid: 314,
            district: "虎门镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 331,
            pid: 314,
            district: "南城街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 332,
            pid: 314,
            district: "虎门港管委会",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 333,
            pid: 314,
            district: "横沥镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 334,
            pid: 314,
            district: "企石镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 335,
            pid: 314,
            district: "东坑镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 336,
            pid: 314,
            district: "东城街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 337,
            pid: 314,
            district: "石排镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 338,
            pid: 314,
            district: "洪梅镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 339,
            pid: 314,
            district: "道滘镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 340,
            pid: 314,
            district: "沙田镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 341,
            pid: 314,
            district: "大岭山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 342,
            pid: 314,
            district: "茶山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 343,
            pid: 314,
            district: "清溪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 344,
            pid: 314,
            district: "石碣镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 345,
            pid: 314,
            district: "万江街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 346,
            pid: 314,
            district: "中堂镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 347,
            pid: 314,
            district: "黄江镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 348,
            pid: 314,
            district: "长安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 349,
            pid: 314,
            district: "大朗镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 350,
        pid: 193,
        district: "梅州市",
        level: 2,
        childrens: [
          {
            id: 351,
            pid: 350,
            district: "平远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 352,
            pid: 350,
            district: "蕉岭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 353,
            pid: 350,
            district: "兴宁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 354,
            pid: 350,
            district: "梅江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 355,
            pid: 350,
            district: "梅县区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 356,
            pid: 350,
            district: "丰顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 357,
            pid: 350,
            district: "大埔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 358,
            pid: 350,
            district: "五华县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 359,
        pid: 193,
        district: "中山市",
        level: 2,
        childrens: [
          {
            id: 360,
            pid: 359,
            district: "三角镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 361,
            pid: 359,
            district: "横栏镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 362,
            pid: 359,
            district: "五桂山街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 363,
            pid: 359,
            district: "小榄镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 364,
            pid: 359,
            district: "中山港街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 365,
            pid: 359,
            district: "神湾镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 366,
            pid: 359,
            district: "南朗街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 367,
            pid: 359,
            district: "古镇镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 368,
            pid: 359,
            district: "民众街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 369,
            pid: 359,
            district: "港口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 370,
            pid: 359,
            district: "三乡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 371,
            pid: 359,
            district: "石岐街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 372,
            pid: 359,
            district: "大涌镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 373,
            pid: 359,
            district: "南头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 374,
            pid: 359,
            district: "东区街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 375,
            pid: 359,
            district: "黄圃镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 376,
            pid: 359,
            district: "阜沙镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 377,
            pid: 359,
            district: "西区街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 378,
            pid: 359,
            district: "板芙镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 379,
            pid: 359,
            district: "沙溪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 380,
            pid: 359,
            district: "南区街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 381,
            pid: 359,
            district: "东凤镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 382,
            pid: 359,
            district: "坦洲镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 383,
        pid: 193,
        district: "广州市",
        level: 2,
        childrens: [
          {
            id: 384,
            pid: 383,
            district: "从化区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 385,
            pid: 383,
            district: "南沙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 386,
            pid: 383,
            district: "番禺区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 387,
            pid: 383,
            district: "海珠区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 388,
            pid: 383,
            district: "增城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 389,
            pid: 383,
            district: "黄埔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 390,
            pid: 383,
            district: "天河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 391,
            pid: 383,
            district: "白云区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 392,
            pid: 383,
            district: "越秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 393,
            pid: 383,
            district: "荔湾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 394,
            pid: 383,
            district: "花都区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 395,
    pid: 1,
    district: "内蒙古自治区",
    level: 1,
    childrens: [
      {
        id: 396,
        pid: 395,
        district: "包头市",
        level: 2,
        childrens: [
          {
            id: 397,
            pid: 396,
            district: "达尔罕茂明安联合旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 398,
            pid: 396,
            district: "白云鄂博矿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 399,
            pid: 396,
            district: "土默特右旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 400,
            pid: 396,
            district: "东河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 401,
            pid: 396,
            district: "固阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 402,
            pid: 396,
            district: "昆都仑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 403,
            pid: 396,
            district: "石拐区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 404,
            pid: 396,
            district: "青山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 405,
            pid: 396,
            district: "九原区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 406,
        pid: 395,
        district: "乌海市",
        level: 2,
        childrens: [
          {
            id: 407,
            pid: 406,
            district: "海勃湾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 408,
            pid: 406,
            district: "海南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 409,
            pid: 406,
            district: "乌达区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 410,
        pid: 395,
        district: "呼伦贝尔市",
        level: 2,
        childrens: [
          {
            id: 411,
            pid: 410,
            district: "额尔古纳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 412,
            pid: 410,
            district: "根河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 413,
            pid: 410,
            district: "海拉尔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 414,
            pid: 410,
            district: "牙克石市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 415,
            pid: 410,
            district: "鄂温克族自治旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 416,
            pid: 410,
            district: "陈巴尔虎旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 417,
            pid: 410,
            district: "阿荣旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 418,
            pid: 410,
            district: "扎兰屯市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 419,
            pid: 410,
            district: "扎赉诺尔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 420,
            pid: 410,
            district: "满洲里市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 421,
            pid: 410,
            district: "新巴尔虎左旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 422,
            pid: 410,
            district: "鄂伦春自治旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 423,
            pid: 410,
            district: "新巴尔虎右旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 424,
            pid: 410,
            district: "莫力达瓦达斡尔族自治旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 425,
        pid: 395,
        district: "巴彦淖尔市",
        level: 2,
        childrens: [
          {
            id: 426,
            pid: 425,
            district: "乌拉特中旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 427,
            pid: 425,
            district: "临河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 428,
            pid: 425,
            district: "磴口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 429,
            pid: 425,
            district: "五原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 430,
            pid: 425,
            district: "乌拉特前旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 431,
            pid: 425,
            district: "乌拉特后旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 432,
            pid: 425,
            district: "杭锦后旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 433,
        pid: 395,
        district: "阿拉善盟",
        level: 2,
        childrens: [
          {
            id: 434,
            pid: 433,
            district: "阿拉善右旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 435,
            pid: 433,
            district: "额济纳旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 436,
            pid: 433,
            district: "阿拉善左旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 437,
        pid: 395,
        district: "通辽市",
        level: 2,
        childrens: [
          {
            id: 438,
            pid: 437,
            district: "扎鲁特旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 439,
            pid: 437,
            district: "霍林郭勒市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 440,
            pid: 437,
            district: "科尔沁左翼中旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 441,
            pid: 437,
            district: "开鲁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 442,
            pid: 437,
            district: "库伦旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 443,
            pid: 437,
            district: "奈曼旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 444,
            pid: 437,
            district: "科尔沁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 445,
            pid: 437,
            district: "科尔沁左翼后旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 446,
        pid: 395,
        district: "兴安盟",
        level: 2,
        childrens: [
          {
            id: 447,
            pid: 446,
            district: "阿尔山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 448,
            pid: 446,
            district: "科尔沁右翼中旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 449,
            pid: 446,
            district: "突泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 450,
            pid: 446,
            district: "乌兰浩特市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 451,
            pid: 446,
            district: "科尔沁右翼前旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 452,
            pid: 446,
            district: "扎赉特旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 453,
        pid: 395,
        district: "锡林郭勒盟",
        level: 2,
        childrens: [
          {
            id: 454,
            pid: 453,
            district: "东乌珠穆沁旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 455,
            pid: 453,
            district: "锡林浩特市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 456,
            pid: 453,
            district: "阿巴嘎旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 457,
            pid: 453,
            district: "苏尼特左旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 458,
            pid: 453,
            district: "西乌珠穆沁旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 459,
            pid: 453,
            district: "正蓝旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 460,
            pid: 453,
            district: "二连浩特市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 461,
            pid: 453,
            district: "苏尼特右旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 462,
            pid: 453,
            district: "正镶白旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 463,
            pid: 453,
            district: "多伦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 464,
            pid: 453,
            district: "镶黄旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 465,
            pid: 453,
            district: "太仆寺旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 466,
        pid: 395,
        district: "呼和浩特市",
        level: 2,
        childrens: [
          {
            id: 467,
            pid: 466,
            district: "和林格尔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 468,
            pid: 466,
            district: "玉泉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 469,
            pid: 466,
            district: "回民区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 470,
            pid: 466,
            district: "武川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 471,
            pid: 466,
            district: "土默特左旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 472,
            pid: 466,
            district: "托克托县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 473,
            pid: 466,
            district: "新城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 474,
            pid: 466,
            district: "赛罕区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 475,
            pid: 466,
            district: "清水河县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 476,
        pid: 395,
        district: "乌兰察布市",
        level: 2,
        childrens: [
          {
            id: 477,
            pid: 476,
            district: "四子王旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 478,
            pid: 476,
            district: "察哈尔右翼后旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 479,
            pid: 476,
            district: "化德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 480,
            pid: 476,
            district: "商都县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 481,
            pid: 476,
            district: "丰镇市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 482,
            pid: 476,
            district: "兴和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 483,
            pid: 476,
            district: "察哈尔右翼中旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 484,
            pid: 476,
            district: "卓资县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 485,
            pid: 476,
            district: "察哈尔右翼前旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 486,
            pid: 476,
            district: "集宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 487,
            pid: 476,
            district: "凉城县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 488,
        pid: 395,
        district: "鄂尔多斯市",
        level: 2,
        childrens: [
          {
            id: 489,
            pid: 488,
            district: "达拉特旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 490,
            pid: 488,
            district: "杭锦旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 491,
            pid: 488,
            district: "鄂托克旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 492,
            pid: 488,
            district: "乌审旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 493,
            pid: 488,
            district: "鄂托克前旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 494,
            pid: 488,
            district: "东胜区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 495,
            pid: 488,
            district: "康巴什区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 496,
            pid: 488,
            district: "伊金霍洛旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 497,
            pid: 488,
            district: "准格尔旗",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 498,
        pid: 395,
        district: "赤峰市",
        level: 2,
        childrens: [
          {
            id: 499,
            pid: 498,
            district: "阿鲁科尔沁旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 500,
            pid: 498,
            district: "巴林左旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 501,
            pid: 498,
            district: "林西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 502,
            pid: 498,
            district: "巴林右旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 503,
            pid: 498,
            district: "喀喇沁旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 504,
            pid: 498,
            district: "敖汉旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 505,
            pid: 498,
            district: "宁城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 506,
            pid: 498,
            district: "元宝山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 507,
            pid: 498,
            district: "克什克腾旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 508,
            pid: 498,
            district: "翁牛特旗",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 509,
            pid: 498,
            district: "松山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 510,
            pid: 498,
            district: "红山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 511,
    pid: 1,
    district: "黑龙江省",
    level: 1,
    childrens: [
      {
        id: 512,
        pid: 511,
        district: "伊春市",
        level: 2,
        childrens: [
          {
            id: 513,
            pid: 512,
            district: "嘉荫县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 514,
            pid: 512,
            district: "丰林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 515,
            pid: 512,
            district: "乌翠区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 516,
            pid: 512,
            district: "伊美区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 517,
            pid: 512,
            district: "汤旺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 518,
            pid: 512,
            district: "友好区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 519,
            pid: 512,
            district: "铁力市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 520,
            pid: 512,
            district: "金林区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 521,
            pid: 512,
            district: "南岔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 522,
            pid: 512,
            district: "大箐山县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 523,
        pid: 511,
        district: "大兴安岭地区",
        level: 2,
        childrens: [
          {
            id: 524,
            pid: 523,
            district: "漠河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 525,
            pid: 523,
            district: "塔河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 526,
            pid: 523,
            district: "呼玛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 527,
            pid: 523,
            district: "加格达奇区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 528,
        pid: 511,
        district: "七台河市",
        level: 2,
        childrens: [
          {
            id: 529,
            pid: 528,
            district: "桃山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 530,
            pid: 528,
            district: "新兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 531,
            pid: 528,
            district: "勃利县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 532,
            pid: 528,
            district: "茄子河区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 533,
        pid: 511,
        district: "鹤岗市",
        level: 2,
        childrens: [
          {
            id: 534,
            pid: 533,
            district: "绥滨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 535,
            pid: 533,
            district: "工农区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 536,
            pid: 533,
            district: "兴山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 537,
            pid: 533,
            district: "南山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 538,
            pid: 533,
            district: "向阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 539,
            pid: 533,
            district: "萝北县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 540,
            pid: 533,
            district: "兴安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 541,
            pid: 533,
            district: "东山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 542,
        pid: 511,
        district: "哈尔滨市",
        level: 2,
        childrens: [
          {
            id: 543,
            pid: 542,
            district: "巴彦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 544,
            pid: 542,
            district: "依兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 545,
            pid: 542,
            district: "通河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 546,
            pid: 542,
            district: "方正县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 547,
            pid: 542,
            district: "宾县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 548,
            pid: 542,
            district: "平房区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 549,
            pid: 542,
            district: "双城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 550,
            pid: 542,
            district: "木兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 551,
            pid: 542,
            district: "延寿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 552,
            pid: 542,
            district: "尚志市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 553,
            pid: 542,
            district: "五常市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 554,
            pid: 542,
            district: "道里区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 555,
            pid: 542,
            district: "阿城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 556,
            pid: 542,
            district: "南岗区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 557,
            pid: 542,
            district: "松北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 558,
            pid: 542,
            district: "呼兰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 559,
            pid: 542,
            district: "香坊区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 560,
            pid: 542,
            district: "道外区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 561,
        pid: 511,
        district: "佳木斯市",
        level: 2,
        childrens: [
          {
            id: 562,
            pid: 561,
            district: "同江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 563,
            pid: 561,
            district: "桦川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 564,
            pid: 561,
            district: "汤原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 565,
            pid: 561,
            district: "东风区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 566,
            pid: 561,
            district: "前进区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 567,
            pid: 561,
            district: "向阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 568,
            pid: 561,
            district: "郊区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 569,
            pid: 561,
            district: "抚远市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 570,
            pid: 561,
            district: "桦南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 571,
            pid: 561,
            district: "富锦市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 572,
        pid: 511,
        district: "双鸭山市",
        level: 2,
        childrens: [
          {
            id: 573,
            pid: 572,
            district: "岭东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 574,
            pid: 572,
            district: "宝山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 575,
            pid: 572,
            district: "友谊县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 576,
            pid: 572,
            district: "尖山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 577,
            pid: 572,
            district: "宝清县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 578,
            pid: 572,
            district: "饶河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 579,
            pid: 572,
            district: "四方台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 580,
            pid: 572,
            district: "集贤县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 581,
        pid: 511,
        district: "黑河市",
        level: 2,
        childrens: [
          {
            id: 582,
            pid: 581,
            district: "爱辉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 583,
            pid: 581,
            district: "北安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 584,
            pid: 581,
            district: "五大连池市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 585,
            pid: 581,
            district: "孙吴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 586,
            pid: 581,
            district: "逊克县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 587,
            pid: 581,
            district: "嫩江市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 588,
        pid: 511,
        district: "牡丹江市",
        level: 2,
        childrens: [
          {
            id: 589,
            pid: 588,
            district: "爱民区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 590,
            pid: 588,
            district: "绥芬河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 591,
            pid: 588,
            district: "东安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 592,
            pid: 588,
            district: "宁安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 593,
            pid: 588,
            district: "穆棱市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 594,
            pid: 588,
            district: "阳明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 595,
            pid: 588,
            district: "林口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 596,
            pid: 588,
            district: "西安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 597,
            pid: 588,
            district: "海林市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 598,
            pid: 588,
            district: "东宁市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 599,
        pid: 511,
        district: "齐齐哈尔市",
        level: 2,
        childrens: [
          {
            id: 600,
            pid: 599,
            district: "克山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 601,
            pid: 599,
            district: "克东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 602,
            pid: 599,
            district: "甘南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 603,
            pid: 599,
            district: "依安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 604,
            pid: 599,
            district: "梅里斯达斡尔族区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 605,
            pid: 599,
            district: "富裕县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 606,
            pid: 599,
            district: "昂昂溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 607,
            pid: 599,
            district: "龙江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 608,
            pid: 599,
            district: "龙沙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 609,
            pid: 599,
            district: "富拉尔基区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 610,
            pid: 599,
            district: "碾子山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 611,
            pid: 599,
            district: "建华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 612,
            pid: 599,
            district: "铁锋区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 613,
            pid: 599,
            district: "泰来县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 614,
            pid: 599,
            district: "拜泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 615,
            pid: 599,
            district: "讷河市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 616,
        pid: 511,
        district: "鸡西市",
        level: 2,
        childrens: [
          {
            id: 617,
            pid: 616,
            district: "城子河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 618,
            pid: 616,
            district: "滴道区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 619,
            pid: 616,
            district: "恒山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 620,
            pid: 616,
            district: "鸡冠区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 621,
            pid: 616,
            district: "梨树区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 622,
            pid: 616,
            district: "麻山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 623,
            pid: 616,
            district: "鸡东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 624,
            pid: 616,
            district: "虎林市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 625,
            pid: 616,
            district: "密山市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 626,
        pid: 511,
        district: "绥化市",
        level: 2,
        childrens: [
          {
            id: 627,
            pid: 626,
            district: "绥棱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 628,
            pid: 626,
            district: "海伦市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 629,
            pid: 626,
            district: "庆安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 630,
            pid: 626,
            district: "望奎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 631,
            pid: 626,
            district: "青冈县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 632,
            pid: 626,
            district: "北林区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 633,
            pid: 626,
            district: "明水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 634,
            pid: 626,
            district: "兰西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 635,
            pid: 626,
            district: "肇东市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 636,
            pid: 626,
            district: "安达市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 637,
        pid: 511,
        district: "大庆市",
        level: 2,
        childrens: [
          {
            id: 638,
            pid: 637,
            district: "肇源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 639,
            pid: 637,
            district: "红岗区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 640,
            pid: 637,
            district: "大同区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 641,
            pid: 637,
            district: "杜尔伯特蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 642,
            pid: 637,
            district: "林甸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 643,
            pid: 637,
            district: "肇州县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 644,
            pid: 637,
            district: "龙凤区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 645,
            pid: 637,
            district: "让胡路区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 646,
            pid: 637,
            district: "萨尔图区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 647,
    pid: 1,
    district: "新疆维吾尔自治区",
    level: 1,
    childrens: [
      {
        id: 648,
        pid: 647,
        district: "铁门关市",
        level: 2,
        childrens: [
          {
            id: 649,
            pid: 648,
            district: "博古其镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 650,
            pid: 648,
            district: "双丰镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 651,
        pid: 647,
        district: "北屯市",
        level: 2,
        childrens: [
          {
            id: 652,
            pid: 651,
            district: "北屯镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 653,
            pid: 651,
            district: "丰庆镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 654,
            pid: 651,
            district: "双渠镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 655,
            pid: 651,
            district: "海川镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 656,
        pid: 647,
        district: "双河市",
        level: 2,
        childrens: [
          {
            id: 657,
            pid: 656,
            district: "兵团八十九团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 658,
            pid: 656,
            district: "石峪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 659,
            pid: 656,
            district: "博河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 660,
            pid: 656,
            district: "双乐镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 661,
            pid: 656,
            district: "双桥镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 662,
        pid: 647,
        district: "可克达拉市",
        level: 2,
        childrens: [
          {
            id: 663,
            pid: 662,
            district: "长丰镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 664,
            pid: 662,
            district: "都拉塔口岸",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 665,
            pid: 662,
            district: "金粱镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 666,
            pid: 662,
            district: "榆树庄镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 667,
            pid: 662,
            district: "苇湖镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 668,
            pid: 662,
            district: "金屯镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 669,
        pid: 647,
        district: "和田地区",
        level: 2,
        childrens: [
          {
            id: 670,
            pid: 669,
            district: "于田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 671,
            pid: 669,
            district: "民丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 672,
            pid: 669,
            district: "和田市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 673,
            pid: 669,
            district: "洛浦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 674,
            pid: 669,
            district: "和田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 675,
            pid: 669,
            district: "策勒县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 676,
            pid: 669,
            district: "皮山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 677,
            pid: 669,
            district: "墨玉县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 678,
        pid: 647,
        district: "阿勒泰地区",
        level: 2,
        childrens: [
          {
            id: 679,
            pid: 678,
            district: "富蕴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 680,
            pid: 678,
            district: "吉木乃县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 681,
            pid: 678,
            district: "青河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 682,
            pid: 678,
            district: "布尔津县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 683,
            pid: 678,
            district: "哈巴河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 684,
            pid: 678,
            district: "阿勒泰市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 685,
            pid: 678,
            district: "福海县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 686,
        pid: 647,
        district: "昌吉回族自治州",
        level: 2,
        childrens: [
          {
            id: 687,
            pid: 686,
            district: "阜康市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 688,
            pid: 686,
            district: "奇台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 689,
            pid: 686,
            district: "玛纳斯县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 690,
            pid: 686,
            district: "呼图壁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 691,
            pid: 686,
            district: "木垒哈萨克自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 692,
            pid: 686,
            district: "吉木萨尔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 693,
            pid: 686,
            district: "昌吉市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 694,
        pid: 647,
        district: "石河子市",
        level: 2,
        childrens: [
          {
            id: 695,
            pid: 694,
            district: "向阳街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 696,
            pid: 694,
            district: "红山街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 697,
            pid: 694,
            district: "兵团一五二团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 698,
            pid: 694,
            district: "东城街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 699,
            pid: 694,
            district: "石河子镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 700,
            pid: 694,
            district: "老街街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 701,
            pid: 694,
            district: "北泉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 702,
            pid: 694,
            district: "新城街道",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 703,
        pid: 647,
        district: "五家渠市",
        level: 2,
        childrens: [
          {
            id: 704,
            pid: 703,
            district: "军垦路街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 705,
            pid: 703,
            district: "梧桐镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 706,
            pid: 703,
            district: "人民路街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 707,
            pid: 703,
            district: "兵团一零一团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 708,
            pid: 703,
            district: "蔡家湖镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 709,
            pid: 703,
            district: "青湖路街道",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 710,
        pid: 647,
        district: "塔城地区",
        level: 2,
        childrens: [
          {
            id: 711,
            pid: 710,
            district: "和布克赛尔蒙古自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 712,
            pid: 710,
            district: "额敏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 713,
            pid: 710,
            district: "塔城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 714,
            pid: 710,
            district: "裕民县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 715,
            pid: 710,
            district: "托里县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 716,
            pid: 710,
            district: "乌苏市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 717,
            pid: 710,
            district: "沙湾市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 718,
        pid: 647,
        district: "博尔塔拉蒙古自治州",
        level: 2,
        childrens: [
          {
            id: 719,
            pid: 718,
            district: "温泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 720,
            pid: 718,
            district: "阿拉山口市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 721,
            pid: 718,
            district: "博乐市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 722,
            pid: 718,
            district: "精河县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 723,
        pid: 647,
        district: "昆玉市",
        level: 2,
        childrens: [
          {
            id: 724,
            pid: 723,
            district: "普恰克其乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 725,
            pid: 723,
            district: "博斯坦乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 726,
            pid: 723,
            district: "兵团二二四团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 727,
            pid: 723,
            district: "老兵镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 728,
            pid: 723,
            district: "奴尔乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 729,
            pid: 723,
            district: "喀拉喀什镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 730,
            pid: 723,
            district: "昆牧镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 731,
            pid: 723,
            district: "乌尔其乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 732,
            pid: 723,
            district: "昆泉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 733,
            pid: 723,
            district: "乌鲁克萨依乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 734,
            pid: 723,
            district: "阔依其乡",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 735,
        pid: 647,
        district: "巴音郭楞蒙古自治州",
        level: 2,
        childrens: [
          {
            id: 736,
            pid: 735,
            district: "和静县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 737,
            pid: 735,
            district: "和硕县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 738,
            pid: 735,
            district: "焉耆回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 739,
            pid: 735,
            district: "博湖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 740,
            pid: 735,
            district: "若羌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 741,
            pid: 735,
            district: "且末县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 742,
            pid: 735,
            district: "库尔勒市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 743,
            pid: 735,
            district: "轮台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 744,
            pid: 735,
            district: "尉犁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 745,
        pid: 647,
        district: "图木舒克市",
        level: 2,
        childrens: [
          {
            id: 746,
            pid: 745,
            district: "海安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 747,
            pid: 745,
            district: "兵团图木舒克市永安坝",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 748,
            pid: 745,
            district: "唐驿镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 749,
            pid: 745,
            district: "前海街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 750,
            pid: 745,
            district: "兵团图木舒克市喀拉拜勒镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 751,
            pid: 745,
            district: "永安坝街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 752,
            pid: 745,
            district: "齐干却勒街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 753,
            pid: 745,
            district: "金胡杨镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 754,
            pid: 745,
            district: "夏河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 755,
            pid: 745,
            district: "永安镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 756,
        pid: 647,
        district: "哈密市",
        level: 2,
        childrens: [
          {
            id: 757,
            pid: 756,
            district: "伊州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 758,
            pid: 756,
            district: "巴里坤哈萨克自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 759,
            pid: 756,
            district: "伊吾县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 760,
        pid: 647,
        district: "阿拉尔市",
        level: 2,
        childrens: [
          {
            id: 761,
            pid: 760,
            district: "幸福路街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 762,
            pid: 760,
            district: "阿拉尔农场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 763,
            pid: 760,
            district: "兵团第一师幸福农场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 764,
            pid: 760,
            district: "工业园区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 765,
            pid: 760,
            district: "玛滩镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 766,
            pid: 760,
            district: "花桥镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 767,
            pid: 760,
            district: "兵团第一师水利水电工程处",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 768,
            pid: 760,
            district: "托喀依乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 769,
            pid: 760,
            district: "青松路街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 770,
            pid: 760,
            district: "塔门镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 771,
            pid: 760,
            district: "中心监狱",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 772,
            pid: 760,
            district: "兵团第一师塔里木灌区水利管理处",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 773,
            pid: 760,
            district: "金杨镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 774,
            pid: 760,
            district: "昌安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 775,
            pid: 760,
            district: "金银川路街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 776,
            pid: 760,
            district: "塔南镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 777,
            pid: 760,
            district: "新开岭镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 778,
            pid: 760,
            district: "幸福镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 779,
            pid: 760,
            district: "南口街道",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 780,
        pid: 647,
        district: "伊犁哈萨克自治州",
        level: 2,
        childrens: [
          {
            id: 781,
            pid: 780,
            district: "奎屯市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 782,
            pid: 780,
            district: "尼勒克县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 783,
            pid: 780,
            district: "伊宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 784,
            pid: 780,
            district: "巩留县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 785,
            pid: 780,
            district: "特克斯县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 786,
            pid: 780,
            district: "新源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 787,
            pid: 780,
            district: "昭苏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 788,
            pid: 780,
            district: "霍城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 789,
            pid: 780,
            district: "察布查尔锡伯自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 790,
            pid: 780,
            district: "霍尔果斯市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 791,
            pid: 780,
            district: "伊宁市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 792,
        pid: 647,
        district: "阿克苏地区",
        level: 2,
        childrens: [
          {
            id: 793,
            pid: 792,
            district: "拜城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 794,
            pid: 792,
            district: "温宿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 795,
            pid: 792,
            district: "库车市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 796,
            pid: 792,
            district: "新和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 797,
            pid: 792,
            district: "沙雅县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 798,
            pid: 792,
            district: "阿克苏市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 799,
            pid: 792,
            district: "柯坪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 800,
            pid: 792,
            district: "阿瓦提县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 801,
            pid: 792,
            district: "乌什县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 802,
        pid: 647,
        district: "喀什地区",
        level: 2,
        childrens: [
          {
            id: 803,
            pid: 802,
            district: "伽师县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 804,
            pid: 802,
            district: "疏勒县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 805,
            pid: 802,
            district: "麦盖提县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 806,
            pid: 802,
            district: "莎车县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 807,
            pid: 802,
            district: "叶城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 808,
            pid: 802,
            district: "塔什库尔干塔吉克自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 809,
            pid: 802,
            district: "泽普县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 810,
            pid: 802,
            district: "巴楚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 811,
            pid: 802,
            district: "英吉沙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 812,
            pid: 802,
            district: "疏附县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 813,
            pid: 802,
            district: "喀什市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 814,
            pid: 802,
            district: "岳普湖县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 815,
        pid: 647,
        district: "克孜勒苏柯尔克孜自治州",
        level: 2,
        childrens: [
          {
            id: 816,
            pid: 815,
            district: "阿图什市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 817,
            pid: 815,
            district: "乌恰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 818,
            pid: 815,
            district: "阿克陶县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 819,
            pid: 815,
            district: "阿合奇县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 820,
        pid: 647,
        district: "克拉玛依市",
        level: 2,
        childrens: [
          {
            id: 821,
            pid: 820,
            district: "乌尔禾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 822,
            pid: 820,
            district: "独山子区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 823,
            pid: 820,
            district: "白碱滩区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 824,
            pid: 820,
            district: "克拉玛依区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 825,
        pid: 647,
        district: "胡杨河市",
        level: 2,
        childrens: [
          {
            id: 826,
            pid: 825,
            district: "兵团一三零团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 827,
            pid: 825,
            district: "五五新镇街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 828,
            pid: 825,
            district: "兵团一二八团",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 829,
            pid: 825,
            district: "兵团一二九团",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 830,
        pid: 647,
        district: "吐鲁番市",
        level: 2,
        childrens: [
          {
            id: 831,
            pid: 830,
            district: "高昌区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 832,
            pid: 830,
            district: "鄯善县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 833,
            pid: 830,
            district: "托克逊县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 834,
        pid: 647,
        district: "乌鲁木齐市",
        level: 2,
        childrens: [
          {
            id: 835,
            pid: 834,
            district: "达坂城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 836,
            pid: 834,
            district: "天山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 837,
            pid: 834,
            district: "水磨沟区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 838,
            pid: 834,
            district: "米东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 839,
            pid: 834,
            district: "乌鲁木齐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 840,
            pid: 834,
            district: "新市区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 841,
            pid: 834,
            district: "沙依巴克区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 842,
            pid: 834,
            district: "头屯河区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 843,
    pid: 1,
    district: "湖北省",
    level: 1,
    childrens: [
      {
        id: 844,
        pid: 843,
        district: "潜江市",
        level: 2,
        childrens: [
          {
            id: 845,
            pid: 844,
            district: "积玉口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 846,
            pid: 844,
            district: "泰丰街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 847,
            pid: 844,
            district: "广华街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 848,
            pid: 844,
            district: "周矶管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 849,
            pid: 844,
            district: "潜江经济开发区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 850,
            pid: 844,
            district: "高场街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 851,
            pid: 844,
            district: "周矶街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 852,
            pid: 844,
            district: "总口管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 853,
            pid: 844,
            district: "王场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 854,
            pid: 844,
            district: "运粮湖管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 855,
            pid: 844,
            district: "园林街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 856,
            pid: 844,
            district: "白鹭湖管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 857,
            pid: 844,
            district: "竹根滩镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 858,
            pid: 844,
            district: "渔洋镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 859,
            pid: 844,
            district: "熊口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 860,
            pid: 844,
            district: "江汉石油管理局",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 861,
            pid: 844,
            district: "熊口管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 862,
            pid: 844,
            district: "后湖管理区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 863,
            pid: 844,
            district: "张金镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 864,
            pid: 844,
            district: "杨市街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 865,
            pid: 844,
            district: "老新镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 866,
            pid: 844,
            district: "浩口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 867,
            pid: 844,
            district: "高石碑镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 868,
            pid: 844,
            district: "浩口原种场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 869,
            pid: 844,
            district: "龙湾镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 870,
        pid: 843,
        district: "孝感市",
        level: 2,
        childrens: [
          {
            id: 871,
            pid: 870,
            district: "云梦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 872,
            pid: 870,
            district: "应城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 873,
            pid: 870,
            district: "汉川市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 874,
            pid: 870,
            district: "孝南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 875,
            pid: 870,
            district: "孝昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 876,
            pid: 870,
            district: "安陆市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 877,
            pid: 870,
            district: "大悟县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 878,
        pid: 843,
        district: "黄冈市",
        level: 2,
        childrens: [
          {
            id: 879,
            pid: 878,
            district: "麻城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 880,
            pid: 878,
            district: "黄州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 881,
            pid: 878,
            district: "浠水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 882,
            pid: 878,
            district: "英山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 883,
            pid: 878,
            district: "红安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 884,
            pid: 878,
            district: "武穴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 885,
            pid: 878,
            district: "黄梅县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 886,
            pid: 878,
            district: "罗田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 887,
            pid: 878,
            district: "团风县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 888,
            pid: 878,
            district: "蕲春县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 889,
        pid: 843,
        district: "天门市",
        level: 2,
        childrens: [
          {
            id: 890,
            pid: 889,
            district: "胡市镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 891,
            pid: 889,
            district: "黄潭镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 892,
            pid: 889,
            district: "多祥镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 893,
            pid: 889,
            district: "沉湖管委会",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 894,
            pid: 889,
            district: "干驿镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 895,
            pid: 889,
            district: "横林镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 896,
            pid: 889,
            district: "马湾镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 897,
            pid: 889,
            district: "蒋湖农场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 898,
            pid: 889,
            district: "小板镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 899,
            pid: 889,
            district: "多宝镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 900,
            pid: 889,
            district: "岳口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 901,
            pid: 889,
            district: "蒋场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 902,
            pid: 889,
            district: "石家河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 903,
            pid: 889,
            district: "彭市镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 904,
            pid: 889,
            district: "佛子山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 905,
            pid: 889,
            district: "九真镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 906,
            pid: 889,
            district: "竟陵街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 907,
            pid: 889,
            district: "候口街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 908,
            pid: 889,
            district: "麻洋镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 909,
            pid: 889,
            district: "杨林街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 910,
            pid: 889,
            district: "汪场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 911,
            pid: 889,
            district: "白茅湖农场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 912,
            pid: 889,
            district: "张港镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 913,
            pid: 889,
            district: "渔薪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 914,
            pid: 889,
            district: "皂市镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 915,
            pid: 889,
            district: "拖市镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 916,
            pid: 889,
            district: "净潭乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 917,
            pid: 889,
            district: "卢市镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 918,
        pid: 843,
        district: "十堰市",
        level: 2,
        childrens: [
          {
            id: 919,
            pid: 918,
            district: "郧阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 920,
            pid: 918,
            district: "丹江口市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 921,
            pid: 918,
            district: "张湾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 922,
            pid: 918,
            district: "竹溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 923,
            pid: 918,
            district: "茅箭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 924,
            pid: 918,
            district: "竹山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 925,
            pid: 918,
            district: "房县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 926,
            pid: 918,
            district: "郧西县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 927,
        pid: 843,
        district: "襄阳市",
        level: 2,
        childrens: [
          {
            id: 928,
            pid: 927,
            district: "谷城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 929,
            pid: 927,
            district: "老河口市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 930,
            pid: 927,
            district: "枣阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 931,
            pid: 927,
            district: "保康县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 932,
            pid: 927,
            district: "宜城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 933,
            pid: 927,
            district: "襄城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 934,
            pid: 927,
            district: "樊城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 935,
            pid: 927,
            district: "南漳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 936,
            pid: 927,
            district: "襄州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 937,
        pid: 843,
        district: "恩施土家族苗族自治州",
        level: 2,
        childrens: [
          {
            id: 938,
            pid: 937,
            district: "恩施市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 939,
            pid: 937,
            district: "建始县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 940,
            pid: 937,
            district: "利川市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 941,
            pid: 937,
            district: "鹤峰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 942,
            pid: 937,
            district: "来凤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 943,
            pid: 937,
            district: "宣恩县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 944,
            pid: 937,
            district: "巴东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 945,
            pid: 937,
            district: "咸丰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 946,
        pid: 843,
        district: "荆门市",
        level: 2,
        childrens: [
          {
            id: 947,
            pid: 946,
            district: "京山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 948,
            pid: 946,
            district: "钟祥市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 949,
            pid: 946,
            district: "东宝区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 950,
            pid: 946,
            district: "沙洋县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 951,
            pid: 946,
            district: "掇刀区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 952,
        pid: 843,
        district: "宜昌市",
        level: 2,
        childrens: [
          {
            id: 953,
            pid: 952,
            district: "兴山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 954,
            pid: 952,
            district: "秭归县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 955,
            pid: 952,
            district: "当阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 956,
            pid: 952,
            district: "点军区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 957,
            pid: 952,
            district: "长阳土家族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 958,
            pid: 952,
            district: "枝江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 959,
            pid: 952,
            district: "猇亭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 960,
            pid: 952,
            district: "宜都市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 961,
            pid: 952,
            district: "五峰土家族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 962,
            pid: 952,
            district: "夷陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 963,
            pid: 952,
            district: "远安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 964,
            pid: 952,
            district: "西陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 965,
            pid: 952,
            district: "伍家岗区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 966,
        pid: 843,
        district: "武汉市",
        level: 2,
        childrens: [
          {
            id: 967,
            pid: 966,
            district: "黄陂区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 968,
            pid: 966,
            district: "新洲区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 969,
            pid: 966,
            district: "汉南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 970,
            pid: 966,
            district: "蔡甸区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 971,
            pid: 966,
            district: "江夏区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 972,
            pid: 966,
            district: "武昌区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 973,
            pid: 966,
            district: "东西湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 974,
            pid: 966,
            district: "硚口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 975,
            pid: 966,
            district: "汉阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 976,
            pid: 966,
            district: "青山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 977,
            pid: 966,
            district: "江汉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 978,
            pid: 966,
            district: "江岸区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 979,
            pid: 966,
            district: "洪山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 980,
        pid: 843,
        district: "荆州市",
        level: 2,
        childrens: [
          {
            id: 981,
            pid: 980,
            district: "江陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 982,
            pid: 980,
            district: "洪湖市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 983,
            pid: 980,
            district: "石首市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 984,
            pid: 980,
            district: "监利市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 985,
            pid: 980,
            district: "沙市区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 986,
            pid: 980,
            district: "松滋市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 987,
            pid: 980,
            district: "公安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 988,
            pid: 980,
            district: "荆州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 989,
        pid: 843,
        district: "仙桃市",
        level: 2,
        childrens: [
          {
            id: 990,
            pid: 989,
            district: "畜禽良种场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 991,
            pid: 989,
            district: "工业园区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 992,
            pid: 989,
            district: "豆河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 993,
            pid: 989,
            district: "沙湖镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 994,
            pid: 989,
            district: "通海口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 995,
            pid: 989,
            district: "胡场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 996,
            pid: 989,
            district: "长倘口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 997,
            pid: 989,
            district: "五湖渔场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 998,
            pid: 989,
            district: "干河街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 999,
            pid: 989,
            district: "杨林尾镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1000,
            pid: 989,
            district: "西流河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1001,
            pid: 989,
            district: "赵西垸林场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1002,
            pid: 989,
            district: "九合垸原种场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1003,
            pid: 989,
            district: "彭场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1004,
            pid: 989,
            district: "沔城回族镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1005,
            pid: 989,
            district: "龙华山街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1006,
            pid: 989,
            district: "沙湖原种场",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1007,
            pid: 989,
            district: "陈场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1008,
            pid: 989,
            district: "郑场镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1009,
            pid: 989,
            district: "郭河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1010,
            pid: 989,
            district: "排湖风景区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1011,
            pid: 989,
            district: "沙嘴街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1012,
            pid: 989,
            district: "毛嘴镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1013,
            pid: 989,
            district: "张沟镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1014,
            pid: 989,
            district: "三伏潭镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1015,
        pid: 843,
        district: "咸宁市",
        level: 2,
        childrens: [
          {
            id: 1016,
            pid: 1015,
            district: "嘉鱼县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1017,
            pid: 1015,
            district: "咸安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1018,
            pid: 1015,
            district: "通山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1019,
            pid: 1015,
            district: "赤壁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1020,
            pid: 1015,
            district: "崇阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1021,
            pid: 1015,
            district: "通城县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1022,
        pid: 843,
        district: "随州市",
        level: 2,
        childrens: [
          {
            id: 1023,
            pid: 1022,
            district: "曾都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1024,
            pid: 1022,
            district: "随县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1025,
            pid: 1022,
            district: "广水市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1026,
        pid: 843,
        district: "神农架林区",
        level: 2,
        childrens: [
          {
            id: 1027,
            pid: 1026,
            district: "木鱼镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1028,
            pid: 1026,
            district: "下谷坪土家族乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1029,
            pid: 1026,
            district: "新华镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1030,
            pid: 1026,
            district: "大九湖镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1031,
            pid: 1026,
            district: "松柏镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1032,
            pid: 1026,
            district: "宋洛乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1033,
            pid: 1026,
            district: "红坪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1034,
            pid: 1026,
            district: "阳日镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1035,
        pid: 843,
        district: "黄石市",
        level: 2,
        childrens: [
          {
            id: 1036,
            pid: 1035,
            district: "铁山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1037,
            pid: 1035,
            district: "下陆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1038,
            pid: 1035,
            district: "西塞山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1039,
            pid: 1035,
            district: "阳新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1040,
            pid: 1035,
            district: "大冶市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1041,
            pid: 1035,
            district: "黄石港区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1042,
        pid: 843,
        district: "鄂州市",
        level: 2,
        childrens: [
          {
            id: 1043,
            pid: 1042,
            district: "华容区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1044,
            pid: 1042,
            district: "梁子湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1045,
            pid: 1042,
            district: "鄂城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1046,
    pid: 1,
    district: "辽宁省",
    level: 1,
    childrens: [
      {
        id: 1047,
        pid: 1046,
        district: "抚顺市",
        level: 2,
        childrens: [
          {
            id: 1048,
            pid: 1047,
            district: "清原满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1049,
            pid: 1047,
            district: "新宾满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1050,
            pid: 1047,
            district: "望花区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1051,
            pid: 1047,
            district: "东洲区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1052,
            pid: 1047,
            district: "新抚区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1053,
            pid: 1047,
            district: "顺城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1054,
            pid: 1047,
            district: "抚顺县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1055,
        pid: 1046,
        district: "沈阳市",
        level: 2,
        childrens: [
          {
            id: 1056,
            pid: 1055,
            district: "新民市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1057,
            pid: 1055,
            district: "苏家屯区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1058,
            pid: 1055,
            district: "和平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1059,
            pid: 1055,
            district: "于洪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1060,
            pid: 1055,
            district: "康平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1061,
            pid: 1055,
            district: "法库县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1062,
            pid: 1055,
            district: "沈河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1063,
            pid: 1055,
            district: "大东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1064,
            pid: 1055,
            district: "铁西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1065,
            pid: 1055,
            district: "辽中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1066,
            pid: 1055,
            district: "浑南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1067,
            pid: 1055,
            district: "皇姑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1068,
            pid: 1055,
            district: "沈北新区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1069,
        pid: 1046,
        district: "大连市",
        level: 2,
        childrens: [
          {
            id: 1070,
            pid: 1069,
            district: "瓦房店市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1071,
            pid: 1069,
            district: "普兰店区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1072,
            pid: 1069,
            district: "庄河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1073,
            pid: 1069,
            district: "金州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1074,
            pid: 1069,
            district: "长海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1075,
            pid: 1069,
            district: "甘井子区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1076,
            pid: 1069,
            district: "中山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1077,
            pid: 1069,
            district: "旅顺口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1078,
            pid: 1069,
            district: "沙河口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1079,
            pid: 1069,
            district: "西岗区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1080,
        pid: 1046,
        district: "丹东市",
        level: 2,
        childrens: [
          {
            id: 1081,
            pid: 1080,
            district: "振安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1082,
            pid: 1080,
            district: "凤城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1083,
            pid: 1080,
            district: "东港市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1084,
            pid: 1080,
            district: "振兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1085,
            pid: 1080,
            district: "宽甸满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1086,
            pid: 1080,
            district: "元宝区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1087,
        pid: 1046,
        district: "葫芦岛市",
        level: 2,
        childrens: [
          {
            id: 1088,
            pid: 1087,
            district: "建昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1089,
            pid: 1087,
            district: "龙港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1090,
            pid: 1087,
            district: "兴城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1091,
            pid: 1087,
            district: "绥中县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1092,
            pid: 1087,
            district: "南票区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1093,
            pid: 1087,
            district: "连山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1094,
        pid: 1046,
        district: "锦州市",
        level: 2,
        childrens: [
          {
            id: 1095,
            pid: 1094,
            district: "北镇市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1096,
            pid: 1094,
            district: "凌河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1097,
            pid: 1094,
            district: "凌海市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1098,
            pid: 1094,
            district: "古塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1099,
            pid: 1094,
            district: "义县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1100,
            pid: 1094,
            district: "太和区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1101,
            pid: 1094,
            district: "黑山县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1102,
        pid: 1046,
        district: "铁岭市",
        level: 2,
        childrens: [
          {
            id: 1103,
            pid: 1102,
            district: "开原市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1104,
            pid: 1102,
            district: "调兵山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1105,
            pid: 1102,
            district: "清河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1106,
            pid: 1102,
            district: "铁岭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1107,
            pid: 1102,
            district: "西丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1108,
            pid: 1102,
            district: "银州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1109,
            pid: 1102,
            district: "昌图县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1110,
        pid: 1046,
        district: "营口市",
        level: 2,
        childrens: [
          {
            id: 1111,
            pid: 1110,
            district: "老边区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1112,
            pid: 1110,
            district: "大石桥市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1113,
            pid: 1110,
            district: "西市区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1114,
            pid: 1110,
            district: "盖州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1115,
            pid: 1110,
            district: "鲅鱼圈区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1116,
            pid: 1110,
            district: "站前区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1117,
        pid: 1046,
        district: "阜新市",
        level: 2,
        childrens: [
          {
            id: 1118,
            pid: 1117,
            district: "太平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1119,
            pid: 1117,
            district: "清河门区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1120,
            pid: 1117,
            district: "阜新蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1121,
            pid: 1117,
            district: "新邱区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1122,
            pid: 1117,
            district: "彰武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1123,
            pid: 1117,
            district: "海州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1124,
            pid: 1117,
            district: "细河区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1125,
        pid: 1046,
        district: "本溪市",
        level: 2,
        childrens: [
          {
            id: 1126,
            pid: 1125,
            district: "溪湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1127,
            pid: 1125,
            district: "桓仁满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1128,
            pid: 1125,
            district: "南芬区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1129,
            pid: 1125,
            district: "平山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1130,
            pid: 1125,
            district: "明山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1131,
            pid: 1125,
            district: "本溪满族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1132,
        pid: 1046,
        district: "辽阳市",
        level: 2,
        childrens: [
          {
            id: 1133,
            pid: 1132,
            district: "文圣区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1134,
            pid: 1132,
            district: "辽阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1135,
            pid: 1132,
            district: "白塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1136,
            pid: 1132,
            district: "太子河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1137,
            pid: 1132,
            district: "弓长岭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1138,
            pid: 1132,
            district: "宏伟区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1139,
            pid: 1132,
            district: "灯塔市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1140,
        pid: 1046,
        district: "鞍山市",
        level: 2,
        childrens: [
          {
            id: 1141,
            pid: 1140,
            district: "立山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1142,
            pid: 1140,
            district: "铁西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1143,
            pid: 1140,
            district: "台安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1144,
            pid: 1140,
            district: "铁东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1145,
            pid: 1140,
            district: "千山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1146,
            pid: 1140,
            district: "岫岩满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1147,
            pid: 1140,
            district: "海城市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1148,
        pid: 1046,
        district: "盘锦市",
        level: 2,
        childrens: [
          {
            id: 1149,
            pid: 1148,
            district: "双台子区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1150,
            pid: 1148,
            district: "大洼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1151,
            pid: 1148,
            district: "盘山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1152,
            pid: 1148,
            district: "兴隆台区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1153,
        pid: 1046,
        district: "朝阳市",
        level: 2,
        childrens: [
          {
            id: 1154,
            pid: 1153,
            district: "龙城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1155,
            pid: 1153,
            district: "朝阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1156,
            pid: 1153,
            district: "建平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1157,
            pid: 1153,
            district: "喀喇沁左翼蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1158,
            pid: 1153,
            district: "凌源市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1159,
            pid: 1153,
            district: "双塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1160,
            pid: 1153,
            district: "北票市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1161,
    pid: 1,
    district: "山东省",
    level: 1,
    childrens: [
      {
        id: 1162,
        pid: 1161,
        district: "淄博市",
        level: 2,
        childrens: [
          {
            id: 1163,
            pid: 1162,
            district: "桓台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1164,
            pid: 1162,
            district: "高青县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1165,
            pid: 1162,
            district: "临淄区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1166,
            pid: 1162,
            district: "博山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1167,
            pid: 1162,
            district: "张店区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1168,
            pid: 1162,
            district: "沂源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1169,
            pid: 1162,
            district: "淄川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1170,
            pid: 1162,
            district: "周村区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1171,
        pid: 1161,
        district: "烟台市",
        level: 2,
        childrens: [
          {
            id: 1172,
            pid: 1171,
            district: "蓬莱区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1173,
            pid: 1171,
            district: "芝罘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1174,
            pid: 1171,
            district: "莱州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1175,
            pid: 1171,
            district: "海阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1176,
            pid: 1171,
            district: "莱阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1177,
            pid: 1171,
            district: "龙口市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1178,
            pid: 1171,
            district: "招远市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1179,
            pid: 1171,
            district: "莱山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1180,
            pid: 1171,
            district: "牟平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1181,
            pid: 1171,
            district: "栖霞市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1182,
            pid: 1171,
            district: "福山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1183,
        pid: 1161,
        district: "青岛市",
        level: 2,
        childrens: [
          {
            id: 1184,
            pid: 1183,
            district: "莱西市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1185,
            pid: 1183,
            district: "即墨区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1186,
            pid: 1183,
            district: "崂山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1187,
            pid: 1183,
            district: "城阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1188,
            pid: 1183,
            district: "黄岛区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1189,
            pid: 1183,
            district: "李沧区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1190,
            pid: 1183,
            district: "胶州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1191,
            pid: 1183,
            district: "平度市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1192,
            pid: 1183,
            district: "市北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1193,
            pid: 1183,
            district: "市南区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1194,
        pid: 1161,
        district: "威海市",
        level: 2,
        childrens: [
          {
            id: 1195,
            pid: 1194,
            district: "环翠区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1196,
            pid: 1194,
            district: "荣成市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1197,
            pid: 1194,
            district: "乳山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1198,
            pid: 1194,
            district: "文登区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1199,
        pid: 1161,
        district: "聊城市",
        level: 2,
        childrens: [
          {
            id: 1200,
            pid: 1199,
            district: "冠县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1201,
            pid: 1199,
            district: "莘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1202,
            pid: 1199,
            district: "茌平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1203,
            pid: 1199,
            district: "东昌府区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1204,
            pid: 1199,
            district: "阳谷县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1205,
            pid: 1199,
            district: "东阿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1206,
            pid: 1199,
            district: "高唐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1207,
            pid: 1199,
            district: "临清市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1208,
        pid: 1161,
        district: "滨州市",
        level: 2,
        childrens: [
          {
            id: 1209,
            pid: 1208,
            district: "滨城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1210,
            pid: 1208,
            district: "沾化区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1211,
            pid: 1208,
            district: "博兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1212,
            pid: 1208,
            district: "邹平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1213,
            pid: 1208,
            district: "无棣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1214,
            pid: 1208,
            district: "阳信县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1215,
            pid: 1208,
            district: "惠民县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1216,
        pid: 1161,
        district: "东营市",
        level: 2,
        childrens: [
          {
            id: 1217,
            pid: 1216,
            district: "利津县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1218,
            pid: 1216,
            district: "广饶县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1219,
            pid: 1216,
            district: "东营区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1220,
            pid: 1216,
            district: "河口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1221,
            pid: 1216,
            district: "垦利区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1222,
        pid: 1161,
        district: "日照市",
        level: 2,
        childrens: [
          {
            id: 1223,
            pid: 1222,
            district: "东港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1224,
            pid: 1222,
            district: "岚山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1225,
            pid: 1222,
            district: "莒县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1226,
            pid: 1222,
            district: "五莲县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1227,
        pid: 1161,
        district: "潍坊市",
        level: 2,
        childrens: [
          {
            id: 1228,
            pid: 1227,
            district: "潍城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1229,
            pid: 1227,
            district: "临朐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1230,
            pid: 1227,
            district: "寿光市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1231,
            pid: 1227,
            district: "寒亭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1232,
            pid: 1227,
            district: "高密市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1233,
            pid: 1227,
            district: "昌邑市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1234,
            pid: 1227,
            district: "安丘市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1235,
            pid: 1227,
            district: "诸城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1236,
            pid: 1227,
            district: "青州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1237,
            pid: 1227,
            district: "坊子区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1238,
            pid: 1227,
            district: "昌乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1239,
            pid: 1227,
            district: "奎文区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1240,
        pid: 1161,
        district: "德州市",
        level: 2,
        childrens: [
          {
            id: 1241,
            pid: 1240,
            district: "宁津县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1242,
            pid: 1240,
            district: "乐陵市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1243,
            pid: 1240,
            district: "德城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1244,
            pid: 1240,
            district: "武城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1245,
            pid: 1240,
            district: "庆云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1246,
            pid: 1240,
            district: "齐河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1247,
            pid: 1240,
            district: "陵城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1248,
            pid: 1240,
            district: "平原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1249,
            pid: 1240,
            district: "夏津县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1250,
            pid: 1240,
            district: "禹城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1251,
            pid: 1240,
            district: "临邑县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1252,
        pid: 1161,
        district: "济南市",
        level: 2,
        childrens: [
          {
            id: 1253,
            pid: 1252,
            district: "章丘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1254,
            pid: 1252,
            district: "平阴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1255,
            pid: 1252,
            district: "长清区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1256,
            pid: 1252,
            district: "商河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1257,
            pid: 1252,
            district: "钢城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1258,
            pid: 1252,
            district: "莱芜区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1259,
            pid: 1252,
            district: "天桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1260,
            pid: 1252,
            district: "济阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1261,
            pid: 1252,
            district: "槐荫区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1262,
            pid: 1252,
            district: "市中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1263,
            pid: 1252,
            district: "历下区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1264,
            pid: 1252,
            district: "历城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1265,
        pid: 1161,
        district: "泰安市",
        level: 2,
        childrens: [
          {
            id: 1266,
            pid: 1265,
            district: "东平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1267,
            pid: 1265,
            district: "泰山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1268,
            pid: 1265,
            district: "岱岳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1269,
            pid: 1265,
            district: "肥城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1270,
            pid: 1265,
            district: "宁阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1271,
            pid: 1265,
            district: "新泰市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1272,
        pid: 1161,
        district: "临沂市",
        level: 2,
        childrens: [
          {
            id: 1273,
            pid: 1272,
            district: "郯城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1274,
            pid: 1272,
            district: "沂南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1275,
            pid: 1272,
            district: "莒南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1276,
            pid: 1272,
            district: "兰山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1277,
            pid: 1272,
            district: "罗庄区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1278,
            pid: 1272,
            district: "兰陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1279,
            pid: 1272,
            district: "临沭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1280,
            pid: 1272,
            district: "河东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1281,
            pid: 1272,
            district: "沂水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1282,
            pid: 1272,
            district: "平邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1283,
            pid: 1272,
            district: "费县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1284,
            pid: 1272,
            district: "蒙阴县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1285,
        pid: 1161,
        district: "济宁市",
        level: 2,
        childrens: [
          {
            id: 1286,
            pid: 1285,
            district: "邹城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1287,
            pid: 1285,
            district: "汶上县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1288,
            pid: 1285,
            district: "泗水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1289,
            pid: 1285,
            district: "嘉祥县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1290,
            pid: 1285,
            district: "金乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1291,
            pid: 1285,
            district: "任城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1292,
            pid: 1285,
            district: "鱼台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1293,
            pid: 1285,
            district: "兖州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1294,
            pid: 1285,
            district: "曲阜市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1295,
            pid: 1285,
            district: "梁山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1296,
            pid: 1285,
            district: "微山县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1297,
        pid: 1161,
        district: "枣庄市",
        level: 2,
        childrens: [
          {
            id: 1298,
            pid: 1297,
            district: "峄城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1299,
            pid: 1297,
            district: "山亭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1300,
            pid: 1297,
            district: "台儿庄区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1301,
            pid: 1297,
            district: "滕州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1302,
            pid: 1297,
            district: "薛城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1303,
            pid: 1297,
            district: "市中区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1304,
        pid: 1161,
        district: "菏泽市",
        level: 2,
        childrens: [
          {
            id: 1305,
            pid: 1304,
            district: "鄄城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1306,
            pid: 1304,
            district: "定陶区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1307,
            pid: 1304,
            district: "巨野县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1308,
            pid: 1304,
            district: "郓城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1309,
            pid: 1304,
            district: "成武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1310,
            pid: 1304,
            district: "东明县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1311,
            pid: 1304,
            district: "牡丹区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1312,
            pid: 1304,
            district: "曹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1313,
            pid: 1304,
            district: "单县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1314,
    pid: 1,
    district: "陕西省",
    level: 1,
    childrens: [
      {
        id: 1315,
        pid: 1314,
        district: "铜川市",
        level: 2,
        childrens: [
          {
            id: 1316,
            pid: 1315,
            district: "宜君县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1317,
            pid: 1315,
            district: "印台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1318,
            pid: 1315,
            district: "王益区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1319,
            pid: 1315,
            district: "耀州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1320,
        pid: 1314,
        district: "汉中市",
        level: 2,
        childrens: [
          {
            id: 1321,
            pid: 1320,
            district: "留坝县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1322,
            pid: 1320,
            district: "洋县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1323,
            pid: 1320,
            district: "佛坪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1324,
            pid: 1320,
            district: "略阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1325,
            pid: 1320,
            district: "宁强县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1326,
            pid: 1320,
            district: "西乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1327,
            pid: 1320,
            district: "勉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1328,
            pid: 1320,
            district: "汉台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1329,
            pid: 1320,
            district: "南郑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1330,
            pid: 1320,
            district: "城固县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1331,
            pid: 1320,
            district: "镇巴县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1332,
        pid: 1314,
        district: "商洛市",
        level: 2,
        childrens: [
          {
            id: 1333,
            pid: 1332,
            district: "丹凤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1334,
            pid: 1332,
            district: "镇安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1335,
            pid: 1332,
            district: "商南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1336,
            pid: 1332,
            district: "洛南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1337,
            pid: 1332,
            district: "商州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1338,
            pid: 1332,
            district: "山阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1339,
            pid: 1332,
            district: "柞水县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1340,
        pid: 1314,
        district: "榆林市",
        level: 2,
        childrens: [
          {
            id: 1341,
            pid: 1340,
            district: "子洲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1342,
            pid: 1340,
            district: "横山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1343,
            pid: 1340,
            district: "靖边县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1344,
            pid: 1340,
            district: "米脂县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1345,
            pid: 1340,
            district: "绥德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1346,
            pid: 1340,
            district: "府谷县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1347,
            pid: 1340,
            district: "榆阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1348,
            pid: 1340,
            district: "神木市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1349,
            pid: 1340,
            district: "佳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1350,
            pid: 1340,
            district: "吴堡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1351,
            pid: 1340,
            district: "清涧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1352,
            pid: 1340,
            district: "定边县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1353,
        pid: 1314,
        district: "延安市",
        level: 2,
        childrens: [
          {
            id: 1354,
            pid: 1353,
            district: "子长市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1355,
            pid: 1353,
            district: "甘泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1356,
            pid: 1353,
            district: "宝塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1357,
            pid: 1353,
            district: "志丹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1358,
            pid: 1353,
            district: "延长县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1359,
            pid: 1353,
            district: "安塞区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1360,
            pid: 1353,
            district: "吴起县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1361,
            pid: 1353,
            district: "延川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1362,
            pid: 1353,
            district: "黄陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1363,
            pid: 1353,
            district: "富县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1364,
            pid: 1353,
            district: "宜川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1365,
            pid: 1353,
            district: "洛川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1366,
            pid: 1353,
            district: "黄龙县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1367,
        pid: 1314,
        district: "西安市",
        level: 2,
        childrens: [
          {
            id: 1368,
            pid: 1367,
            district: "临潼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1369,
            pid: 1367,
            district: "高陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1370,
            pid: 1367,
            district: "鄠邑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1371,
            pid: 1367,
            district: "灞桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1372,
            pid: 1367,
            district: "未央区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1373,
            pid: 1367,
            district: "周至县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1374,
            pid: 1367,
            district: "长安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1375,
            pid: 1367,
            district: "蓝田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1376,
            pid: 1367,
            district: "阎良区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1377,
            pid: 1367,
            district: "碑林区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1378,
            pid: 1367,
            district: "新城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1379,
            pid: 1367,
            district: "雁塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1380,
            pid: 1367,
            district: "莲湖区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1381,
        pid: 1314,
        district: "宝鸡市",
        level: 2,
        childrens: [
          {
            id: 1382,
            pid: 1381,
            district: "陇县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1383,
            pid: 1381,
            district: "千阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1384,
            pid: 1381,
            district: "金台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1385,
            pid: 1381,
            district: "麟游县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1386,
            pid: 1381,
            district: "岐山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1387,
            pid: 1381,
            district: "渭滨区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1388,
            pid: 1381,
            district: "凤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1389,
            pid: 1381,
            district: "太白县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1390,
            pid: 1381,
            district: "陈仓区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1391,
            pid: 1381,
            district: "凤翔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1392,
            pid: 1381,
            district: "眉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1393,
            pid: 1381,
            district: "扶风县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1394,
        pid: 1314,
        district: "安康市",
        level: 2,
        childrens: [
          {
            id: 1395,
            pid: 1394,
            district: "宁陕县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1396,
            pid: 1394,
            district: "平利县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1397,
            pid: 1394,
            district: "石泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1398,
            pid: 1394,
            district: "镇坪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1399,
            pid: 1394,
            district: "紫阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1400,
            pid: 1394,
            district: "岚皋县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1401,
            pid: 1394,
            district: "汉阴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1402,
            pid: 1394,
            district: "白河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1403,
            pid: 1394,
            district: "旬阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1404,
            pid: 1394,
            district: "汉滨区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1405,
        pid: 1314,
        district: "渭南市",
        level: 2,
        childrens: [
          {
            id: 1406,
            pid: 1405,
            district: "韩城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1407,
            pid: 1405,
            district: "白水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1408,
            pid: 1405,
            district: "潼关县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1409,
            pid: 1405,
            district: "澄城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1410,
            pid: 1405,
            district: "蒲城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1411,
            pid: 1405,
            district: "合阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1412,
            pid: 1405,
            district: "华阴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1413,
            pid: 1405,
            district: "华州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1414,
            pid: 1405,
            district: "大荔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1415,
            pid: 1405,
            district: "临渭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1416,
            pid: 1405,
            district: "富平县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1417,
        pid: 1314,
        district: "咸阳市",
        level: 2,
        childrens: [
          {
            id: 1418,
            pid: 1417,
            district: "乾县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1419,
            pid: 1417,
            district: "永寿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1420,
            pid: 1417,
            district: "渭城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1421,
            pid: 1417,
            district: "泾阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1422,
            pid: 1417,
            district: "三原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1423,
            pid: 1417,
            district: "淳化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1424,
            pid: 1417,
            district: "礼泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1425,
            pid: 1417,
            district: "杨陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1426,
            pid: 1417,
            district: "武功县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1427,
            pid: 1417,
            district: "兴平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1428,
            pid: 1417,
            district: "彬州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1429,
            pid: 1417,
            district: "旬邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1430,
            pid: 1417,
            district: "长武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1431,
            pid: 1417,
            district: "秦都区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1432,
    pid: 1,
    district: "上海市",
    level: 1,
    childrens: [
      {
        id: 1433,
        pid: 1432,
        district: "上海市",
        level: 2,
        childrens: [
          {
            id: 1434,
            pid: 1433,
            district: "浦东新区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1435,
            pid: 1433,
            district: "崇明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1436,
            pid: 1433,
            district: "金山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1437,
            pid: 1433,
            district: "奉贤区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1438,
            pid: 1433,
            district: "普陀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1439,
            pid: 1433,
            district: "黄浦区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1440,
            pid: 1433,
            district: "杨浦区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1441,
            pid: 1433,
            district: "静安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1442,
            pid: 1433,
            district: "虹口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1443,
            pid: 1433,
            district: "徐汇区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1444,
            pid: 1433,
            district: "青浦区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1445,
            pid: 1433,
            district: "长宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1446,
            pid: 1433,
            district: "嘉定区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1447,
            pid: 1433,
            district: "宝山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1448,
            pid: 1433,
            district: "松江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1449,
            pid: 1433,
            district: "闵行区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1450,
    pid: 1,
    district: "贵州省",
    level: 1,
    childrens: [
      {
        id: 1451,
        pid: 1450,
        district: "遵义市",
        level: 2,
        childrens: [
          {
            id: 1452,
            pid: 1451,
            district: "湄潭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1453,
            pid: 1451,
            district: "桐梓县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1454,
            pid: 1451,
            district: "绥阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1455,
            pid: 1451,
            district: "余庆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1456,
            pid: 1451,
            district: "播州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1457,
            pid: 1451,
            district: "仁怀市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1458,
            pid: 1451,
            district: "赤水市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1459,
            pid: 1451,
            district: "习水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1460,
            pid: 1451,
            district: "正安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1461,
            pid: 1451,
            district: "道真仡佬族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1462,
            pid: 1451,
            district: "务川仡佬族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1463,
            pid: 1451,
            district: "凤冈县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1464,
            pid: 1451,
            district: "汇川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1465,
            pid: 1451,
            district: "红花岗区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1466,
        pid: 1450,
        district: "铜仁市",
        level: 2,
        childrens: [
          {
            id: 1467,
            pid: 1466,
            district: "石阡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1468,
            pid: 1466,
            district: "碧江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1469,
            pid: 1466,
            district: "德江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1470,
            pid: 1466,
            district: "万山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1471,
            pid: 1466,
            district: "思南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1472,
            pid: 1466,
            district: "玉屏侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1473,
            pid: 1466,
            district: "松桃苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1474,
            pid: 1466,
            district: "江口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1475,
            pid: 1466,
            district: "印江土家族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1476,
            pid: 1466,
            district: "沿河土家族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1477,
        pid: 1450,
        district: "六盘水市",
        level: 2,
        childrens: [
          {
            id: 1478,
            pid: 1477,
            district: "钟山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1479,
            pid: 1477,
            district: "盘州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1480,
            pid: 1477,
            district: "水城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1481,
            pid: 1477,
            district: "六枝特区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1482,
        pid: 1450,
        district: "黔南布依族苗族自治州",
        level: 2,
        childrens: [
          {
            id: 1483,
            pid: 1482,
            district: "惠水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1484,
            pid: 1482,
            district: "独山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1485,
            pid: 1482,
            district: "平塘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1486,
            pid: 1482,
            district: "长顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1487,
            pid: 1482,
            district: "罗甸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1488,
            pid: 1482,
            district: "荔波县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1489,
            pid: 1482,
            district: "龙里县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1490,
            pid: 1482,
            district: "贵定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1491,
            pid: 1482,
            district: "福泉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1492,
            pid: 1482,
            district: "三都水族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1493,
            pid: 1482,
            district: "瓮安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1494,
            pid: 1482,
            district: "都匀市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1495,
        pid: 1450,
        district: "安顺市",
        level: 2,
        childrens: [
          {
            id: 1496,
            pid: 1495,
            district: "西秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1497,
            pid: 1495,
            district: "紫云苗族布依族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1498,
            pid: 1495,
            district: "关岭布依族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1499,
            pid: 1495,
            district: "平坝区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1500,
            pid: 1495,
            district: "普定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1501,
            pid: 1495,
            district: "镇宁布依族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1502,
        pid: 1450,
        district: "黔西南布依族苗族自治州",
        level: 2,
        childrens: [
          {
            id: 1503,
            pid: 1502,
            district: "兴义市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1504,
            pid: 1502,
            district: "册亨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1505,
            pid: 1502,
            district: "安龙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1506,
            pid: 1502,
            district: "兴仁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1507,
            pid: 1502,
            district: "望谟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1508,
            pid: 1502,
            district: "晴隆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1509,
            pid: 1502,
            district: "贞丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1510,
            pid: 1502,
            district: "普安县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1511,
        pid: 1450,
        district: "黔东南苗族侗族自治州",
        level: 2,
        childrens: [
          {
            id: 1512,
            pid: 1511,
            district: "榕江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1513,
            pid: 1511,
            district: "雷山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1514,
            pid: 1511,
            district: "施秉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1515,
            pid: 1511,
            district: "天柱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1516,
            pid: 1511,
            district: "镇远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1517,
            pid: 1511,
            district: "丹寨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1518,
            pid: 1511,
            district: "凯里市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1519,
            pid: 1511,
            district: "黎平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1520,
            pid: 1511,
            district: "从江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1521,
            pid: 1511,
            district: "锦屏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1522,
            pid: 1511,
            district: "台江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1523,
            pid: 1511,
            district: "黄平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1524,
            pid: 1511,
            district: "岑巩县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1525,
            pid: 1511,
            district: "麻江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1526,
            pid: 1511,
            district: "剑河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1527,
            pid: 1511,
            district: "三穗县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1528,
        pid: 1450,
        district: "毕节市",
        level: 2,
        childrens: [
          {
            id: 1529,
            pid: 1528,
            district: "七星关区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1530,
            pid: 1528,
            district: "黔西市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1531,
            pid: 1528,
            district: "金沙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1532,
            pid: 1528,
            district: "织金县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1533,
            pid: 1528,
            district: "威宁彝族回族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1534,
            pid: 1528,
            district: "赫章县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1535,
            pid: 1528,
            district: "大方县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1536,
            pid: 1528,
            district: "纳雍县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1537,
        pid: 1450,
        district: "贵阳市",
        level: 2,
        childrens: [
          {
            id: 1538,
            pid: 1537,
            district: "开阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1539,
            pid: 1537,
            district: "息烽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1540,
            pid: 1537,
            district: "白云区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1541,
            pid: 1537,
            district: "修文县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1542,
            pid: 1537,
            district: "清镇市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1543,
            pid: 1537,
            district: "花溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1544,
            pid: 1537,
            district: "观山湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1545,
            pid: 1537,
            district: "乌当区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1546,
            pid: 1537,
            district: "南明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1547,
            pid: 1537,
            district: "云岩区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1548,
    pid: 1,
    district: "重庆市",
    level: 1,
    childrens: [
      {
        id: 1549,
        pid: 1548,
        district: "重庆郊县",
        level: 2,
        childrens: [
          {
            id: 1550,
            pid: 1549,
            district: "巫溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1551,
            pid: 1549,
            district: "彭水苗族土家族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1552,
            pid: 1549,
            district: "城口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1553,
            pid: 1549,
            district: "奉节县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1554,
            pid: 1549,
            district: "垫江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1555,
            pid: 1549,
            district: "秀山土家族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1556,
            pid: 1549,
            district: "云阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1557,
            pid: 1549,
            district: "石柱土家族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1558,
            pid: 1549,
            district: "忠县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1559,
            pid: 1549,
            district: "丰都县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1560,
            pid: 1549,
            district: "酉阳土家族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1561,
            pid: 1549,
            district: "巫山县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1562,
        pid: 1548,
        district: "重庆城区",
        level: 2,
        childrens: [
          {
            id: 1563,
            pid: 1562,
            district: "潼南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1564,
            pid: 1562,
            district: "大足区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1565,
            pid: 1562,
            district: "铜梁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1566,
            pid: 1562,
            district: "合川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1567,
            pid: 1562,
            district: "璧山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1568,
            pid: 1562,
            district: "长寿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1569,
            pid: 1562,
            district: "大渡口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1570,
            pid: 1562,
            district: "万州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1571,
            pid: 1562,
            district: "涪陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1572,
            pid: 1562,
            district: "渝中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1573,
            pid: 1562,
            district: "南川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1574,
            pid: 1562,
            district: "九龙坡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1575,
            pid: 1562,
            district: "綦江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1576,
            pid: 1562,
            district: "梁平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1577,
            pid: 1562,
            district: "开州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1578,
            pid: 1562,
            district: "黔江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1579,
            pid: 1562,
            district: "江津区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1580,
            pid: 1562,
            district: "南岸区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1581,
            pid: 1562,
            district: "巴南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1582,
            pid: 1562,
            district: "北碚区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1583,
            pid: 1562,
            district: "武隆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1584,
            pid: 1562,
            district: "江北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1585,
            pid: 1562,
            district: "渝北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1586,
            pid: 1562,
            district: "沙坪坝区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1587,
            pid: 1562,
            district: "永川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1588,
            pid: 1562,
            district: "荣昌区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1589,
    pid: 1,
    district: "西藏自治区",
    level: 1,
    childrens: [
      {
        id: 1590,
        pid: 1589,
        district: "林芝市",
        level: 2,
        childrens: [
          {
            id: 1591,
            pid: 1590,
            district: "波密县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1592,
            pid: 1590,
            district: "工布江达县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1593,
            pid: 1590,
            district: "巴宜区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1594,
            pid: 1590,
            district: "墨脱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1595,
            pid: 1590,
            district: "米林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1596,
            pid: 1590,
            district: "察隅县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1597,
            pid: 1590,
            district: "朗县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1598,
        pid: 1589,
        district: "日喀则市",
        level: 2,
        childrens: [
          {
            id: 1599,
            pid: 1598,
            district: "仲巴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1600,
            pid: 1598,
            district: "昂仁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1601,
            pid: 1598,
            district: "南木林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1602,
            pid: 1598,
            district: "萨嘎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1603,
            pid: 1598,
            district: "谢通门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1604,
            pid: 1598,
            district: "拉孜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1605,
            pid: 1598,
            district: "桑珠孜区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1606,
            pid: 1598,
            district: "仁布县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1607,
            pid: 1598,
            district: "萨迦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1608,
            pid: 1598,
            district: "吉隆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1609,
            pid: 1598,
            district: "白朗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1610,
            pid: 1598,
            district: "江孜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1611,
            pid: 1598,
            district: "定结县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1612,
            pid: 1598,
            district: "定日县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1613,
            pid: 1598,
            district: "康马县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1614,
            pid: 1598,
            district: "聂拉木县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1615,
            pid: 1598,
            district: "岗巴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1616,
            pid: 1598,
            district: "亚东县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1617,
        pid: 1589,
        district: "拉萨市",
        level: 2,
        childrens: [
          {
            id: 1618,
            pid: 1617,
            district: "当雄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1619,
            pid: 1617,
            district: "墨竹工卡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1620,
            pid: 1617,
            district: "达孜区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1621,
            pid: 1617,
            district: "林周县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1622,
            pid: 1617,
            district: "堆龙德庆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1623,
            pid: 1617,
            district: "尼木县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1624,
            pid: 1617,
            district: "曲水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1625,
            pid: 1617,
            district: "城关区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1626,
        pid: 1589,
        district: "昌都市",
        level: 2,
        childrens: [
          {
            id: 1627,
            pid: 1626,
            district: "丁青县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1628,
            pid: 1626,
            district: "江达县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1629,
            pid: 1626,
            district: "类乌齐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1630,
            pid: 1626,
            district: "卡若区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1631,
            pid: 1626,
            district: "贡觉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1632,
            pid: 1626,
            district: "边坝县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1633,
            pid: 1626,
            district: "察雅县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1634,
            pid: 1626,
            district: "洛隆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1635,
            pid: 1626,
            district: "八宿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1636,
            pid: 1626,
            district: "芒康县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1637,
            pid: 1626,
            district: "左贡县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1638,
        pid: 1589,
        district: "那曲市",
        level: 2,
        childrens: [
          {
            id: 1639,
            pid: 1638,
            district: "聂荣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1640,
            pid: 1638,
            district: "申扎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1641,
            pid: 1638,
            district: "巴青县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1642,
            pid: 1638,
            district: "安多县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1643,
            pid: 1638,
            district: "班戈县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1644,
            pid: 1638,
            district: "索县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1645,
            pid: 1638,
            district: "色尼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1646,
            pid: 1638,
            district: "比如县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1647,
            pid: 1638,
            district: "嘉黎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1648,
            pid: 1638,
            district: "尼玛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1649,
            pid: 1638,
            district: "双湖县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1650,
        pid: 1589,
        district: "阿里地区",
        level: 2,
        childrens: [
          {
            id: 1651,
            pid: 1650,
            district: "札达县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1652,
            pid: 1650,
            district: "改则县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1653,
            pid: 1650,
            district: "措勤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1654,
            pid: 1650,
            district: "普兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1655,
            pid: 1650,
            district: "噶尔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1656,
            pid: 1650,
            district: "革吉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1657,
            pid: 1650,
            district: "日土县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1658,
        pid: 1589,
        district: "山南市",
        level: 2,
        childrens: [
          {
            id: 1659,
            pid: 1658,
            district: "乃东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1660,
            pid: 1658,
            district: "加查县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1661,
            pid: 1658,
            district: "桑日县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1662,
            pid: 1658,
            district: "贡嘎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1663,
            pid: 1658,
            district: "扎囊县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1664,
            pid: 1658,
            district: "曲松县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1665,
            pid: 1658,
            district: "浪卡子县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1666,
            pid: 1658,
            district: "措美县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1667,
            pid: 1658,
            district: "隆子县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1668,
            pid: 1658,
            district: "洛扎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1669,
            pid: 1658,
            district: "琼结县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1670,
            pid: 1658,
            district: "错那县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1671,
    pid: 1,
    district: "安徽省",
    level: 1,
    childrens: [
      {
        id: 1672,
        pid: 1671,
        district: "淮北市",
        level: 2,
        childrens: [
          {
            id: 1673,
            pid: 1672,
            district: "杜集区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1674,
            pid: 1672,
            district: "相山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1675,
            pid: 1672,
            district: "烈山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1676,
            pid: 1672,
            district: "濉溪县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1677,
        pid: 1671,
        district: "阜阳市",
        level: 2,
        childrens: [
          {
            id: 1678,
            pid: 1677,
            district: "太和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1679,
            pid: 1677,
            district: "阜南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1680,
            pid: 1677,
            district: "颍州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1681,
            pid: 1677,
            district: "临泉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1682,
            pid: 1677,
            district: "颍上县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1683,
            pid: 1677,
            district: "界首市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1684,
            pid: 1677,
            district: "颍泉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1685,
            pid: 1677,
            district: "颍东区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1686,
        pid: 1671,
        district: "黄山市",
        level: 2,
        childrens: [
          {
            id: 1687,
            pid: 1686,
            district: "黄山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1688,
            pid: 1686,
            district: "黟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1689,
            pid: 1686,
            district: "祁门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1690,
            pid: 1686,
            district: "屯溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1691,
            pid: 1686,
            district: "徽州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1692,
            pid: 1686,
            district: "歙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1693,
            pid: 1686,
            district: "休宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1694,
        pid: 1671,
        district: "马鞍山市",
        level: 2,
        childrens: [
          {
            id: 1695,
            pid: 1694,
            district: "博望区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1696,
            pid: 1694,
            district: "雨山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1697,
            pid: 1694,
            district: "花山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1698,
            pid: 1694,
            district: "含山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1699,
            pid: 1694,
            district: "当涂县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1700,
            pid: 1694,
            district: "和县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1701,
        pid: 1671,
        district: "铜陵市",
        level: 2,
        childrens: [
          {
            id: 1702,
            pid: 1701,
            district: "郊区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1703,
            pid: 1701,
            district: "铜官区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1704,
            pid: 1701,
            district: "枞阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1705,
            pid: 1701,
            district: "义安区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1706,
        pid: 1671,
        district: "池州市",
        level: 2,
        childrens: [
          {
            id: 1707,
            pid: 1706,
            district: "贵池区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1708,
            pid: 1706,
            district: "石台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1709,
            pid: 1706,
            district: "青阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1710,
            pid: 1706,
            district: "东至县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1711,
        pid: 1671,
        district: "安庆市",
        level: 2,
        childrens: [
          {
            id: 1712,
            pid: 1711,
            district: "迎江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1713,
            pid: 1711,
            district: "宜秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1714,
            pid: 1711,
            district: "潜山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1715,
            pid: 1711,
            district: "桐城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1716,
            pid: 1711,
            district: "岳西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1717,
            pid: 1711,
            district: "望江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1718,
            pid: 1711,
            district: "宿松县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1719,
            pid: 1711,
            district: "大观区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1720,
            pid: 1711,
            district: "怀宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1721,
            pid: 1711,
            district: "太湖县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1722,
        pid: 1671,
        district: "滁州市",
        level: 2,
        childrens: [
          {
            id: 1723,
            pid: 1722,
            district: "明光市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1724,
            pid: 1722,
            district: "天长市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1725,
            pid: 1722,
            district: "琅琊区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1726,
            pid: 1722,
            district: "全椒县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1727,
            pid: 1722,
            district: "凤阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1728,
            pid: 1722,
            district: "定远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1729,
            pid: 1722,
            district: "来安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1730,
            pid: 1722,
            district: "南谯区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1731,
        pid: 1671,
        district: "淮南市",
        level: 2,
        childrens: [
          {
            id: 1732,
            pid: 1731,
            district: "凤台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1733,
            pid: 1731,
            district: "八公山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1734,
            pid: 1731,
            district: "潘集区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1735,
            pid: 1731,
            district: "大通区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1736,
            pid: 1731,
            district: "田家庵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1737,
            pid: 1731,
            district: "谢家集区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1738,
            pid: 1731,
            district: "寿县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1739,
        pid: 1671,
        district: "蚌埠市",
        level: 2,
        childrens: [
          {
            id: 1740,
            pid: 1739,
            district: "五河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1741,
            pid: 1739,
            district: "淮上区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1742,
            pid: 1739,
            district: "怀远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1743,
            pid: 1739,
            district: "禹会区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1744,
            pid: 1739,
            district: "龙子湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1745,
            pid: 1739,
            district: "蚌山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1746,
            pid: 1739,
            district: "固镇县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1747,
        pid: 1671,
        district: "宿州市",
        level: 2,
        childrens: [
          {
            id: 1748,
            pid: 1747,
            district: "埇桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1749,
            pid: 1747,
            district: "萧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1750,
            pid: 1747,
            district: "砀山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1751,
            pid: 1747,
            district: "泗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1752,
            pid: 1747,
            district: "灵璧县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1753,
        pid: 1671,
        district: "亳州市",
        level: 2,
        childrens: [
          {
            id: 1754,
            pid: 1753,
            district: "蒙城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1755,
            pid: 1753,
            district: "利辛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1756,
            pid: 1753,
            district: "涡阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1757,
            pid: 1753,
            district: "谯城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1758,
        pid: 1671,
        district: "合肥市",
        level: 2,
        childrens: [
          {
            id: 1759,
            pid: 1758,
            district: "瑶海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1760,
            pid: 1758,
            district: "巢湖市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1761,
            pid: 1758,
            district: "肥东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1762,
            pid: 1758,
            district: "长丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1763,
            pid: 1758,
            district: "庐江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1764,
            pid: 1758,
            district: "包河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1765,
            pid: 1758,
            district: "庐阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1766,
            pid: 1758,
            district: "肥西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1767,
            pid: 1758,
            district: "蜀山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1768,
        pid: 1671,
        district: "宣城市",
        level: 2,
        childrens: [
          {
            id: 1769,
            pid: 1768,
            district: "旌德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1770,
            pid: 1768,
            district: "宣州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1771,
            pid: 1768,
            district: "郎溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1772,
            pid: 1768,
            district: "泾县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1773,
            pid: 1768,
            district: "宁国市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1774,
            pid: 1768,
            district: "绩溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1775,
            pid: 1768,
            district: "广德市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1776,
        pid: 1671,
        district: "六安市",
        level: 2,
        childrens: [
          {
            id: 1777,
            pid: 1776,
            district: "霍山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1778,
            pid: 1776,
            district: "裕安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1779,
            pid: 1776,
            district: "金安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1780,
            pid: 1776,
            district: "金寨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1781,
            pid: 1776,
            district: "霍邱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1782,
            pid: 1776,
            district: "叶集区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1783,
            pid: 1776,
            district: "舒城县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1784,
        pid: 1671,
        district: "芜湖市",
        level: 2,
        childrens: [
          {
            id: 1785,
            pid: 1784,
            district: "繁昌区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1786,
            pid: 1784,
            district: "镜湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1787,
            pid: 1784,
            district: "无为市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1788,
            pid: 1784,
            district: "鸠江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1789,
            pid: 1784,
            district: "弋江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1790,
            pid: 1784,
            district: "湾沚区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1791,
            pid: 1784,
            district: "南陵县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1792,
    pid: 1,
    district: "福建省",
    level: 1,
    childrens: [
      {
        id: 1793,
        pid: 1792,
        district: "宁德市",
        level: 2,
        childrens: [
          {
            id: 1794,
            pid: 1793,
            district: "福安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1795,
            pid: 1793,
            district: "寿宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1796,
            pid: 1793,
            district: "古田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1797,
            pid: 1793,
            district: "福鼎市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1798,
            pid: 1793,
            district: "霞浦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1799,
            pid: 1793,
            district: "蕉城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1800,
            pid: 1793,
            district: "柘荣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1801,
            pid: 1793,
            district: "屏南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1802,
            pid: 1793,
            district: "周宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1803,
        pid: 1792,
        district: "福州市",
        level: 2,
        childrens: [
          {
            id: 1804,
            pid: 1803,
            district: "闽清县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1805,
            pid: 1803,
            district: "罗源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1806,
            pid: 1803,
            district: "连江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1807,
            pid: 1803,
            district: "永泰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1808,
            pid: 1803,
            district: "长乐区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1809,
            pid: 1803,
            district: "福清市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1810,
            pid: 1803,
            district: "平潭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1811,
            pid: 1803,
            district: "台江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1812,
            pid: 1803,
            district: "马尾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1813,
            pid: 1803,
            district: "仓山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1814,
            pid: 1803,
            district: "闽侯县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1815,
            pid: 1803,
            district: "晋安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1816,
            pid: 1803,
            district: "鼓楼区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1817,
        pid: 1792,
        district: "莆田市",
        level: 2,
        childrens: [
          {
            id: 1818,
            pid: 1817,
            district: "秀屿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1819,
            pid: 1817,
            district: "仙游县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1820,
            pid: 1817,
            district: "荔城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1821,
            pid: 1817,
            district: "城厢区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1822,
            pid: 1817,
            district: "涵江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1823,
        pid: 1792,
        district: "厦门市",
        level: 2,
        childrens: [
          {
            id: 1824,
            pid: 1823,
            district: "湖里区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1825,
            pid: 1823,
            district: "翔安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1826,
            pid: 1823,
            district: "思明区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1827,
            pid: 1823,
            district: "同安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1828,
            pid: 1823,
            district: "集美区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1829,
            pid: 1823,
            district: "海沧区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1830,
        pid: 1792,
        district: "泉州市",
        level: 2,
        childrens: [
          {
            id: 1831,
            pid: 1830,
            district: "德化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1832,
            pid: 1830,
            district: "永春县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1833,
            pid: 1830,
            district: "晋江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1834,
            pid: 1830,
            district: "南安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1835,
            pid: 1830,
            district: "洛江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1836,
            pid: 1830,
            district: "惠安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1837,
            pid: 1830,
            district: "金门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1838,
            pid: 1830,
            district: "石狮市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1839,
            pid: 1830,
            district: "泉港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1840,
            pid: 1830,
            district: "安溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1841,
            pid: 1830,
            district: "丰泽区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1842,
            pid: 1830,
            district: "鲤城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1843,
        pid: 1792,
        district: "漳州市",
        level: 2,
        childrens: [
          {
            id: 1844,
            pid: 1843,
            district: "东山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1845,
            pid: 1843,
            district: "龙海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1846,
            pid: 1843,
            district: "漳浦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1847,
            pid: 1843,
            district: "芗城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1848,
            pid: 1843,
            district: "长泰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1849,
            pid: 1843,
            district: "诏安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1850,
            pid: 1843,
            district: "平和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1851,
            pid: 1843,
            district: "云霄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1852,
            pid: 1843,
            district: "南靖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1853,
            pid: 1843,
            district: "龙文区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1854,
            pid: 1843,
            district: "华安县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1855,
        pid: 1792,
        district: "南平市",
        level: 2,
        childrens: [
          {
            id: 1856,
            pid: 1855,
            district: "光泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1857,
            pid: 1855,
            district: "浦城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1858,
            pid: 1855,
            district: "武夷山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1859,
            pid: 1855,
            district: "建阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1860,
            pid: 1855,
            district: "松溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1861,
            pid: 1855,
            district: "政和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1862,
            pid: 1855,
            district: "邵武市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1863,
            pid: 1855,
            district: "建瓯市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1864,
            pid: 1855,
            district: "顺昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1865,
            pid: 1855,
            district: "延平区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1866,
        pid: 1792,
        district: "三明市",
        level: 2,
        childrens: [
          {
            id: 1867,
            pid: 1866,
            district: "泰宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1868,
            pid: 1866,
            district: "大田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1869,
            pid: 1866,
            district: "沙县区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1870,
            pid: 1866,
            district: "建宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1871,
            pid: 1866,
            district: "尤溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1872,
            pid: 1866,
            district: "将乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1873,
            pid: 1866,
            district: "明溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1874,
            pid: 1866,
            district: "三元区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1875,
            pid: 1866,
            district: "宁化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1876,
            pid: 1866,
            district: "永安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1877,
            pid: 1866,
            district: "清流县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1878,
        pid: 1792,
        district: "龙岩市",
        level: 2,
        childrens: [
          {
            id: 1879,
            pid: 1878,
            district: "上杭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1880,
            pid: 1878,
            district: "武平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1881,
            pid: 1878,
            district: "长汀县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1882,
            pid: 1878,
            district: "永定区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1883,
            pid: 1878,
            district: "连城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1884,
            pid: 1878,
            district: "新罗区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1885,
            pid: 1878,
            district: "漳平市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 1886,
    pid: 1,
    district: "湖南省",
    level: 1,
    childrens: [
      {
        id: 1887,
        pid: 1886,
        district: "长沙市",
        level: 2,
        childrens: [
          {
            id: 1888,
            pid: 1887,
            district: "岳麓区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1889,
            pid: 1887,
            district: "浏阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1890,
            pid: 1887,
            district: "芙蓉区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1891,
            pid: 1887,
            district: "开福区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1892,
            pid: 1887,
            district: "天心区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1893,
            pid: 1887,
            district: "雨花区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1894,
            pid: 1887,
            district: "长沙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1895,
            pid: 1887,
            district: "望城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1896,
            pid: 1887,
            district: "宁乡市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1897,
        pid: 1886,
        district: "衡阳市",
        level: 2,
        childrens: [
          {
            id: 1898,
            pid: 1897,
            district: "衡山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1899,
            pid: 1897,
            district: "衡阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1900,
            pid: 1897,
            district: "衡东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1901,
            pid: 1897,
            district: "南岳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1902,
            pid: 1897,
            district: "珠晖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1903,
            pid: 1897,
            district: "衡南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1904,
            pid: 1897,
            district: "祁东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1905,
            pid: 1897,
            district: "雁峰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1906,
            pid: 1897,
            district: "石鼓区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1907,
            pid: 1897,
            district: "耒阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1908,
            pid: 1897,
            district: "蒸湘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1909,
            pid: 1897,
            district: "常宁市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1910,
        pid: 1886,
        district: "湘西土家族苗族自治州",
        level: 2,
        childrens: [
          {
            id: 1911,
            pid: 1910,
            district: "永顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1912,
            pid: 1910,
            district: "保靖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1913,
            pid: 1910,
            district: "花垣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1914,
            pid: 1910,
            district: "吉首市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1915,
            pid: 1910,
            district: "古丈县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1916,
            pid: 1910,
            district: "泸溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1917,
            pid: 1910,
            district: "龙山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1918,
            pid: 1910,
            district: "凤凰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1919,
        pid: 1886,
        district: "岳阳市",
        level: 2,
        childrens: [
          {
            id: 1920,
            pid: 1919,
            district: "君山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1921,
            pid: 1919,
            district: "华容县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1922,
            pid: 1919,
            district: "云溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1923,
            pid: 1919,
            district: "岳阳楼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1924,
            pid: 1919,
            district: "汨罗市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1925,
            pid: 1919,
            district: "湘阴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1926,
            pid: 1919,
            district: "岳阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1927,
            pid: 1919,
            district: "临湘市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1928,
            pid: 1919,
            district: "平江县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1929,
        pid: 1886,
        district: "常德市",
        level: 2,
        childrens: [
          {
            id: 1930,
            pid: 1929,
            district: "津市市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1931,
            pid: 1929,
            district: "澧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1932,
            pid: 1929,
            district: "汉寿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1933,
            pid: 1929,
            district: "鼎城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1934,
            pid: 1929,
            district: "武陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1935,
            pid: 1929,
            district: "桃源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1936,
            pid: 1929,
            district: "安乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1937,
            pid: 1929,
            district: "石门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1938,
            pid: 1929,
            district: "临澧县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1939,
        pid: 1886,
        district: "张家界市",
        level: 2,
        childrens: [
          {
            id: 1940,
            pid: 1939,
            district: "慈利县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1941,
            pid: 1939,
            district: "武陵源区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1942,
            pid: 1939,
            district: "桑植县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1943,
            pid: 1939,
            district: "永定区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1944,
        pid: 1886,
        district: "怀化市",
        level: 2,
        childrens: [
          {
            id: 1945,
            pid: 1944,
            district: "洪江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1946,
            pid: 1944,
            district: "芷江侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1947,
            pid: 1944,
            district: "通道侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1948,
            pid: 1944,
            district: "沅陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1949,
            pid: 1944,
            district: "辰溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1950,
            pid: 1944,
            district: "溆浦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1951,
            pid: 1944,
            district: "会同县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1952,
            pid: 1944,
            district: "靖州苗族侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1953,
            pid: 1944,
            district: "新晃侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1954,
            pid: 1944,
            district: "麻阳苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1955,
            pid: 1944,
            district: "鹤城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1956,
            pid: 1944,
            district: "中方县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1957,
        pid: 1886,
        district: "湘潭市",
        level: 2,
        childrens: [
          {
            id: 1958,
            pid: 1957,
            district: "雨湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1959,
            pid: 1957,
            district: "岳塘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1960,
            pid: 1957,
            district: "湘潭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1961,
            pid: 1957,
            district: "韶山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1962,
            pid: 1957,
            district: "湘乡市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1963,
        pid: 1886,
        district: "邵阳市",
        level: 2,
        childrens: [
          {
            id: 1964,
            pid: 1963,
            district: "隆回县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1965,
            pid: 1963,
            district: "双清区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1966,
            pid: 1963,
            district: "北塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1967,
            pid: 1963,
            district: "邵阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1968,
            pid: 1963,
            district: "大祥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1969,
            pid: 1963,
            district: "绥宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1970,
            pid: 1963,
            district: "城步苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1971,
            pid: 1963,
            district: "邵东市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1972,
            pid: 1963,
            district: "新邵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1973,
            pid: 1963,
            district: "新宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1974,
            pid: 1963,
            district: "洞口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1975,
            pid: 1963,
            district: "武冈市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1976,
        pid: 1886,
        district: "株洲市",
        level: 2,
        childrens: [
          {
            id: 1977,
            pid: 1976,
            district: "荷塘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1978,
            pid: 1976,
            district: "攸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1979,
            pid: 1976,
            district: "茶陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1980,
            pid: 1976,
            district: "炎陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1981,
            pid: 1976,
            district: "芦淞区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1982,
            pid: 1976,
            district: "天元区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1983,
            pid: 1976,
            district: "渌口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1984,
            pid: 1976,
            district: "石峰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1985,
            pid: 1976,
            district: "醴陵市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1986,
        pid: 1886,
        district: "郴州市",
        level: 2,
        childrens: [
          {
            id: 1987,
            pid: 1986,
            district: "安仁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1988,
            pid: 1986,
            district: "资兴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1989,
            pid: 1986,
            district: "苏仙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1990,
            pid: 1986,
            district: "永兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1991,
            pid: 1986,
            district: "北湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1992,
            pid: 1986,
            district: "桂东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1993,
            pid: 1986,
            district: "汝城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1994,
            pid: 1986,
            district: "宜章县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1995,
            pid: 1986,
            district: "桂阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1996,
            pid: 1986,
            district: "嘉禾县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 1997,
            pid: 1986,
            district: "临武县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 1998,
        pid: 1886,
        district: "永州市",
        level: 2,
        childrens: [
          {
            id: 1999,
            pid: 1998,
            district: "祁阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2000,
            pid: 1998,
            district: "冷水滩区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2001,
            pid: 1998,
            district: "东安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2002,
            pid: 1998,
            district: "零陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2003,
            pid: 1998,
            district: "新田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2004,
            pid: 1998,
            district: "宁远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2005,
            pid: 1998,
            district: "双牌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2006,
            pid: 1998,
            district: "江永县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2007,
            pid: 1998,
            district: "道县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2008,
            pid: 1998,
            district: "江华瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2009,
            pid: 1998,
            district: "蓝山县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2010,
        pid: 1886,
        district: "娄底市",
        level: 2,
        childrens: [
          {
            id: 2011,
            pid: 2010,
            district: "冷水江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2012,
            pid: 2010,
            district: "新化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2013,
            pid: 2010,
            district: "双峰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2014,
            pid: 2010,
            district: "涟源市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2015,
            pid: 2010,
            district: "娄星区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2016,
        pid: 1886,
        district: "益阳市",
        level: 2,
        childrens: [
          {
            id: 2017,
            pid: 2016,
            district: "桃江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2018,
            pid: 2016,
            district: "资阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2019,
            pid: 2016,
            district: "赫山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2020,
            pid: 2016,
            district: "安化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2021,
            pid: 2016,
            district: "南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2022,
            pid: 2016,
            district: "沅江市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2023,
    pid: 1,
    district: "海南省",
    level: 1,
    childrens: [
      {
        id: 2024,
        pid: 2023,
        district: "白沙黎族自治县",
        level: 2,
        childrens: [
          {
            id: 2025,
            pid: 2024,
            district: "南开乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2026,
            pid: 2024,
            district: "阜龙乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2027,
            pid: 2024,
            district: "邦溪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2028,
            pid: 2024,
            district: "金波乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2029,
            pid: 2024,
            district: "牙叉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2030,
            pid: 2024,
            district: "打安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2031,
            pid: 2024,
            district: "青松乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2032,
            pid: 2024,
            district: "荣邦乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2033,
            pid: 2024,
            district: "细水乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2034,
            pid: 2024,
            district: "元门乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2035,
            pid: 2024,
            district: "七坊镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2036,
        pid: 2023,
        district: "东方市",
        level: 2,
        childrens: [
          {
            id: 2037,
            pid: 2036,
            district: "江边乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2038,
            pid: 2036,
            district: "天安乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2039,
            pid: 2036,
            district: "三家镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2040,
            pid: 2036,
            district: "四更镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2041,
            pid: 2036,
            district: "东河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2042,
            pid: 2036,
            district: "八所镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2043,
            pid: 2036,
            district: "感城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2044,
            pid: 2036,
            district: "新龙镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2045,
            pid: 2036,
            district: "板桥镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2046,
            pid: 2036,
            district: "大田镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2047,
        pid: 2023,
        district: "琼海市",
        level: 2,
        childrens: [
          {
            id: 2048,
            pid: 2047,
            district: "嘉积镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2049,
            pid: 2047,
            district: "潭门镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2050,
            pid: 2047,
            district: "塔洋镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2051,
            pid: 2047,
            district: "博鳌镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2052,
            pid: 2047,
            district: "中原镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2053,
            pid: 2047,
            district: "石壁镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2054,
            pid: 2047,
            district: "长坡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2055,
            pid: 2047,
            district: "大路镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2056,
            pid: 2047,
            district: "万泉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2057,
            pid: 2047,
            district: "龙江镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2058,
            pid: 2047,
            district: "会山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2059,
            pid: 2047,
            district: "阳江镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2060,
        pid: 2023,
        district: "昌江黎族自治县",
        level: 2,
        childrens: [
          {
            id: 2061,
            pid: 2060,
            district: "海尾镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2062,
            pid: 2060,
            district: "叉河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2063,
            pid: 2060,
            district: "石碌镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2064,
            pid: 2060,
            district: "乌烈镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2065,
            pid: 2060,
            district: "昌化镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2066,
            pid: 2060,
            district: "王下乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2067,
            pid: 2060,
            district: "七叉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2068,
            pid: 2060,
            district: "十月田镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2069,
        pid: 2023,
        district: "屯昌县",
        level: 2,
        childrens: [
          {
            id: 2070,
            pid: 2069,
            district: "乌坡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2071,
            pid: 2069,
            district: "西昌镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2072,
            pid: 2069,
            district: "枫木镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2073,
            pid: 2069,
            district: "南坤镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2074,
            pid: 2069,
            district: "南吕镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2075,
            pid: 2069,
            district: "屯城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2076,
            pid: 2069,
            district: "坡心镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2077,
            pid: 2069,
            district: "新兴镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2078,
        pid: 2023,
        district: "临高县",
        level: 2,
        childrens: [
          {
            id: 2079,
            pid: 2078,
            district: "博厚镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2080,
            pid: 2078,
            district: "南宝镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2081,
            pid: 2078,
            district: "和舍镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2082,
            pid: 2078,
            district: "调楼镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2083,
            pid: 2078,
            district: "波莲镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2084,
            pid: 2078,
            district: "加来镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2085,
            pid: 2078,
            district: "东英镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2086,
            pid: 2078,
            district: "新盈镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2087,
            pid: 2078,
            district: "临城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2088,
            pid: 2078,
            district: "多文镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2089,
            pid: 2078,
            district: "皇桐镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2090,
        pid: 2023,
        district: "琼中黎族苗族自治县",
        level: 2,
        childrens: [
          {
            id: 2091,
            pid: 2090,
            district: "上安乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2092,
            pid: 2090,
            district: "什运乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2093,
            pid: 2090,
            district: "湾岭镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2094,
            pid: 2090,
            district: "红毛镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2095,
            pid: 2090,
            district: "黎母山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2096,
            pid: 2090,
            district: "营根镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2097,
            pid: 2090,
            district: "吊罗山乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2098,
            pid: 2090,
            district: "中平镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2099,
            pid: 2090,
            district: "和平镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2100,
            pid: 2090,
            district: "长征镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2101,
        pid: 2023,
        district: "定安县",
        level: 2,
        childrens: [
          {
            id: 2102,
            pid: 2101,
            district: "岭口镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2103,
            pid: 2101,
            district: "新竹镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2104,
            pid: 2101,
            district: "龙门镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2105,
            pid: 2101,
            district: "富文镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2106,
            pid: 2101,
            district: "翰林镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2107,
            pid: 2101,
            district: "定城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2108,
            pid: 2101,
            district: "龙河镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2109,
            pid: 2101,
            district: "雷鸣镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2110,
            pid: 2101,
            district: "黄竹镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2111,
            pid: 2101,
            district: "龙湖镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2112,
        pid: 2023,
        district: "乐东黎族自治县",
        level: 2,
        childrens: [
          {
            id: 2113,
            pid: 2112,
            district: "利国镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2114,
            pid: 2112,
            district: "佛罗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2115,
            pid: 2112,
            district: "大安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2116,
            pid: 2112,
            district: "黄流镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2117,
            pid: 2112,
            district: "志仲镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2118,
            pid: 2112,
            district: "莺歌海镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2119,
            pid: 2112,
            district: "抱由镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2120,
            pid: 2112,
            district: "尖峰镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2121,
            pid: 2112,
            district: "九所镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2122,
            pid: 2112,
            district: "万冲镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2123,
            pid: 2112,
            district: "千家镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2124,
        pid: 2023,
        district: "万宁市",
        level: 2,
        childrens: [
          {
            id: 2125,
            pid: 2124,
            district: "后安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2126,
            pid: 2124,
            district: "礼纪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2127,
            pid: 2124,
            district: "万城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2128,
            pid: 2124,
            district: "大茂镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2129,
            pid: 2124,
            district: "和乐镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2130,
            pid: 2124,
            district: "山根镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2131,
            pid: 2124,
            district: "东澳镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2132,
            pid: 2124,
            district: "南桥镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2133,
            pid: 2124,
            district: "龙滚镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2134,
            pid: 2124,
            district: "长丰镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2135,
            pid: 2124,
            district: "三更罗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2136,
            pid: 2124,
            district: "北大镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2137,
        pid: 2023,
        district: "澄迈县",
        level: 2,
        childrens: [
          {
            id: 2138,
            pid: 2137,
            district: "金江镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2139,
            pid: 2137,
            district: "老城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2140,
            pid: 2137,
            district: "永发镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2141,
            pid: 2137,
            district: "桥头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2142,
            pid: 2137,
            district: "大丰镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2143,
            pid: 2137,
            district: "瑞溪镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2144,
            pid: 2137,
            district: "福山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2145,
            pid: 2137,
            district: "仁兴镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2146,
            pid: 2137,
            district: "加乐镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2147,
            pid: 2137,
            district: "文儒镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2148,
            pid: 2137,
            district: "中兴镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2149,
        pid: 2023,
        district: "陵水黎族自治县",
        level: 2,
        childrens: [
          {
            id: 2150,
            pid: 2149,
            district: "椰林镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2151,
            pid: 2149,
            district: "新村镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2152,
            pid: 2149,
            district: "黎安镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2153,
            pid: 2149,
            district: "文罗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2154,
            pid: 2149,
            district: "提蒙乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2155,
            pid: 2149,
            district: "群英乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2156,
            pid: 2149,
            district: "三才镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2157,
            pid: 2149,
            district: "隆广镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2158,
            pid: 2149,
            district: "英州镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2159,
            pid: 2149,
            district: "光坡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2160,
            pid: 2149,
            district: "本号镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2161,
        pid: 2023,
        district: "儋州市",
        level: 2,
        childrens: [
          {
            id: 2162,
            pid: 2161,
            district: "光村镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2163,
            pid: 2161,
            district: "兰洋镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2164,
            pid: 2161,
            district: "海头镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2165,
            pid: 2161,
            district: "中和镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2166,
            pid: 2161,
            district: "新州镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2167,
            pid: 2161,
            district: "木棠镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2168,
            pid: 2161,
            district: "王五镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2169,
            pid: 2161,
            district: "南丰镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2170,
            pid: 2161,
            district: "排浦镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2171,
            pid: 2161,
            district: "峨蔓镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2172,
            pid: 2161,
            district: "白马井镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2173,
            pid: 2161,
            district: "东成镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2174,
            pid: 2161,
            district: "大成镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2175,
            pid: 2161,
            district: "雅星镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2176,
            pid: 2161,
            district: "那大镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2177,
            pid: 2161,
            district: "和庆镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2178,
            pid: 2161,
            district: "三都镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2179,
        pid: 2023,
        district: "三沙市",
        level: 2,
        childrens: [
          {
            id: 2180,
            pid: 2179,
            district: "西沙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2181,
            pid: 2179,
            district: "南沙区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2182,
        pid: 2023,
        district: "三亚市",
        level: 2,
        childrens: [
          {
            id: 2183,
            pid: 2182,
            district: "崖州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2184,
            pid: 2182,
            district: "海棠区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2185,
            pid: 2182,
            district: "吉阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2186,
            pid: 2182,
            district: "天涯区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2187,
        pid: 2023,
        district: "文昌市",
        level: 2,
        childrens: [
          {
            id: 2188,
            pid: 2187,
            district: "文教镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2189,
            pid: 2187,
            district: "会文镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2190,
            pid: 2187,
            district: "铺前镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2191,
            pid: 2187,
            district: "冯坡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2192,
            pid: 2187,
            district: "东郊镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2193,
            pid: 2187,
            district: "龙楼镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2194,
            pid: 2187,
            district: "翁田镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2195,
            pid: 2187,
            district: "昌洒镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2196,
            pid: 2187,
            district: "公坡镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2197,
            pid: 2187,
            district: "潭牛镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2198,
            pid: 2187,
            district: "文城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2199,
            pid: 2187,
            district: "锦山镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2200,
            pid: 2187,
            district: "抱罗镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2201,
            pid: 2187,
            district: "东阁镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2202,
            pid: 2187,
            district: "东路镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2203,
            pid: 2187,
            district: "蓬莱镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2204,
            pid: 2187,
            district: "重兴镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2205,
        pid: 2023,
        district: "海口市",
        level: 2,
        childrens: [
          {
            id: 2206,
            pid: 2205,
            district: "龙华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2207,
            pid: 2205,
            district: "美兰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2208,
            pid: 2205,
            district: "琼山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2209,
            pid: 2205,
            district: "秀英区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2210,
        pid: 2023,
        district: "保亭黎族苗族自治县",
        level: 2,
        childrens: [
          {
            id: 2211,
            pid: 2210,
            district: "南林乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2212,
            pid: 2210,
            district: "毛感乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2213,
            pid: 2210,
            district: "什玲镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2214,
            pid: 2210,
            district: "新政镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2215,
            pid: 2210,
            district: "保城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2216,
            pid: 2210,
            district: "加茂镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2217,
            pid: 2210,
            district: "六弓乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2218,
            pid: 2210,
            district: "三道镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2219,
            pid: 2210,
            district: "响水镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2220,
        pid: 2023,
        district: "五指山市",
        level: 2,
        childrens: [
          {
            id: 2221,
            pid: 2220,
            district: "南圣镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2222,
            pid: 2220,
            district: "毛阳镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2223,
            pid: 2220,
            district: "水满乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2224,
            pid: 2220,
            district: "番阳镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2225,
            pid: 2220,
            district: "畅好乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2226,
            pid: 2220,
            district: "毛道乡",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2227,
            pid: 2220,
            district: "通什镇",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2228,
    pid: 1,
    district: "江苏省",
    level: 1,
    childrens: [
      {
        id: 2229,
        pid: 2228,
        district: "连云港市",
        level: 2,
        childrens: [
          {
            id: 2230,
            pid: 2229,
            district: "东海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2231,
            pid: 2229,
            district: "灌南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2232,
            pid: 2229,
            district: "赣榆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2233,
            pid: 2229,
            district: "连云区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2234,
            pid: 2229,
            district: "灌云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2235,
            pid: 2229,
            district: "海州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2236,
        pid: 2228,
        district: "南通市",
        level: 2,
        childrens: [
          {
            id: 2237,
            pid: 2236,
            district: "海安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2238,
            pid: 2236,
            district: "如东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2239,
            pid: 2236,
            district: "崇川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2240,
            pid: 2236,
            district: "如皋市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2241,
            pid: 2236,
            district: "通州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2242,
            pid: 2236,
            district: "海门区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2243,
            pid: 2236,
            district: "启东市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2244,
        pid: 2228,
        district: "宿迁市",
        level: 2,
        childrens: [
          {
            id: 2245,
            pid: 2244,
            district: "泗阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2246,
            pid: 2244,
            district: "泗洪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2247,
            pid: 2244,
            district: "宿城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2248,
            pid: 2244,
            district: "宿豫区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2249,
            pid: 2244,
            district: "沭阳县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2250,
        pid: 2228,
        district: "泰州市",
        level: 2,
        childrens: [
          {
            id: 2251,
            pid: 2250,
            district: "海陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2252,
            pid: 2250,
            district: "靖江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2253,
            pid: 2250,
            district: "泰兴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2254,
            pid: 2250,
            district: "高港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2255,
            pid: 2250,
            district: "兴化市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2256,
            pid: 2250,
            district: "姜堰区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2257,
        pid: 2228,
        district: "南京市",
        level: 2,
        childrens: [
          {
            id: 2258,
            pid: 2257,
            district: "雨花台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2259,
            pid: 2257,
            district: "江宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2260,
            pid: 2257,
            district: "高淳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2261,
            pid: 2257,
            district: "溧水区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2262,
            pid: 2257,
            district: "六合区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2263,
            pid: 2257,
            district: "建邺区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2264,
            pid: 2257,
            district: "秦淮区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2265,
            pid: 2257,
            district: "鼓楼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2266,
            pid: 2257,
            district: "玄武区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2267,
            pid: 2257,
            district: "浦口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2268,
            pid: 2257,
            district: "栖霞区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2269,
        pid: 2228,
        district: "苏州市",
        level: 2,
        childrens: [
          {
            id: 2270,
            pid: 2269,
            district: "太仓市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2271,
            pid: 2269,
            district: "常熟市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2272,
            pid: 2269,
            district: "相城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2273,
            pid: 2269,
            district: "昆山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2274,
            pid: 2269,
            district: "张家港市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2275,
            pid: 2269,
            district: "姑苏区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2276,
            pid: 2269,
            district: "虎丘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2277,
            pid: 2269,
            district: "吴江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2278,
            pid: 2269,
            district: "吴中区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2279,
        pid: 2228,
        district: "扬州市",
        level: 2,
        childrens: [
          {
            id: 2280,
            pid: 2279,
            district: "宝应县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2281,
            pid: 2279,
            district: "仪征市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2282,
            pid: 2279,
            district: "邗江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2283,
            pid: 2279,
            district: "广陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2284,
            pid: 2279,
            district: "高邮市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2285,
            pid: 2279,
            district: "江都区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2286,
        pid: 2228,
        district: "镇江市",
        level: 2,
        childrens: [
          {
            id: 2287,
            pid: 2286,
            district: "扬中市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2288,
            pid: 2286,
            district: "润州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2289,
            pid: 2286,
            district: "丹徒区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2290,
            pid: 2286,
            district: "丹阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2291,
            pid: 2286,
            district: "句容市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2292,
            pid: 2286,
            district: "京口区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2293,
        pid: 2228,
        district: "盐城市",
        level: 2,
        childrens: [
          {
            id: 2294,
            pid: 2293,
            district: "滨海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2295,
            pid: 2293,
            district: "射阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2296,
            pid: 2293,
            district: "建湖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2297,
            pid: 2293,
            district: "盐都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2298,
            pid: 2293,
            district: "东台市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2299,
            pid: 2293,
            district: "响水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2300,
            pid: 2293,
            district: "亭湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2301,
            pid: 2293,
            district: "大丰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2302,
            pid: 2293,
            district: "阜宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2303,
        pid: 2228,
        district: "淮安市",
        level: 2,
        childrens: [
          {
            id: 2304,
            pid: 2303,
            district: "洪泽区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2305,
            pid: 2303,
            district: "淮阴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2306,
            pid: 2303,
            district: "金湖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2307,
            pid: 2303,
            district: "盱眙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2308,
            pid: 2303,
            district: "涟水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2309,
            pid: 2303,
            district: "清江浦区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2310,
            pid: 2303,
            district: "淮安区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2311,
        pid: 2228,
        district: "徐州市",
        level: 2,
        childrens: [
          {
            id: 2312,
            pid: 2311,
            district: "泉山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2313,
            pid: 2311,
            district: "铜山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2314,
            pid: 2311,
            district: "鼓楼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2315,
            pid: 2311,
            district: "新沂市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2316,
            pid: 2311,
            district: "睢宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2317,
            pid: 2311,
            district: "贾汪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2318,
            pid: 2311,
            district: "云龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2319,
            pid: 2311,
            district: "沛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2320,
            pid: 2311,
            district: "邳州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2321,
            pid: 2311,
            district: "丰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2322,
        pid: 2228,
        district: "常州市",
        level: 2,
        childrens: [
          {
            id: 2323,
            pid: 2322,
            district: "溧阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2324,
            pid: 2322,
            district: "新北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2325,
            pid: 2322,
            district: "钟楼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2326,
            pid: 2322,
            district: "金坛区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2327,
            pid: 2322,
            district: "天宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2328,
            pid: 2322,
            district: "武进区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2329,
        pid: 2228,
        district: "无锡市",
        level: 2,
        childrens: [
          {
            id: 2330,
            pid: 2329,
            district: "新吴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2331,
            pid: 2329,
            district: "锡山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2332,
            pid: 2329,
            district: "惠山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2333,
            pid: 2329,
            district: "江阴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2334,
            pid: 2329,
            district: "宜兴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2335,
            pid: 2329,
            district: "梁溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2336,
            pid: 2329,
            district: "滨湖区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2337,
    pid: 1,
    district: "青海省",
    level: 1,
    childrens: [
      {
        id: 2338,
        pid: 2337,
        district: "海东市",
        level: 2,
        childrens: [
          {
            id: 2339,
            pid: 2338,
            district: "民和回族土族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2340,
            pid: 2338,
            district: "循化撒拉族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2341,
            pid: 2338,
            district: "乐都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2342,
            pid: 2338,
            district: "平安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2343,
            pid: 2338,
            district: "互助土族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2344,
            pid: 2338,
            district: "化隆回族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2345,
        pid: 2337,
        district: "海南藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2346,
            pid: 2345,
            district: "同德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2347,
            pid: 2345,
            district: "贵南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2348,
            pid: 2345,
            district: "贵德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2349,
            pid: 2345,
            district: "共和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2350,
            pid: 2345,
            district: "兴海县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2351,
        pid: 2337,
        district: "果洛藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2352,
            pid: 2351,
            district: "玛沁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2353,
            pid: 2351,
            district: "达日县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2354,
            pid: 2351,
            district: "甘德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2355,
            pid: 2351,
            district: "久治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2356,
            pid: 2351,
            district: "班玛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2357,
            pid: 2351,
            district: "玛多县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2358,
        pid: 2337,
        district: "玉树藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2359,
            pid: 2358,
            district: "曲麻莱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2360,
            pid: 2358,
            district: "治多县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2361,
            pid: 2358,
            district: "玉树市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2362,
            pid: 2358,
            district: "囊谦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2363,
            pid: 2358,
            district: "杂多县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2364,
            pid: 2358,
            district: "称多县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2365,
        pid: 2337,
        district: "黄南藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2366,
            pid: 2365,
            district: "同仁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2367,
            pid: 2365,
            district: "泽库县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2368,
            pid: 2365,
            district: "河南蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2369,
            pid: 2365,
            district: "尖扎县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2370,
        pid: 2337,
        district: "海西蒙古族藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2371,
            pid: 2370,
            district: "都兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2372,
            pid: 2370,
            district: "格尔木市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2373,
            pid: 2370,
            district: "德令哈市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2374,
            pid: 2370,
            district: "天峻县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2375,
            pid: 2370,
            district: "乌兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2376,
            pid: 2370,
            district: "海西蒙古族藏族自治州直辖",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2377,
            pid: 2370,
            district: "茫崖市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2378,
        pid: 2337,
        district: "西宁市",
        level: 2,
        childrens: [
          {
            id: 2379,
            pid: 2378,
            district: "湟源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2380,
            pid: 2378,
            district: "城东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2381,
            pid: 2378,
            district: "大通回族土族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2382,
            pid: 2378,
            district: "城西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2383,
            pid: 2378,
            district: "湟中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2384,
            pid: 2378,
            district: "城中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2385,
            pid: 2378,
            district: "城北区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2386,
        pid: 2337,
        district: "海北藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2387,
            pid: 2386,
            district: "海晏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2388,
            pid: 2386,
            district: "门源回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2389,
            pid: 2386,
            district: "刚察县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2390,
            pid: 2386,
            district: "祁连县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2391,
    pid: 1,
    district: "广西壮族自治区",
    level: 1,
    childrens: [
      {
        id: 2392,
        pid: 2391,
        district: "钦州市",
        level: 2,
        childrens: [
          {
            id: 2393,
            pid: 2392,
            district: "钦南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2394,
            pid: 2392,
            district: "钦北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2395,
            pid: 2392,
            district: "灵山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2396,
            pid: 2392,
            district: "浦北县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2397,
        pid: 2391,
        district: "柳州市",
        level: 2,
        childrens: [
          {
            id: 2398,
            pid: 2397,
            district: "三江侗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2399,
            pid: 2397,
            district: "融水苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2400,
            pid: 2397,
            district: "融安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2401,
            pid: 2397,
            district: "鹿寨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2402,
            pid: 2397,
            district: "城中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2403,
            pid: 2397,
            district: "柳北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2404,
            pid: 2397,
            district: "柳城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2405,
            pid: 2397,
            district: "鱼峰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2406,
            pid: 2397,
            district: "柳南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2407,
            pid: 2397,
            district: "柳江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2408,
        pid: 2391,
        district: "百色市",
        level: 2,
        childrens: [
          {
            id: 2409,
            pid: 2408,
            district: "乐业县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2410,
            pid: 2408,
            district: "隆林各族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2411,
            pid: 2408,
            district: "凌云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2412,
            pid: 2408,
            district: "西林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2413,
            pid: 2408,
            district: "平果市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2414,
            pid: 2408,
            district: "右江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2415,
            pid: 2408,
            district: "靖西市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2416,
            pid: 2408,
            district: "那坡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2417,
            pid: 2408,
            district: "田林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2418,
            pid: 2408,
            district: "田东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2419,
            pid: 2408,
            district: "田阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2420,
            pid: 2408,
            district: "德保县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2421,
        pid: 2391,
        district: "北海市",
        level: 2,
        childrens: [
          {
            id: 2422,
            pid: 2421,
            district: "铁山港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2423,
            pid: 2421,
            district: "银海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2424,
            pid: 2421,
            district: "海城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2425,
            pid: 2421,
            district: "合浦县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2426,
        pid: 2391,
        district: "梧州市",
        level: 2,
        childrens: [
          {
            id: 2427,
            pid: 2426,
            district: "蒙山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2428,
            pid: 2426,
            district: "龙圩区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2429,
            pid: 2426,
            district: "长洲区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2430,
            pid: 2426,
            district: "岑溪市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2431,
            pid: 2426,
            district: "万秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2432,
            pid: 2426,
            district: "苍梧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2433,
            pid: 2426,
            district: "藤县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2434,
        pid: 2391,
        district: "桂林市",
        level: 2,
        childrens: [
          {
            id: 2435,
            pid: 2434,
            district: "雁山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2436,
            pid: 2434,
            district: "七星区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2437,
            pid: 2434,
            district: "阳朔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2438,
            pid: 2434,
            district: "荔浦市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2439,
            pid: 2434,
            district: "龙胜各族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2440,
            pid: 2434,
            district: "灌阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2441,
            pid: 2434,
            district: "秀峰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2442,
            pid: 2434,
            district: "象山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2443,
            pid: 2434,
            district: "灵川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2444,
            pid: 2434,
            district: "叠彩区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2445,
            pid: 2434,
            district: "资源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2446,
            pid: 2434,
            district: "恭城瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2447,
            pid: 2434,
            district: "平乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2448,
            pid: 2434,
            district: "全州县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2449,
            pid: 2434,
            district: "兴安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2450,
            pid: 2434,
            district: "永福县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2451,
            pid: 2434,
            district: "临桂区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2452,
        pid: 2391,
        district: "贺州市",
        level: 2,
        childrens: [
          {
            id: 2453,
            pid: 2452,
            district: "富川瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2454,
            pid: 2452,
            district: "昭平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2455,
            pid: 2452,
            district: "平桂区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2456,
            pid: 2452,
            district: "钟山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2457,
            pid: 2452,
            district: "八步区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2458,
        pid: 2391,
        district: "来宾市",
        level: 2,
        childrens: [
          {
            id: 2459,
            pid: 2458,
            district: "金秀瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2460,
            pid: 2458,
            district: "兴宾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2461,
            pid: 2458,
            district: "象州县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2462,
            pid: 2458,
            district: "合山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2463,
            pid: 2458,
            district: "忻城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2464,
            pid: 2458,
            district: "武宣县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2465,
        pid: 2391,
        district: "河池市",
        level: 2,
        childrens: [
          {
            id: 2466,
            pid: 2465,
            district: "南丹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2467,
            pid: 2465,
            district: "天峨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2468,
            pid: 2465,
            district: "环江毛南族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2469,
            pid: 2465,
            district: "罗城仫佬族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2470,
            pid: 2465,
            district: "凤山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2471,
            pid: 2465,
            district: "都安瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2472,
            pid: 2465,
            district: "宜州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2473,
            pid: 2465,
            district: "金城江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2474,
            pid: 2465,
            district: "大化瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2475,
            pid: 2465,
            district: "东兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2476,
            pid: 2465,
            district: "巴马瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2477,
        pid: 2391,
        district: "玉林市",
        level: 2,
        childrens: [
          {
            id: 2478,
            pid: 2477,
            district: "福绵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2479,
            pid: 2477,
            district: "博白县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2480,
            pid: 2477,
            district: "陆川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2481,
            pid: 2477,
            district: "玉州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2482,
            pid: 2477,
            district: "兴业县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2483,
            pid: 2477,
            district: "北流市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2484,
            pid: 2477,
            district: "容县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2485,
        pid: 2391,
        district: "南宁市",
        level: 2,
        childrens: [
          {
            id: 2486,
            pid: 2485,
            district: "马山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2487,
            pid: 2485,
            district: "上林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2488,
            pid: 2485,
            district: "宾阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2489,
            pid: 2485,
            district: "武鸣区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2490,
            pid: 2485,
            district: "良庆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2491,
            pid: 2485,
            district: "江南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2492,
            pid: 2485,
            district: "邕宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2493,
            pid: 2485,
            district: "兴宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2494,
            pid: 2485,
            district: "青秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2495,
            pid: 2485,
            district: "西乡塘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2496,
            pid: 2485,
            district: "隆安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2497,
            pid: 2485,
            district: "横州市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2498,
        pid: 2391,
        district: "贵港市",
        level: 2,
        childrens: [
          {
            id: 2499,
            pid: 2498,
            district: "港南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2500,
            pid: 2498,
            district: "港北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2501,
            pid: 2498,
            district: "平南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2502,
            pid: 2498,
            district: "桂平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2503,
            pid: 2498,
            district: "覃塘区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2504,
        pid: 2391,
        district: "崇左市",
        level: 2,
        childrens: [
          {
            id: 2505,
            pid: 2504,
            district: "扶绥县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2506,
            pid: 2504,
            district: "江州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2507,
            pid: 2504,
            district: "凭祥市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2508,
            pid: 2504,
            district: "龙州县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2509,
            pid: 2504,
            district: "宁明县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2510,
            pid: 2504,
            district: "大新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2511,
            pid: 2504,
            district: "天等县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2512,
        pid: 2391,
        district: "防城港市",
        level: 2,
        childrens: [
          {
            id: 2513,
            pid: 2512,
            district: "港口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2514,
            pid: 2512,
            district: "东兴市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2515,
            pid: 2512,
            district: "上思县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2516,
            pid: 2512,
            district: "防城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2517,
    pid: 1,
    district: "宁夏回族自治区",
    level: 1,
    childrens: [
      {
        id: 2518,
        pid: 2517,
        district: "固原市",
        level: 2,
        childrens: [
          {
            id: 2519,
            pid: 2518,
            district: "隆德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2520,
            pid: 2518,
            district: "彭阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2521,
            pid: 2518,
            district: "泾源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2522,
            pid: 2518,
            district: "西吉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2523,
            pid: 2518,
            district: "原州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2524,
        pid: 2517,
        district: "中卫市",
        level: 2,
        childrens: [
          {
            id: 2525,
            pid: 2524,
            district: "海原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2526,
            pid: 2524,
            district: "沙坡头区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2527,
            pid: 2524,
            district: "中宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2528,
        pid: 2517,
        district: "石嘴山市",
        level: 2,
        childrens: [
          {
            id: 2529,
            pid: 2528,
            district: "惠农区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2530,
            pid: 2528,
            district: "大武口区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2531,
            pid: 2528,
            district: "平罗县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2532,
        pid: 2517,
        district: "吴忠市",
        level: 2,
        childrens: [
          {
            id: 2533,
            pid: 2532,
            district: "青铜峡市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2534,
            pid: 2532,
            district: "同心县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2535,
            pid: 2532,
            district: "盐池县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2536,
            pid: 2532,
            district: "利通区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2537,
            pid: 2532,
            district: "红寺堡区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2538,
        pid: 2517,
        district: "银川市",
        level: 2,
        childrens: [
          {
            id: 2539,
            pid: 2538,
            district: "西夏区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2540,
            pid: 2538,
            district: "永宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2541,
            pid: 2538,
            district: "兴庆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2542,
            pid: 2538,
            district: "灵武市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2543,
            pid: 2538,
            district: "贺兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2544,
            pid: 2538,
            district: "金凤区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2545,
    pid: 1,
    district: "浙江省",
    level: 1,
    childrens: [
      {
        id: 2546,
        pid: 2545,
        district: "温州市",
        level: 2,
        childrens: [
          {
            id: 2547,
            pid: 2546,
            district: "洞头区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2548,
            pid: 2546,
            district: "平阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2549,
            pid: 2546,
            district: "苍南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2550,
            pid: 2546,
            district: "瑞安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2551,
            pid: 2546,
            district: "文成县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2552,
            pid: 2546,
            district: "龙港市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2553,
            pid: 2546,
            district: "泰顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2554,
            pid: 2546,
            district: "永嘉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2555,
            pid: 2546,
            district: "乐清市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2556,
            pid: 2546,
            district: "龙湾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2557,
            pid: 2546,
            district: "鹿城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2558,
            pid: 2546,
            district: "瓯海区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2559,
        pid: 2545,
        district: "宁波市",
        level: 2,
        childrens: [
          {
            id: 2560,
            pid: 2559,
            district: "象山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2561,
            pid: 2559,
            district: "北仑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2562,
            pid: 2559,
            district: "镇海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2563,
            pid: 2559,
            district: "江北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2564,
            pid: 2559,
            district: "宁海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2565,
            pid: 2559,
            district: "海曙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2566,
            pid: 2559,
            district: "鄞州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2567,
            pid: 2559,
            district: "奉化区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2568,
            pid: 2559,
            district: "慈溪市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2569,
            pid: 2559,
            district: "余姚市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2570,
        pid: 2545,
        district: "舟山市",
        level: 2,
        childrens: [
          {
            id: 2571,
            pid: 2570,
            district: "岱山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2572,
            pid: 2570,
            district: "嵊泗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2573,
            pid: 2570,
            district: "普陀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2574,
            pid: 2570,
            district: "定海区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2575,
        pid: 2545,
        district: "台州市",
        level: 2,
        childrens: [
          {
            id: 2576,
            pid: 2575,
            district: "椒江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2577,
            pid: 2575,
            district: "路桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2578,
            pid: 2575,
            district: "临海市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2579,
            pid: 2575,
            district: "玉环市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2580,
            pid: 2575,
            district: "温岭市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2581,
            pid: 2575,
            district: "三门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2582,
            pid: 2575,
            district: "天台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2583,
            pid: 2575,
            district: "仙居县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2584,
            pid: 2575,
            district: "黄岩区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2585,
        pid: 2545,
        district: "嘉兴市",
        level: 2,
        childrens: [
          {
            id: 2586,
            pid: 2585,
            district: "平湖市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2587,
            pid: 2585,
            district: "嘉善县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2588,
            pid: 2585,
            district: "桐乡市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2589,
            pid: 2585,
            district: "海盐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2590,
            pid: 2585,
            district: "海宁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2591,
            pid: 2585,
            district: "南湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2592,
            pid: 2585,
            district: "秀洲区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2593,
        pid: 2545,
        district: "丽水市",
        level: 2,
        childrens: [
          {
            id: 2594,
            pid: 2593,
            district: "松阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2595,
            pid: 2593,
            district: "龙泉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2596,
            pid: 2593,
            district: "云和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2597,
            pid: 2593,
            district: "景宁畲族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2598,
            pid: 2593,
            district: "遂昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2599,
            pid: 2593,
            district: "庆元县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2600,
            pid: 2593,
            district: "缙云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2601,
            pid: 2593,
            district: "莲都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2602,
            pid: 2593,
            district: "青田县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2603,
        pid: 2545,
        district: "金华市",
        level: 2,
        childrens: [
          {
            id: 2604,
            pid: 2603,
            district: "永康市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2605,
            pid: 2603,
            district: "武义县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2606,
            pid: 2603,
            district: "磐安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2607,
            pid: 2603,
            district: "金东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2608,
            pid: 2603,
            district: "婺城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2609,
            pid: 2603,
            district: "浦江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2610,
            pid: 2603,
            district: "兰溪市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2611,
            pid: 2603,
            district: "义乌市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2612,
            pid: 2603,
            district: "东阳市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2613,
        pid: 2545,
        district: "衢州市",
        level: 2,
        childrens: [
          {
            id: 2614,
            pid: 2613,
            district: "柯城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2615,
            pid: 2613,
            district: "江山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2616,
            pid: 2613,
            district: "常山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2617,
            pid: 2613,
            district: "开化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2618,
            pid: 2613,
            district: "龙游县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2619,
            pid: 2613,
            district: "衢江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2620,
        pid: 2545,
        district: "绍兴市",
        level: 2,
        childrens: [
          {
            id: 2621,
            pid: 2620,
            district: "嵊州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2622,
            pid: 2620,
            district: "新昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2623,
            pid: 2620,
            district: "诸暨市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2624,
            pid: 2620,
            district: "上虞区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2625,
            pid: 2620,
            district: "越城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2626,
            pid: 2620,
            district: "柯桥区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2627,
        pid: 2545,
        district: "杭州市",
        level: 2,
        childrens: [
          {
            id: 2628,
            pid: 2627,
            district: "淳安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2629,
            pid: 2627,
            district: "滨江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2630,
            pid: 2627,
            district: "建德市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2631,
            pid: 2627,
            district: "萧山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2632,
            pid: 2627,
            district: "临安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2633,
            pid: 2627,
            district: "富阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2634,
            pid: 2627,
            district: "桐庐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2635,
            pid: 2627,
            district: "西湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2636,
            pid: 2627,
            district: "钱塘区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2637,
            pid: 2627,
            district: "上城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2638,
            pid: 2627,
            district: "拱墅区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2639,
            pid: 2627,
            district: "临平区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2640,
            pid: 2627,
            district: "余杭区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2641,
        pid: 2545,
        district: "湖州市",
        level: 2,
        childrens: [
          {
            id: 2642,
            pid: 2641,
            district: "长兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2643,
            pid: 2641,
            district: "南浔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2644,
            pid: 2641,
            district: "安吉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2645,
            pid: 2641,
            district: "吴兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2646,
            pid: 2641,
            district: "德清县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2647,
    pid: 1,
    district: "河北省",
    level: 1,
    childrens: [
      {
        id: 2648,
        pid: 2647,
        district: "唐山市",
        level: 2,
        childrens: [
          {
            id: 2649,
            pid: 2648,
            district: "迁西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2650,
            pid: 2648,
            district: "遵化市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2651,
            pid: 2648,
            district: "迁安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2652,
            pid: 2648,
            district: "滦州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2653,
            pid: 2648,
            district: "曹妃甸区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2654,
            pid: 2648,
            district: "滦南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2655,
            pid: 2648,
            district: "玉田县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2656,
            pid: 2648,
            district: "乐亭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2657,
            pid: 2648,
            district: "路南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2658,
            pid: 2648,
            district: "古冶区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2659,
            pid: 2648,
            district: "丰南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2660,
            pid: 2648,
            district: "路北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2661,
            pid: 2648,
            district: "丰润区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2662,
            pid: 2648,
            district: "开平区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2663,
        pid: 2647,
        district: "廊坊市",
        level: 2,
        childrens: [
          {
            id: 2664,
            pid: 2663,
            district: "大厂回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2665,
            pid: 2663,
            district: "大城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2666,
            pid: 2663,
            district: "永清县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2667,
            pid: 2663,
            district: "固安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2668,
            pid: 2663,
            district: "香河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2669,
            pid: 2663,
            district: "文安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2670,
            pid: 2663,
            district: "三河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2671,
            pid: 2663,
            district: "广阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2672,
            pid: 2663,
            district: "安次区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2673,
            pid: 2663,
            district: "霸州市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2674,
        pid: 2647,
        district: "秦皇岛市",
        level: 2,
        childrens: [
          {
            id: 2675,
            pid: 2674,
            district: "青龙满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2676,
            pid: 2674,
            district: "山海关区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2677,
            pid: 2674,
            district: "卢龙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2678,
            pid: 2674,
            district: "海港区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2679,
            pid: 2674,
            district: "抚宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2680,
            pid: 2674,
            district: "昌黎县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2681,
            pid: 2674,
            district: "北戴河区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2682,
        pid: 2647,
        district: "邯郸市",
        level: 2,
        childrens: [
          {
            id: 2683,
            pid: 2682,
            district: "肥乡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2684,
            pid: 2682,
            district: "涉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2685,
            pid: 2682,
            district: "鸡泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2686,
            pid: 2682,
            district: "曲周县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2687,
            pid: 2682,
            district: "复兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2688,
            pid: 2682,
            district: "丛台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2689,
            pid: 2682,
            district: "磁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2690,
            pid: 2682,
            district: "永年区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2691,
            pid: 2682,
            district: "邯山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2692,
            pid: 2682,
            district: "馆陶县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2693,
            pid: 2682,
            district: "邱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2694,
            pid: 2682,
            district: "广平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2695,
            pid: 2682,
            district: "大名县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2696,
            pid: 2682,
            district: "峰峰矿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2697,
            pid: 2682,
            district: "武安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2698,
            pid: 2682,
            district: "临漳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2699,
            pid: 2682,
            district: "成安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2700,
            pid: 2682,
            district: "魏县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2701,
        pid: 2647,
        district: "沧州市",
        level: 2,
        childrens: [
          {
            id: 2702,
            pid: 2701,
            district: "任丘市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2703,
            pid: 2701,
            district: "献县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2704,
            pid: 2701,
            district: "海兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2705,
            pid: 2701,
            district: "南皮县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2706,
            pid: 2701,
            district: "东光县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2707,
            pid: 2701,
            district: "运河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2708,
            pid: 2701,
            district: "吴桥县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2709,
            pid: 2701,
            district: "黄骅市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2710,
            pid: 2701,
            district: "青县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2711,
            pid: 2701,
            district: "肃宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2712,
            pid: 2701,
            district: "河间市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2713,
            pid: 2701,
            district: "盐山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2714,
            pid: 2701,
            district: "孟村回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2715,
            pid: 2701,
            district: "沧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2716,
            pid: 2701,
            district: "新华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2717,
            pid: 2701,
            district: "泊头市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2718,
        pid: 2647,
        district: "衡水市",
        level: 2,
        childrens: [
          {
            id: 2719,
            pid: 2718,
            district: "桃城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2720,
            pid: 2718,
            district: "深州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2721,
            pid: 2718,
            district: "饶阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2722,
            pid: 2718,
            district: "安平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2723,
            pid: 2718,
            district: "阜城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2724,
            pid: 2718,
            district: "冀州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2725,
            pid: 2718,
            district: "景县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2726,
            pid: 2718,
            district: "故城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2727,
            pid: 2718,
            district: "武邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2728,
            pid: 2718,
            district: "武强县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2729,
            pid: 2718,
            district: "枣强县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2730,
        pid: 2647,
        district: "邢台市",
        level: 2,
        childrens: [
          {
            id: 2731,
            pid: 2730,
            district: "新河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2732,
            pid: 2730,
            district: "柏乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2733,
            pid: 2730,
            district: "清河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2734,
            pid: 2730,
            district: "内丘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2735,
            pid: 2730,
            district: "南宫市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2736,
            pid: 2730,
            district: "广宗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2737,
            pid: 2730,
            district: "临城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2738,
            pid: 2730,
            district: "南和区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2739,
            pid: 2730,
            district: "平乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2740,
            pid: 2730,
            district: "沙河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2741,
            pid: 2730,
            district: "宁晋县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2742,
            pid: 2730,
            district: "任泽区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2743,
            pid: 2730,
            district: "巨鹿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2744,
            pid: 2730,
            district: "隆尧县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2745,
            pid: 2730,
            district: "襄都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2746,
            pid: 2730,
            district: "信都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2747,
            pid: 2730,
            district: "威县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2748,
            pid: 2730,
            district: "临西县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2749,
        pid: 2647,
        district: "张家口市",
        level: 2,
        childrens: [
          {
            id: 2750,
            pid: 2749,
            district: "沽源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2751,
            pid: 2749,
            district: "康保县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2752,
            pid: 2749,
            district: "下花园区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2753,
            pid: 2749,
            district: "万全区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2754,
            pid: 2749,
            district: "崇礼区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2755,
            pid: 2749,
            district: "张北县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2756,
            pid: 2749,
            district: "尚义县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2757,
            pid: 2749,
            district: "赤城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2758,
            pid: 2749,
            district: "涿鹿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2759,
            pid: 2749,
            district: "怀来县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2760,
            pid: 2749,
            district: "桥东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2761,
            pid: 2749,
            district: "桥西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2762,
            pid: 2749,
            district: "宣化区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2763,
            pid: 2749,
            district: "阳原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2764,
            pid: 2749,
            district: "蔚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2765,
            pid: 2749,
            district: "怀安县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2766,
        pid: 2647,
        district: "保定市",
        level: 2,
        childrens: [
          {
            id: 2767,
            pid: 2766,
            district: "涞源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2768,
            pid: 2766,
            district: "易县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2769,
            pid: 2766,
            district: "徐水区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2770,
            pid: 2766,
            district: "安新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2771,
            pid: 2766,
            district: "高阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2772,
            pid: 2766,
            district: "顺平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2773,
            pid: 2766,
            district: "竞秀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2774,
            pid: 2766,
            district: "定州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2775,
            pid: 2766,
            district: "安国市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2776,
            pid: 2766,
            district: "莲池区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2777,
            pid: 2766,
            district: "涞水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2778,
            pid: 2766,
            district: "涿州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2779,
            pid: 2766,
            district: "阜平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2780,
            pid: 2766,
            district: "望都县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2781,
            pid: 2766,
            district: "唐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2782,
            pid: 2766,
            district: "高碑店市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2783,
            pid: 2766,
            district: "定兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2784,
            pid: 2766,
            district: "容城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2785,
            pid: 2766,
            district: "雄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2786,
            pid: 2766,
            district: "曲阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2787,
            pid: 2766,
            district: "博野县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2788,
            pid: 2766,
            district: "蠡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2789,
            pid: 2766,
            district: "清苑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2790,
            pid: 2766,
            district: "满城区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2791,
        pid: 2647,
        district: "石家庄市",
        level: 2,
        childrens: [
          {
            id: 2792,
            pid: 2791,
            district: "灵寿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2793,
            pid: 2791,
            district: "无极县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2794,
            pid: 2791,
            district: "藁城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2795,
            pid: 2791,
            district: "晋州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2796,
            pid: 2791,
            district: "元氏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2797,
            pid: 2791,
            district: "正定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2798,
            pid: 2791,
            district: "赞皇县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2799,
            pid: 2791,
            district: "高邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2800,
            pid: 2791,
            district: "新乐市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2801,
            pid: 2791,
            district: "辛集市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2802,
            pid: 2791,
            district: "井陉矿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2803,
            pid: 2791,
            district: "井陉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2804,
            pid: 2791,
            district: "深泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2805,
            pid: 2791,
            district: "赵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2806,
            pid: 2791,
            district: "栾城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2807,
            pid: 2791,
            district: "行唐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2808,
            pid: 2791,
            district: "裕华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2809,
            pid: 2791,
            district: "长安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2810,
            pid: 2791,
            district: "新华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2811,
            pid: 2791,
            district: "桥西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2812,
            pid: 2791,
            district: "平山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2813,
            pid: 2791,
            district: "鹿泉区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2814,
        pid: 2647,
        district: "承德市",
        level: 2,
        childrens: [
          {
            id: 2815,
            pid: 2814,
            district: "围场满族蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2816,
            pid: 2814,
            district: "丰宁满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2817,
            pid: 2814,
            district: "隆化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2818,
            pid: 2814,
            district: "鹰手营子矿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2819,
            pid: 2814,
            district: "宽城满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2820,
            pid: 2814,
            district: "兴隆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2821,
            pid: 2814,
            district: "平泉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2822,
            pid: 2814,
            district: "双滦区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2823,
            pid: 2814,
            district: "滦平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2824,
            pid: 2814,
            district: "双桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2825,
            pid: 2814,
            district: "承德县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2826,
    pid: 1,
    district: "香港特别行政区",
    level: 1,
    childrens: [
      {
        id: 2827,
        pid: 2826,
        district: "沙田区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2828,
        pid: 2826,
        district: "大埔区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2829,
        pid: 2826,
        district: "北区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2830,
        pid: 2826,
        district: "西贡区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2831,
        pid: 2826,
        district: "屯门区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2832,
        pid: 2826,
        district: "九龙城区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2833,
        pid: 2826,
        district: "东区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2834,
        pid: 2826,
        district: "油尖旺区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2835,
        pid: 2826,
        district: "离岛区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2836,
        pid: 2826,
        district: "黄大仙区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2837,
        pid: 2826,
        district: "观塘区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2838,
        pid: 2826,
        district: "深水埗区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2839,
        pid: 2826,
        district: "湾仔区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2840,
        pid: 2826,
        district: "中西区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2841,
        pid: 2826,
        district: "元朗区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2842,
        pid: 2826,
        district: "南区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2843,
        pid: 2826,
        district: "葵青区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2844,
        pid: 2826,
        district: "荃湾区",
        level: 2,
        childrens: null,
        root: false
      }
    ],
    root: false
  },
  {
    id: 2845,
    pid: 1,
    district: "台湾省",
    level: 1,
    childrens: null,
    root: false
  },
  {
    id: 2846,
    pid: 1,
    district: "澳门特别行政区",
    level: 1,
    childrens: [
      {
        id: 2847,
        pid: 2846,
        district: "花地玛堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2848,
        pid: 2846,
        district: "路凼填海区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2849,
        pid: 2846,
        district: "嘉模堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2850,
        pid: 2846,
        district: "望德堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2851,
        pid: 2846,
        district: "圣方济各堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2852,
        pid: 2846,
        district: "风顺堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2853,
        pid: 2846,
        district: "花王堂区",
        level: 2,
        childrens: null,
        root: false
      },
      {
        id: 2854,
        pid: 2846,
        district: "大堂区",
        level: 2,
        childrens: null,
        root: false
      }
    ],
    root: false
  },
  {
    id: 2855,
    pid: 1,
    district: "甘肃省",
    level: 1,
    childrens: [
      {
        id: 2856,
        pid: 2855,
        district: "白银市",
        level: 2,
        childrens: [
          {
            id: 2857,
            pid: 2856,
            district: "靖远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2858,
            pid: 2856,
            district: "平川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2859,
            pid: 2856,
            district: "白银区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2860,
            pid: 2856,
            district: "会宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2861,
            pid: 2856,
            district: "景泰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2862,
        pid: 2855,
        district: "金昌市",
        level: 2,
        childrens: [
          {
            id: 2863,
            pid: 2862,
            district: "金川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2864,
            pid: 2862,
            district: "永昌县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2865,
        pid: 2855,
        district: "酒泉市",
        level: 2,
        childrens: [
          {
            id: 2866,
            pid: 2865,
            district: "玉门市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2867,
            pid: 2865,
            district: "敦煌市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2868,
            pid: 2865,
            district: "肃北蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2869,
            pid: 2865,
            district: "金塔县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2870,
            pid: 2865,
            district: "肃州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2871,
            pid: 2865,
            district: "阿克塞哈萨克族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2872,
            pid: 2865,
            district: "瓜州县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2873,
        pid: 2855,
        district: "平凉市",
        level: 2,
        childrens: [
          {
            id: 2874,
            pid: 2873,
            district: "崆峒区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2875,
            pid: 2873,
            district: "崇信县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2876,
            pid: 2873,
            district: "庄浪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2877,
            pid: 2873,
            district: "华亭市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2878,
            pid: 2873,
            district: "静宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2879,
            pid: 2873,
            district: "泾川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2880,
            pid: 2873,
            district: "灵台县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2881,
        pid: 2855,
        district: "兰州市",
        level: 2,
        childrens: [
          {
            id: 2882,
            pid: 2881,
            district: "红古区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2883,
            pid: 2881,
            district: "皋兰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2884,
            pid: 2881,
            district: "西固区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2885,
            pid: 2881,
            district: "七里河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2886,
            pid: 2881,
            district: "安宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2887,
            pid: 2881,
            district: "城关区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2888,
            pid: 2881,
            district: "榆中县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2889,
            pid: 2881,
            district: "永登县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2890,
        pid: 2855,
        district: "嘉峪关市",
        level: 2,
        childrens: [
          {
            id: 2891,
            pid: 2890,
            district: "峪泉镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2892,
            pid: 2890,
            district: "新城镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2893,
            pid: 2890,
            district: "文殊镇",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2894,
            pid: 2890,
            district: "雄关街道",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2895,
            pid: 2890,
            district: "钢城街道",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2896,
        pid: 2855,
        district: "陇南市",
        level: 2,
        childrens: [
          {
            id: 2897,
            pid: 2896,
            district: "两当县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2898,
            pid: 2896,
            district: "宕昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2899,
            pid: 2896,
            district: "武都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2900,
            pid: 2896,
            district: "礼县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2901,
            pid: 2896,
            district: "徽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2902,
            pid: 2896,
            district: "文县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2903,
            pid: 2896,
            district: "西和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2904,
            pid: 2896,
            district: "康县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2905,
            pid: 2896,
            district: "成县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2906,
        pid: 2855,
        district: "张掖市",
        level: 2,
        childrens: [
          {
            id: 2907,
            pid: 2906,
            district: "高台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2908,
            pid: 2906,
            district: "肃南裕固族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2909,
            pid: 2906,
            district: "民乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2910,
            pid: 2906,
            district: "山丹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2911,
            pid: 2906,
            district: "甘州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2912,
            pid: 2906,
            district: "临泽县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2913,
        pid: 2855,
        district: "定西市",
        level: 2,
        childrens: [
          {
            id: 2914,
            pid: 2913,
            district: "临洮县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2915,
            pid: 2913,
            district: "岷县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2916,
            pid: 2913,
            district: "渭源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2917,
            pid: 2913,
            district: "漳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2918,
            pid: 2913,
            district: "安定区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2919,
            pid: 2913,
            district: "通渭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2920,
            pid: 2913,
            district: "陇西县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2921,
        pid: 2855,
        district: "临夏回族自治州",
        level: 2,
        childrens: [
          {
            id: 2922,
            pid: 2921,
            district: "东乡族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2923,
            pid: 2921,
            district: "广河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2924,
            pid: 2921,
            district: "和政县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2925,
            pid: 2921,
            district: "康乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2926,
            pid: 2921,
            district: "积石山保安族东乡族撒拉族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2927,
            pid: 2921,
            district: "永靖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2928,
            pid: 2921,
            district: "临夏市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2929,
            pid: 2921,
            district: "临夏县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2930,
        pid: 2855,
        district: "天水市",
        level: 2,
        childrens: [
          {
            id: 2931,
            pid: 2930,
            district: "甘谷县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2932,
            pid: 2930,
            district: "秦州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2933,
            pid: 2930,
            district: "麦积区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2934,
            pid: 2930,
            district: "武山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2935,
            pid: 2930,
            district: "张家川回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2936,
            pid: 2930,
            district: "秦安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2937,
            pid: 2930,
            district: "清水县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2938,
        pid: 2855,
        district: "庆阳市",
        level: 2,
        childrens: [
          {
            id: 2939,
            pid: 2938,
            district: "正宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2940,
            pid: 2938,
            district: "环县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2941,
            pid: 2938,
            district: "镇原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2942,
            pid: 2938,
            district: "华池县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2943,
            pid: 2938,
            district: "宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2944,
            pid: 2938,
            district: "合水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2945,
            pid: 2938,
            district: "庆城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2946,
            pid: 2938,
            district: "西峰区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2947,
        pid: 2855,
        district: "武威市",
        level: 2,
        childrens: [
          {
            id: 2948,
            pid: 2947,
            district: "凉州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2949,
            pid: 2947,
            district: "古浪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2950,
            pid: 2947,
            district: "民勤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2951,
            pid: 2947,
            district: "天祝藏族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2952,
        pid: 2855,
        district: "甘南藏族自治州",
        level: 2,
        childrens: [
          {
            id: 2953,
            pid: 2952,
            district: "合作市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2954,
            pid: 2952,
            district: "夏河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2955,
            pid: 2952,
            district: "临潭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2956,
            pid: 2952,
            district: "卓尼县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2957,
            pid: 2952,
            district: "迭部县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2958,
            pid: 2952,
            district: "玛曲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2959,
            pid: 2952,
            district: "舟曲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2960,
            pid: 2952,
            district: "碌曲县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 2961,
    pid: 1,
    district: "四川省",
    level: 1,
    childrens: [
      {
        id: 2962,
        pid: 2961,
        district: "广元市",
        level: 2,
        childrens: [
          {
            id: 2963,
            pid: 2962,
            district: "朝天区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2964,
            pid: 2962,
            district: "苍溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2965,
            pid: 2962,
            district: "昭化区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2966,
            pid: 2962,
            district: "剑阁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2967,
            pid: 2962,
            district: "旺苍县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2968,
            pid: 2962,
            district: "青川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2969,
            pid: 2962,
            district: "利州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2970,
        pid: 2961,
        district: "南充市",
        level: 2,
        childrens: [
          {
            id: 2971,
            pid: 2970,
            district: "南部县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2972,
            pid: 2970,
            district: "营山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2973,
            pid: 2970,
            district: "蓬安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2974,
            pid: 2970,
            district: "嘉陵区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2975,
            pid: 2970,
            district: "西充县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2976,
            pid: 2970,
            district: "仪陇县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2977,
            pid: 2970,
            district: "顺庆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2978,
            pid: 2970,
            district: "高坪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2979,
            pid: 2970,
            district: "阆中市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2980,
        pid: 2961,
        district: "巴中市",
        level: 2,
        childrens: [
          {
            id: 2981,
            pid: 2980,
            district: "通江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2982,
            pid: 2980,
            district: "巴州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2983,
            pid: 2980,
            district: "平昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2984,
            pid: 2980,
            district: "南江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2985,
            pid: 2980,
            district: "恩阳区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2986,
        pid: 2961,
        district: "德阳市",
        level: 2,
        childrens: [
          {
            id: 2987,
            pid: 2986,
            district: "中江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2988,
            pid: 2986,
            district: "什邡市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2989,
            pid: 2986,
            district: "旌阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2990,
            pid: 2986,
            district: "罗江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2991,
            pid: 2986,
            district: "绵竹市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2992,
            pid: 2986,
            district: "广汉市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 2993,
        pid: 2961,
        district: "绵阳市",
        level: 2,
        childrens: [
          {
            id: 2994,
            pid: 2993,
            district: "江油市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2995,
            pid: 2993,
            district: "梓潼县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2996,
            pid: 2993,
            district: "游仙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2997,
            pid: 2993,
            district: "三台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2998,
            pid: 2993,
            district: "平武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 2999,
            pid: 2993,
            district: "涪城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3000,
            pid: 2993,
            district: "盐亭县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3001,
            pid: 2993,
            district: "北川羌族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3002,
            pid: 2993,
            district: "安州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3003,
        pid: 2961,
        district: "广安市",
        level: 2,
        childrens: [
          {
            id: 3004,
            pid: 3003,
            district: "邻水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3005,
            pid: 3003,
            district: "武胜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3006,
            pid: 3003,
            district: "广安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3007,
            pid: 3003,
            district: "前锋区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3008,
            pid: 3003,
            district: "华蓥市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3009,
            pid: 3003,
            district: "岳池县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3010,
        pid: 2961,
        district: "成都市",
        level: 2,
        childrens: [
          {
            id: 3011,
            pid: 3010,
            district: "彭州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3012,
            pid: 3010,
            district: "崇州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3013,
            pid: 3010,
            district: "大邑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3014,
            pid: 3010,
            district: "简阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3015,
            pid: 3010,
            district: "蒲江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3016,
            pid: 3010,
            district: "金堂县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3017,
            pid: 3010,
            district: "邛崃市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3018,
            pid: 3010,
            district: "温江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3019,
            pid: 3010,
            district: "双流区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3020,
            pid: 3010,
            district: "龙泉驿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3021,
            pid: 3010,
            district: "新津区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3022,
            pid: 3010,
            district: "武侯区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3023,
            pid: 3010,
            district: "成华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3024,
            pid: 3010,
            district: "青羊区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3025,
            pid: 3010,
            district: "金牛区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3026,
            pid: 3010,
            district: "郫都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3027,
            pid: 3010,
            district: "都江堰市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3028,
            pid: 3010,
            district: "锦江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3029,
            pid: 3010,
            district: "新都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3030,
            pid: 3010,
            district: "青白江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3031,
        pid: 2961,
        district: "眉山市",
        level: 2,
        childrens: [
          {
            id: 3032,
            pid: 3031,
            district: "仁寿县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3033,
            pid: 3031,
            district: "洪雅县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3034,
            pid: 3031,
            district: "丹棱县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3035,
            pid: 3031,
            district: "东坡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3036,
            pid: 3031,
            district: "青神县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3037,
            pid: 3031,
            district: "彭山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3038,
        pid: 2961,
        district: "资阳市",
        level: 2,
        childrens: [
          {
            id: 3039,
            pid: 3038,
            district: "乐至县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3040,
            pid: 3038,
            district: "安岳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3041,
            pid: 3038,
            district: "雁江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3042,
        pid: 2961,
        district: "遂宁市",
        level: 2,
        childrens: [
          {
            id: 3043,
            pid: 3042,
            district: "大英县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3044,
            pid: 3042,
            district: "射洪市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3045,
            pid: 3042,
            district: "蓬溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3046,
            pid: 3042,
            district: "船山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3047,
            pid: 3042,
            district: "安居区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3048,
        pid: 2961,
        district: "达州市",
        level: 2,
        childrens: [
          {
            id: 3049,
            pid: 3048,
            district: "大竹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3050,
            pid: 3048,
            district: "宣汉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3051,
            pid: 3048,
            district: "渠县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3052,
            pid: 3048,
            district: "开江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3053,
            pid: 3048,
            district: "万源市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3054,
            pid: 3048,
            district: "通川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3055,
            pid: 3048,
            district: "达川区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3056,
        pid: 2961,
        district: "内江市",
        level: 2,
        childrens: [
          {
            id: 3057,
            pid: 3056,
            district: "资中县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3058,
            pid: 3056,
            district: "威远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3059,
            pid: 3056,
            district: "东兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3060,
            pid: 3056,
            district: "隆昌市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3061,
            pid: 3056,
            district: "市中区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3062,
        pid: 2961,
        district: "凉山彝族自治州",
        level: 2,
        childrens: [
          {
            id: 3063,
            pid: 3062,
            district: "甘洛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3064,
            pid: 3062,
            district: "金阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3065,
            pid: 3062,
            district: "越西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3066,
            pid: 3062,
            district: "木里藏族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3067,
            pid: 3062,
            district: "喜德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3068,
            pid: 3062,
            district: "盐源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3069,
            pid: 3062,
            district: "美姑县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3070,
            pid: 3062,
            district: "会理市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3071,
            pid: 3062,
            district: "冕宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3072,
            pid: 3062,
            district: "雷波县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3073,
            pid: 3062,
            district: "普格县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3074,
            pid: 3062,
            district: "布拖县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3075,
            pid: 3062,
            district: "会东县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3076,
            pid: 3062,
            district: "德昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3077,
            pid: 3062,
            district: "宁南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3078,
            pid: 3062,
            district: "西昌市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3079,
            pid: 3062,
            district: "昭觉县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3080,
        pid: 2961,
        district: "泸州市",
        level: 2,
        childrens: [
          {
            id: 3081,
            pid: 3080,
            district: "泸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3082,
            pid: 3080,
            district: "纳溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3083,
            pid: 3080,
            district: "龙马潭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3084,
            pid: 3080,
            district: "古蔺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3085,
            pid: 3080,
            district: "江阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3086,
            pid: 3080,
            district: "叙永县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3087,
            pid: 3080,
            district: "合江县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3088,
        pid: 2961,
        district: "乐山市",
        level: 2,
        childrens: [
          {
            id: 3089,
            pid: 3088,
            district: "夹江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3090,
            pid: 3088,
            district: "五通桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3091,
            pid: 3088,
            district: "沙湾区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3092,
            pid: 3088,
            district: "井研县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3093,
            pid: 3088,
            district: "金口河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3094,
            pid: 3088,
            district: "峨边彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3095,
            pid: 3088,
            district: "犍为县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3096,
            pid: 3088,
            district: "沐川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3097,
            pid: 3088,
            district: "马边彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3098,
            pid: 3088,
            district: "市中区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3099,
            pid: 3088,
            district: "峨眉山市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3100,
        pid: 2961,
        district: "自贡市",
        level: 2,
        childrens: [
          {
            id: 3101,
            pid: 3100,
            district: "大安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3102,
            pid: 3100,
            district: "荣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3103,
            pid: 3100,
            district: "沿滩区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3104,
            pid: 3100,
            district: "富顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3105,
            pid: 3100,
            district: "贡井区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3106,
            pid: 3100,
            district: "自流井区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3107,
        pid: 2961,
        district: "攀枝花市",
        level: 2,
        childrens: [
          {
            id: 3108,
            pid: 3107,
            district: "米易县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3109,
            pid: 3107,
            district: "西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3110,
            pid: 3107,
            district: "盐边县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3111,
            pid: 3107,
            district: "东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3112,
            pid: 3107,
            district: "仁和区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3113,
        pid: 2961,
        district: "宜宾市",
        level: 2,
        childrens: [
          {
            id: 3114,
            pid: 3113,
            district: "筠连县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3115,
            pid: 3113,
            district: "高县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3116,
            pid: 3113,
            district: "江安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3117,
            pid: 3113,
            district: "长宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3118,
            pid: 3113,
            district: "屏山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3119,
            pid: 3113,
            district: "南溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3120,
            pid: 3113,
            district: "珙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3121,
            pid: 3113,
            district: "翠屏区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3122,
            pid: 3113,
            district: "兴文县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3123,
            pid: 3113,
            district: "叙州区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3124,
        pid: 2961,
        district: "甘孜藏族自治州",
        level: 2,
        childrens: [
          {
            id: 3125,
            pid: 3124,
            district: "甘孜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3126,
            pid: 3124,
            district: "石渠县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3127,
            pid: 3124,
            district: "德格县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3128,
            pid: 3124,
            district: "炉霍县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3129,
            pid: 3124,
            district: "白玉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3130,
            pid: 3124,
            district: "新龙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3131,
            pid: 3124,
            district: "道孚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3132,
            pid: 3124,
            district: "巴塘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3133,
            pid: 3124,
            district: "康定市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3134,
            pid: 3124,
            district: "理塘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3135,
            pid: 3124,
            district: "雅江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3136,
            pid: 3124,
            district: "稻城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3137,
            pid: 3124,
            district: "乡城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3138,
            pid: 3124,
            district: "九龙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3139,
            pid: 3124,
            district: "丹巴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3140,
            pid: 3124,
            district: "色达县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3141,
            pid: 3124,
            district: "得荣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3142,
            pid: 3124,
            district: "泸定县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3143,
        pid: 2961,
        district: "阿坝藏族羌族自治州",
        level: 2,
        childrens: [
          {
            id: 3144,
            pid: 3143,
            district: "红原县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3145,
            pid: 3143,
            district: "九寨沟县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3146,
            pid: 3143,
            district: "阿坝县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3147,
            pid: 3143,
            district: "黑水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3148,
            pid: 3143,
            district: "马尔康市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3149,
            pid: 3143,
            district: "金川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3150,
            pid: 3143,
            district: "理县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3151,
            pid: 3143,
            district: "汶川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3152,
            pid: 3143,
            district: "茂县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3153,
            pid: 3143,
            district: "小金县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3154,
            pid: 3143,
            district: "松潘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3155,
            pid: 3143,
            district: "壤塘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3156,
            pid: 3143,
            district: "若尔盖县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3157,
        pid: 2961,
        district: "雅安市",
        level: 2,
        childrens: [
          {
            id: 3158,
            pid: 3157,
            district: "宝兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3159,
            pid: 3157,
            district: "芦山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3160,
            pid: 3157,
            district: "天全县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3161,
            pid: 3157,
            district: "荥经县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3162,
            pid: 3157,
            district: "雨城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3163,
            pid: 3157,
            district: "石棉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3164,
            pid: 3157,
            district: "名山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3165,
            pid: 3157,
            district: "汉源县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3166,
    pid: 1,
    district: "天津市",
    level: 1,
    childrens: [
      {
        id: 3167,
        pid: 3166,
        district: "天津城区",
        level: 2,
        childrens: [
          {
            id: 3168,
            pid: 3167,
            district: "滨海新区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3169,
            pid: 3167,
            district: "静海区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3170,
            pid: 3167,
            district: "河北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3171,
            pid: 3167,
            district: "宝坻区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3172,
            pid: 3167,
            district: "蓟州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3173,
            pid: 3167,
            district: "武清区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3174,
            pid: 3167,
            district: "南开区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3175,
            pid: 3167,
            district: "宁河区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3176,
            pid: 3167,
            district: "红桥区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3177,
            pid: 3167,
            district: "津南区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3178,
            pid: 3167,
            district: "西青区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3179,
            pid: 3167,
            district: "北辰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3180,
            pid: 3167,
            district: "东丽区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3181,
            pid: 3167,
            district: "河东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3182,
            pid: 3167,
            district: "河西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3183,
            pid: 3167,
            district: "和平区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3184,
    pid: 1,
    district: "江西省",
    level: 1,
    childrens: [
      {
        id: 3185,
        pid: 3184,
        district: "赣州市",
        level: 2,
        childrens: [
          {
            id: 3186,
            pid: 3185,
            district: "宁都县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3187,
            pid: 3185,
            district: "石城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3188,
            pid: 3185,
            district: "于都县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3189,
            pid: 3185,
            district: "兴国县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3190,
            pid: 3185,
            district: "瑞金市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3191,
            pid: 3185,
            district: "上犹县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3192,
            pid: 3185,
            district: "寻乌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3193,
            pid: 3185,
            district: "大余县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3194,
            pid: 3185,
            district: "崇义县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3195,
            pid: 3185,
            district: "全南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3196,
            pid: 3185,
            district: "龙南市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3197,
            pid: 3185,
            district: "定南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3198,
            pid: 3185,
            district: "赣县区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3199,
            pid: 3185,
            district: "章贡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3200,
            pid: 3185,
            district: "安远县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3201,
            pid: 3185,
            district: "会昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3202,
            pid: 3185,
            district: "南康区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3203,
            pid: 3185,
            district: "信丰县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3204,
        pid: 3184,
        district: "景德镇市",
        level: 2,
        childrens: [
          {
            id: 3205,
            pid: 3204,
            district: "昌江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3206,
            pid: 3204,
            district: "浮梁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3207,
            pid: 3204,
            district: "乐平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3208,
            pid: 3204,
            district: "珠山区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3209,
        pid: 3184,
        district: "萍乡市",
        level: 2,
        childrens: [
          {
            id: 3210,
            pid: 3209,
            district: "安源区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3211,
            pid: 3209,
            district: "上栗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3212,
            pid: 3209,
            district: "芦溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3213,
            pid: 3209,
            district: "莲花县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3214,
            pid: 3209,
            district: "湘东区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3215,
        pid: 3184,
        district: "新余市",
        level: 2,
        childrens: [
          {
            id: 3216,
            pid: 3215,
            district: "分宜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3217,
            pid: 3215,
            district: "渝水区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3218,
        pid: 3184,
        district: "吉安市",
        level: 2,
        childrens: [
          {
            id: 3219,
            pid: 3218,
            district: "永丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3220,
            pid: 3218,
            district: "吉安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3221,
            pid: 3218,
            district: "吉水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3222,
            pid: 3218,
            district: "吉州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3223,
            pid: 3218,
            district: "遂川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3224,
            pid: 3218,
            district: "永新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3225,
            pid: 3218,
            district: "万安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3226,
            pid: 3218,
            district: "青原区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3227,
            pid: 3218,
            district: "新干县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3228,
            pid: 3218,
            district: "安福县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3229,
            pid: 3218,
            district: "泰和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3230,
            pid: 3218,
            district: "峡江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3231,
            pid: 3218,
            district: "井冈山市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3232,
        pid: 3184,
        district: "上饶市",
        level: 2,
        childrens: [
          {
            id: 3233,
            pid: 3232,
            district: "横峰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3234,
            pid: 3232,
            district: "婺源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3235,
            pid: 3232,
            district: "弋阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3236,
            pid: 3232,
            district: "广丰区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3237,
            pid: 3232,
            district: "铅山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3238,
            pid: 3232,
            district: "玉山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3239,
            pid: 3232,
            district: "广信区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3240,
            pid: 3232,
            district: "信州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3241,
            pid: 3232,
            district: "余干县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3242,
            pid: 3232,
            district: "鄱阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3243,
            pid: 3232,
            district: "万年县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3244,
            pid: 3232,
            district: "德兴市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3245,
        pid: 3184,
        district: "南昌市",
        level: 2,
        childrens: [
          {
            id: 3246,
            pid: 3245,
            district: "进贤县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3247,
            pid: 3245,
            district: "青云谱区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3248,
            pid: 3245,
            district: "青山湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3249,
            pid: 3245,
            district: "东湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3250,
            pid: 3245,
            district: "南昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3251,
            pid: 3245,
            district: "西湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3252,
            pid: 3245,
            district: "安义县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3253,
            pid: 3245,
            district: "新建区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3254,
            pid: 3245,
            district: "红谷滩区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3255,
        pid: 3184,
        district: "鹰潭市",
        level: 2,
        childrens: [
          {
            id: 3256,
            pid: 3255,
            district: "月湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3257,
            pid: 3255,
            district: "余江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3258,
            pid: 3255,
            district: "贵溪市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3259,
        pid: 3184,
        district: "抚州市",
        level: 2,
        childrens: [
          {
            id: 3260,
            pid: 3259,
            district: "临川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3261,
            pid: 3259,
            district: "崇仁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3262,
            pid: 3259,
            district: "东乡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3263,
            pid: 3259,
            district: "资溪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3264,
            pid: 3259,
            district: "宜黄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3265,
            pid: 3259,
            district: "南丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3266,
            pid: 3259,
            district: "南城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3267,
            pid: 3259,
            district: "乐安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3268,
            pid: 3259,
            district: "黎川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3269,
            pid: 3259,
            district: "广昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3270,
            pid: 3259,
            district: "金溪县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3271,
        pid: 3184,
        district: "九江市",
        level: 2,
        childrens: [
          {
            id: 3272,
            pid: 3271,
            district: "彭泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3273,
            pid: 3271,
            district: "武宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3274,
            pid: 3271,
            district: "柴桑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3275,
            pid: 3271,
            district: "庐山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3276,
            pid: 3271,
            district: "濂溪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3277,
            pid: 3271,
            district: "共青城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3278,
            pid: 3271,
            district: "德安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3279,
            pid: 3271,
            district: "瑞昌市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3280,
            pid: 3271,
            district: "浔阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3281,
            pid: 3271,
            district: "湖口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3282,
            pid: 3271,
            district: "永修县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3283,
            pid: 3271,
            district: "都昌县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3284,
            pid: 3271,
            district: "修水县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3285,
        pid: 3184,
        district: "宜春市",
        level: 2,
        childrens: [
          {
            id: 3286,
            pid: 3285,
            district: "宜丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3287,
            pid: 3285,
            district: "高安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3288,
            pid: 3285,
            district: "丰城市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3289,
            pid: 3285,
            district: "上高县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3290,
            pid: 3285,
            district: "铜鼓县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3291,
            pid: 3285,
            district: "袁州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3292,
            pid: 3285,
            district: "万载县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3293,
            pid: 3285,
            district: "樟树市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3294,
            pid: 3285,
            district: "奉新县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3295,
            pid: 3285,
            district: "靖安县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3296,
    pid: 1,
    district: "云南省",
    level: 1,
    childrens: [
      {
        id: 3297,
        pid: 3296,
        district: "昭通市",
        level: 2,
        childrens: [
          {
            id: 3298,
            pid: 3297,
            district: "永善县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3299,
            pid: 3297,
            district: "绥江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3300,
            pid: 3297,
            district: "水富市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3301,
            pid: 3297,
            district: "大关县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3302,
            pid: 3297,
            district: "威信县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3303,
            pid: 3297,
            district: "巧家县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3304,
            pid: 3297,
            district: "镇雄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3305,
            pid: 3297,
            district: "鲁甸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3306,
            pid: 3297,
            district: "昭阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3307,
            pid: 3297,
            district: "彝良县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3308,
            pid: 3297,
            district: "盐津县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3309,
        pid: 3296,
        district: "曲靖市",
        level: 2,
        childrens: [
          {
            id: 3310,
            pid: 3309,
            district: "会泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3311,
            pid: 3309,
            district: "马龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3312,
            pid: 3309,
            district: "陆良县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3313,
            pid: 3309,
            district: "宣威市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3314,
            pid: 3309,
            district: "富源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3315,
            pid: 3309,
            district: "麒麟区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3316,
            pid: 3309,
            district: "沾益区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3317,
            pid: 3309,
            district: "罗平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3318,
            pid: 3309,
            district: "师宗县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3319,
        pid: 3296,
        district: "大理白族自治州",
        level: 2,
        childrens: [
          {
            id: 3320,
            pid: 3319,
            district: "洱源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3321,
            pid: 3319,
            district: "云龙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3322,
            pid: 3319,
            district: "剑川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3323,
            pid: 3319,
            district: "鹤庆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3324,
            pid: 3319,
            district: "宾川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3325,
            pid: 3319,
            district: "大理市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3326,
            pid: 3319,
            district: "永平县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3327,
            pid: 3319,
            district: "祥云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3328,
            pid: 3319,
            district: "漾濞彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3329,
            pid: 3319,
            district: "巍山彝族回族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3330,
            pid: 3319,
            district: "弥渡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3331,
            pid: 3319,
            district: "南涧彝族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3332,
        pid: 3296,
        district: "保山市",
        level: 2,
        childrens: [
          {
            id: 3333,
            pid: 3332,
            district: "腾冲市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3334,
            pid: 3332,
            district: "隆阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3335,
            pid: 3332,
            district: "施甸县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3336,
            pid: 3332,
            district: "龙陵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3337,
            pid: 3332,
            district: "昌宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3338,
        pid: 3296,
        district: "西双版纳傣族自治州",
        level: 2,
        childrens: [
          {
            id: 3339,
            pid: 3338,
            district: "勐海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3340,
            pid: 3338,
            district: "景洪市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3341,
            pid: 3338,
            district: "勐腊县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3342,
        pid: 3296,
        district: "怒江傈僳族自治州",
        level: 2,
        childrens: [
          {
            id: 3343,
            pid: 3342,
            district: "贡山独龙族怒族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3344,
            pid: 3342,
            district: "兰坪白族普米族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3345,
            pid: 3342,
            district: "福贡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3346,
            pid: 3342,
            district: "泸水市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3347,
        pid: 3296,
        district: "丽江市",
        level: 2,
        childrens: [
          {
            id: 3348,
            pid: 3347,
            district: "宁蒗彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3349,
            pid: 3347,
            district: "玉龙纳西族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3350,
            pid: 3347,
            district: "华坪县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3351,
            pid: 3347,
            district: "古城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3352,
            pid: 3347,
            district: "永胜县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3353,
        pid: 3296,
        district: "红河哈尼族彝族自治州",
        level: 2,
        childrens: [
          {
            id: 3354,
            pid: 3353,
            district: "弥勒市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3355,
            pid: 3353,
            district: "泸西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3356,
            pid: 3353,
            district: "建水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3357,
            pid: 3353,
            district: "石屏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3358,
            pid: 3353,
            district: "开远市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3359,
            pid: 3353,
            district: "个旧市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3360,
            pid: 3353,
            district: "屏边苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3361,
            pid: 3353,
            district: "绿春县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3362,
            pid: 3353,
            district: "红河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3363,
            pid: 3353,
            district: "蒙自市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3364,
            pid: 3353,
            district: "元阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3365,
            pid: 3353,
            district: "金平苗族瑶族傣族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3366,
            pid: 3353,
            district: "河口瑶族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3367,
        pid: 3296,
        district: "玉溪市",
        level: 2,
        childrens: [
          {
            id: 3368,
            pid: 3367,
            district: "易门县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3369,
            pid: 3367,
            district: "峨山彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3370,
            pid: 3367,
            district: "江川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3371,
            pid: 3367,
            district: "华宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3372,
            pid: 3367,
            district: "红塔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3373,
            pid: 3367,
            district: "新平彝族傣族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3374,
            pid: 3367,
            district: "通海县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3375,
            pid: 3367,
            district: "元江哈尼族彝族傣族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3376,
            pid: 3367,
            district: "澄江市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3377,
        pid: 3296,
        district: "文山壮族苗族自治州",
        level: 2,
        childrens: [
          {
            id: 3378,
            pid: 3377,
            district: "广南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3379,
            pid: 3377,
            district: "丘北县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3380,
            pid: 3377,
            district: "文山市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3381,
            pid: 3377,
            district: "砚山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3382,
            pid: 3377,
            district: "西畴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3383,
            pid: 3377,
            district: "麻栗坡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3384,
            pid: 3377,
            district: "马关县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3385,
            pid: 3377,
            district: "富宁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3386,
        pid: 3296,
        district: "迪庆藏族自治州",
        level: 2,
        childrens: [
          {
            id: 3387,
            pid: 3386,
            district: "德钦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3388,
            pid: 3386,
            district: "香格里拉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3389,
            pid: 3386,
            district: "维西傈僳族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3390,
        pid: 3296,
        district: "普洱市",
        level: 2,
        childrens: [
          {
            id: 3391,
            pid: 3390,
            district: "景东彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3392,
            pid: 3390,
            district: "镇沅彝族哈尼族拉祜族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3393,
            pid: 3390,
            district: "景谷傣族彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3394,
            pid: 3390,
            district: "墨江哈尼族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3395,
            pid: 3390,
            district: "宁洱哈尼族彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3396,
            pid: 3390,
            district: "澜沧拉祜族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3397,
            pid: 3390,
            district: "思茅区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3398,
            pid: 3390,
            district: "西盟佤族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3399,
            pid: 3390,
            district: "孟连傣族拉祜族佤族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3400,
            pid: 3390,
            district: "江城哈尼族彝族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3401,
        pid: 3296,
        district: "楚雄彝族自治州",
        level: 2,
        childrens: [
          {
            id: 3402,
            pid: 3401,
            district: "大姚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3403,
            pid: 3401,
            district: "牟定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3404,
            pid: 3401,
            district: "元谋县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3405,
            pid: 3401,
            district: "姚安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3406,
            pid: 3401,
            district: "南华县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3407,
            pid: 3401,
            district: "禄丰市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3408,
            pid: 3401,
            district: "楚雄市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3409,
            pid: 3401,
            district: "双柏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3410,
            pid: 3401,
            district: "武定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3411,
            pid: 3401,
            district: "永仁县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3412,
        pid: 3296,
        district: "临沧市",
        level: 2,
        childrens: [
          {
            id: 3413,
            pid: 3412,
            district: "凤庆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3414,
            pid: 3412,
            district: "云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3415,
            pid: 3412,
            district: "镇康县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3416,
            pid: 3412,
            district: "永德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3417,
            pid: 3412,
            district: "临翔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3418,
            pid: 3412,
            district: "双江拉祜族佤族布朗族傣族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3419,
            pid: 3412,
            district: "耿马傣族佤族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3420,
            pid: 3412,
            district: "沧源佤族自治县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3421,
        pid: 3296,
        district: "昆明市",
        level: 2,
        childrens: [
          {
            id: 3422,
            pid: 3421,
            district: "东川区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3423,
            pid: 3421,
            district: "寻甸回族彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3424,
            pid: 3421,
            district: "宜良县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3425,
            pid: 3421,
            district: "五华区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3426,
            pid: 3421,
            district: "西山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3427,
            pid: 3421,
            district: "石林彝族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3428,
            pid: 3421,
            district: "晋宁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3429,
            pid: 3421,
            district: "安宁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3430,
            pid: 3421,
            district: "富民县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3431,
            pid: 3421,
            district: "禄劝彝族苗族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3432,
            pid: 3421,
            district: "嵩明县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3433,
            pid: 3421,
            district: "盘龙区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3434,
            pid: 3421,
            district: "官渡区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3435,
            pid: 3421,
            district: "呈贡区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3436,
        pid: 3296,
        district: "德宏傣族景颇族自治州",
        level: 2,
        childrens: [
          {
            id: 3437,
            pid: 3436,
            district: "盈江县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3438,
            pid: 3436,
            district: "梁河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3439,
            pid: 3436,
            district: "芒市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3440,
            pid: 3436,
            district: "陇川县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3441,
            pid: 3436,
            district: "瑞丽市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3442,
    pid: 1,
    district: "山西省",
    level: 1,
    childrens: [
      {
        id: 3443,
        pid: 3442,
        district: "阳泉市",
        level: 2,
        childrens: [
          {
            id: 3444,
            pid: 3443,
            district: "平定县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3445,
            pid: 3443,
            district: "盂县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3446,
            pid: 3443,
            district: "矿区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3447,
            pid: 3443,
            district: "城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3448,
            pid: 3443,
            district: "郊区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3449,
        pid: 3442,
        district: "太原市",
        level: 2,
        childrens: [
          {
            id: 3450,
            pid: 3449,
            district: "娄烦县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3451,
            pid: 3449,
            district: "杏花岭区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3452,
            pid: 3449,
            district: "阳曲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3453,
            pid: 3449,
            district: "古交市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3454,
            pid: 3449,
            district: "清徐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3455,
            pid: 3449,
            district: "迎泽区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3456,
            pid: 3449,
            district: "小店区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3457,
            pid: 3449,
            district: "尖草坪区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3458,
            pid: 3449,
            district: "晋源区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3459,
            pid: 3449,
            district: "万柏林区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3460,
        pid: 3442,
        district: "吕梁市",
        level: 2,
        childrens: [
          {
            id: 3461,
            pid: 3460,
            district: "方山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3462,
            pid: 3460,
            district: "岚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3463,
            pid: 3460,
            district: "临县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3464,
            pid: 3460,
            district: "离石区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3465,
            pid: 3460,
            district: "中阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3466,
            pid: 3460,
            district: "柳林县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3467,
            pid: 3460,
            district: "交口县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3468,
            pid: 3460,
            district: "兴县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3469,
            pid: 3460,
            district: "石楼县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3470,
            pid: 3460,
            district: "交城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3471,
            pid: 3460,
            district: "文水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3472,
            pid: 3460,
            district: "汾阳市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3473,
            pid: 3460,
            district: "孝义市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3474,
        pid: 3442,
        district: "晋中市",
        level: 2,
        childrens: [
          {
            id: 3475,
            pid: 3474,
            district: "昔阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3476,
            pid: 3474,
            district: "和顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3477,
            pid: 3474,
            district: "介休市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3478,
            pid: 3474,
            district: "榆社县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3479,
            pid: 3474,
            district: "寿阳县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3480,
            pid: 3474,
            district: "左权县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3481,
            pid: 3474,
            district: "灵石县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3482,
            pid: 3474,
            district: "榆次区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3483,
            pid: 3474,
            district: "太谷区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3484,
            pid: 3474,
            district: "祁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3485,
            pid: 3474,
            district: "平遥县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3486,
        pid: 3442,
        district: "长治市",
        level: 2,
        childrens: [
          {
            id: 3487,
            pid: 3486,
            district: "黎城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3488,
            pid: 3486,
            district: "潞城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3489,
            pid: 3486,
            district: "平顺县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3490,
            pid: 3486,
            district: "襄垣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3491,
            pid: 3486,
            district: "潞州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3492,
            pid: 3486,
            district: "沁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3493,
            pid: 3486,
            district: "武乡县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3494,
            pid: 3486,
            district: "壶关县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3495,
            pid: 3486,
            district: "沁源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3496,
            pid: 3486,
            district: "屯留区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3497,
            pid: 3486,
            district: "上党区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3498,
            pid: 3486,
            district: "长子县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3499,
        pid: 3442,
        district: "运城市",
        level: 2,
        childrens: [
          {
            id: 3500,
            pid: 3499,
            district: "万荣县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3501,
            pid: 3499,
            district: "盐湖区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3502,
            pid: 3499,
            district: "闻喜县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3503,
            pid: 3499,
            district: "平陆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3504,
            pid: 3499,
            district: "临猗县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3505,
            pid: 3499,
            district: "夏县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3506,
            pid: 3499,
            district: "新绛县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3507,
            pid: 3499,
            district: "垣曲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3508,
            pid: 3499,
            district: "河津市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3509,
            pid: 3499,
            district: "稷山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3510,
            pid: 3499,
            district: "芮城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3511,
            pid: 3499,
            district: "永济市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3512,
            pid: 3499,
            district: "绛县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3513,
        pid: 3442,
        district: "临汾市",
        level: 2,
        childrens: [
          {
            id: 3514,
            pid: 3513,
            district: "古县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3515,
            pid: 3513,
            district: "大宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3516,
            pid: 3513,
            district: "隰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3517,
            pid: 3513,
            district: "汾西县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3518,
            pid: 3513,
            district: "安泽县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3519,
            pid: 3513,
            district: "吉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3520,
            pid: 3513,
            district: "永和县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3521,
            pid: 3513,
            district: "侯马市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3522,
            pid: 3513,
            district: "霍州市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3523,
            pid: 3513,
            district: "乡宁县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3524,
            pid: 3513,
            district: "蒲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3525,
            pid: 3513,
            district: "浮山县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3526,
            pid: 3513,
            district: "尧都区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3527,
            pid: 3513,
            district: "翼城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3528,
            pid: 3513,
            district: "襄汾县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3529,
            pid: 3513,
            district: "洪洞县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3530,
            pid: 3513,
            district: "曲沃县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3531,
        pid: 3442,
        district: "忻州市",
        level: 2,
        childrens: [
          {
            id: 3532,
            pid: 3531,
            district: "五台县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3533,
            pid: 3531,
            district: "偏关县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3534,
            pid: 3531,
            district: "宁武县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3535,
            pid: 3531,
            district: "五寨县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3536,
            pid: 3531,
            district: "岢岚县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3537,
            pid: 3531,
            district: "忻府区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3538,
            pid: 3531,
            district: "定襄县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3539,
            pid: 3531,
            district: "静乐县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3540,
            pid: 3531,
            district: "神池县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3541,
            pid: 3531,
            district: "保德县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3542,
            pid: 3531,
            district: "繁峙县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3543,
            pid: 3531,
            district: "河曲县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3544,
            pid: 3531,
            district: "代县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3545,
            pid: 3531,
            district: "原平市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3546,
        pid: 3442,
        district: "朔州市",
        level: 2,
        childrens: [
          {
            id: 3547,
            pid: 3546,
            district: "右玉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3548,
            pid: 3546,
            district: "平鲁区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3549,
            pid: 3546,
            district: "怀仁市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3550,
            pid: 3546,
            district: "应县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3551,
            pid: 3546,
            district: "朔城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3552,
            pid: 3546,
            district: "山阴县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3553,
        pid: 3442,
        district: "大同市",
        level: 2,
        childrens: [
          {
            id: 3554,
            pid: 3553,
            district: "灵丘县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3555,
            pid: 3553,
            district: "广灵县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3556,
            pid: 3553,
            district: "浑源县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3557,
            pid: 3553,
            district: "阳高县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3558,
            pid: 3553,
            district: "新荣区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3559,
            pid: 3553,
            district: "云州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3560,
            pid: 3553,
            district: "左云县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3561,
            pid: 3553,
            district: "云冈区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3562,
            pid: 3553,
            district: "平城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3563,
            pid: 3553,
            district: "天镇县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3564,
        pid: 3442,
        district: "晋城市",
        level: 2,
        childrens: [
          {
            id: 3565,
            pid: 3564,
            district: "城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3566,
            pid: 3564,
            district: "泽州县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3567,
            pid: 3564,
            district: "沁水县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3568,
            pid: 3564,
            district: "阳城县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3569,
            pid: 3564,
            district: "高平市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3570,
            pid: 3564,
            district: "陵川县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3571,
    pid: 1,
    district: "北京市",
    level: 1,
    childrens: [
      {
        id: 3572,
        pid: 3571,
        district: "北京市",
        level: 2,
        childrens: [
          {
            id: 3573,
            pid: 3572,
            district: "怀柔区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3574,
            pid: 3572,
            district: "门头沟区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3575,
            pid: 3572,
            district: "延庆区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3576,
            pid: 3572,
            district: "顺义区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3577,
            pid: 3572,
            district: "平谷区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3578,
            pid: 3572,
            district: "东城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3579,
            pid: 3572,
            district: "朝阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3580,
            pid: 3572,
            district: "房山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3581,
            pid: 3572,
            district: "丰台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3582,
            pid: 3572,
            district: "石景山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3583,
            pid: 3572,
            district: "西城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3584,
            pid: 3572,
            district: "密云区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3585,
            pid: 3572,
            district: "大兴区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3586,
            pid: 3572,
            district: "通州区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3587,
            pid: 3572,
            district: "海淀区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3588,
            pid: 3572,
            district: "昌平区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  },
  {
    id: 3589,
    pid: 1,
    district: "吉林省",
    level: 1,
    childrens: [
      {
        id: 3590,
        pid: 3589,
        district: "吉林市",
        level: 2,
        childrens: [
          {
            id: 3591,
            pid: 3590,
            district: "舒兰市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3592,
            pid: 3590,
            district: "桦甸市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3593,
            pid: 3590,
            district: "蛟河市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3594,
            pid: 3590,
            district: "永吉县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3595,
            pid: 3590,
            district: "磐石市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3596,
            pid: 3590,
            district: "昌邑区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3597,
            pid: 3590,
            district: "船营区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3598,
            pid: 3590,
            district: "丰满区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3599,
            pid: 3590,
            district: "龙潭区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3600,
        pid: 3589,
        district: "白城市",
        level: 2,
        childrens: [
          {
            id: 3601,
            pid: 3600,
            district: "大安市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3602,
            pid: 3600,
            district: "通榆县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3603,
            pid: 3600,
            district: "洮南市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3604,
            pid: 3600,
            district: "洮北区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3605,
            pid: 3600,
            district: "镇赉县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3606,
        pid: 3589,
        district: "长春市",
        level: 2,
        childrens: [
          {
            id: 3607,
            pid: 3606,
            district: "德惠市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3608,
            pid: 3606,
            district: "榆树市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3609,
            pid: 3606,
            district: "双阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3610,
            pid: 3606,
            district: "农安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3611,
            pid: 3606,
            district: "宽城区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3612,
            pid: 3606,
            district: "九台区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3613,
            pid: 3606,
            district: "朝阳区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3614,
            pid: 3606,
            district: "公主岭市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3615,
            pid: 3606,
            district: "绿园区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3616,
            pid: 3606,
            district: "二道区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3617,
            pid: 3606,
            district: "南关区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3618,
        pid: 3589,
        district: "松原市",
        level: 2,
        childrens: [
          {
            id: 3619,
            pid: 3618,
            district: "扶余市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3620,
            pid: 3618,
            district: "宁江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3621,
            pid: 3618,
            district: "前郭尔罗斯蒙古族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3622,
            pid: 3618,
            district: "乾安县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3623,
            pid: 3618,
            district: "长岭县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3624,
        pid: 3589,
        district: "辽源市",
        level: 2,
        childrens: [
          {
            id: 3625,
            pid: 3624,
            district: "西安区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3626,
            pid: 3624,
            district: "东丰县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3627,
            pid: 3624,
            district: "龙山区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3628,
            pid: 3624,
            district: "东辽县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3629,
        pid: 3589,
        district: "四平市",
        level: 2,
        childrens: [
          {
            id: 3630,
            pid: 3629,
            district: "双辽市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3631,
            pid: 3629,
            district: "铁东区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3632,
            pid: 3629,
            district: "伊通满族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3633,
            pid: 3629,
            district: "铁西区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3634,
            pid: 3629,
            district: "梨树县",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3635,
        pid: 3589,
        district: "延边朝鲜族自治州",
        level: 2,
        childrens: [
          {
            id: 3636,
            pid: 3635,
            district: "珲春市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3637,
            pid: 3635,
            district: "和龙市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3638,
            pid: 3635,
            district: "图们市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3639,
            pid: 3635,
            district: "汪清县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3640,
            pid: 3635,
            district: "安图县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3641,
            pid: 3635,
            district: "延吉市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3642,
            pid: 3635,
            district: "敦化市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3643,
            pid: 3635,
            district: "龙井市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3644,
        pid: 3589,
        district: "白山市",
        level: 2,
        childrens: [
          {
            id: 3645,
            pid: 3644,
            district: "靖宇县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3646,
            pid: 3644,
            district: "江源区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3647,
            pid: 3644,
            district: "长白朝鲜族自治县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3648,
            pid: 3644,
            district: "抚松县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3649,
            pid: 3644,
            district: "临江市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3650,
            pid: 3644,
            district: "浑江区",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      },
      {
        id: 3651,
        pid: 3589,
        district: "通化市",
        level: 2,
        childrens: [
          {
            id: 3652,
            pid: 3651,
            district: "二道江区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3653,
            pid: 3651,
            district: "柳河县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3654,
            pid: 3651,
            district: "通化县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3655,
            pid: 3651,
            district: "东昌区",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3656,
            pid: 3651,
            district: "辉南县",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3657,
            pid: 3651,
            district: "梅河口市",
            level: 3,
            childrens: null,
            root: false
          },
          {
            id: 3658,
            pid: 3651,
            district: "集安市",
            level: 3,
            childrens: null,
            root: false
          }
        ],
        root: false
      }
    ],
    root: false
  }   
];
