import axios from 'axios'

export  function getToken(data){
    return new Promise((resolve,reject)=>{
        axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          });
    })
}