<template>
  <div>
    <el-upload
      class="upload-demo"
      action="#"
      :show-file-list="false"
      :auto-upload="true"
      :before-upload="beforeUpload"
      accept=".mp4"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">文件大小不能超过500MB</div>
    </el-upload>
    <el-progress
      v-show="videoForm.percent < 100 && videoForm.name"
      :percentage="videoForm.percent"
    ></el-progress>
    <div class="item" v-show="videoForm.url">
      <div>
        <video
          :src="videoForm.url"
          :poster="videoForm.poster"
          controls
          alt=""
          class="videocover"
        ></video>
      </div>
      <div class="name">{{ videoForm.name }}</div>

      <el-button
        type="danger"
        icon="el-icon-delete"
        circle
        @click="remove"
      ></el-button>
    </div>
  </div>
</template>

<script>
import * as qiniu from "qiniu-js";
import { getToken } from "@/api/upload.js";
export default {
  data() {
    return {
      upload_qiniu_addr: "https://img.curiousmore.com/",
      qiniuToken: "",
      qiniuKey: "",
      videoForm: { percent: 0, url: "", name: "" },
    };
  },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  watch: {
    url: {
      handler(nv) {
        if (nv) {
          this.videoForm.url = nv;
        }
      },
      immediate: true,
    },
  },

  mounted() {},

  methods: {
    //开始上传前处理
    async beforeUpload(file) {
      const isSize = file.size / 1024 / 1024 < 500;
      if (file.name.split(".")[1].toLowerCase() != "mp4") {
        this.$message.error("文件格式错误~");
        return false;
      }
      if (!isSize) {
        this.$message.error("视频大小不能超过500MB!");
        return false;
      }

      const suffix = file.name.split(".")[1]; // 后缀名
      const current = new Date().getTime();
      const key = `video_${current}.${suffix}`;
      this.qiniuKey = key;
      this.videoForm.name = file.name;
      let token = await getToken({ key, type: "mp4" });
      this.qiniuToken = token.data.data;
      //上传七牛云
      this.uploadVideo(file);
      //取消el后续自动上传处理
      return Promise.reject();
    },
    // 上传视频至七牛云
    uploadVideo(file) {
      const _this = this;
      // key为上传后文件名 必填
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z2, // 根据地区不同，官网有不同的配置
      };
      var observable = qiniu.upload(
        file,
        _this.qiniuKey,
        _this.qiniuToken,
        config
      ); // _this.qiniuToken 由后端提供，通过接口获取
      var observer = {
        next(res) {
          // 用于展示上传进度
          _this.videoForm.percent = Number(res.total.percent.toFixed(0));
        },
        error(err) {
          console.log(err);
          // 上传错误报错
        },
        // 上传成功的回调，res中可以拿到七牛云返回的key和hash
        complete(res) {
          _this.handleSuccess();
        },
      };
      this.subscription = observable.subscribe(observer); // 开始上传（赋值给一个全局的参数，可以在合适的时机通过：subscription.unsubscribe() 终止上传）
    },
    //上传成功
    handleSuccess() {
      let url = this.upload_qiniu_addr + this.qiniuKey;
      this.videoForm.url = url;
      this.videoForm.poster = url + "?vframe/png/offset/0";
      console.log(url);
      this.$emit("change", url);
    },
    //删除视频
    remove() {
      this.$alert("确认删除教练宣传视频吗？").then(() => {
        this.videoForm = { percent: 0, url: "", name: "" };
        this.$emit("change", "");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.videocover {
  width: 240px;
  height: 160px;
}
.item {
  display: flex;
  padding: 10px 10px 0 10px;
  border: 1px solid #eee;
  align-items: center;
  border-radius: 5px;
  .name {
    margin: 0 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>