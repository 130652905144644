<template>
  <div class="container" style="padding-bottom: 200px">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <div style="font-size: 26px; color: #333333">基本信息</div>
    <div class="h-border" />

    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <div class="row">
        <el-form-item label="头像" required>
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="getImgUrl"
            :url="user.head_portrait"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="昵称" required>
          <el-input v-model="user.name" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" required>
          <el-input
            v-model="user.real_name"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" required>
          <el-select v-model="user.sex" placeholder="请选择姓别">
            <el-option
              v-for="item in sex"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="城市" required>
          <el-cascader
            :options="city"
            v-model="address"
            :placeholder="address.join('/')"
            :props="{
              children: 'childrens',
              label: 'district',
              multiple: false,
              value: 'district',
            }"
            @change="handleChange"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="user.address">
            <addr-select
              slot="append"
              @getform="addMecAddr"
              type="icon"
            ></addr-select>
          </el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input v-model="user.longitude" placeholder="经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input v-model="user.latitude" placeholder="纬度"></el-input>
        </el-form-item>
      </div>

      <div class="row">
        <el-form-item label="教龄(年)" required>
          <el-input
            v-model="user.teaching_age"
            type="number"
            placeholder="请输入年龄"
          ></el-input>
        </el-form-item>
        <el-form-item label="授课类型(多选)" required>
          <el-select
            v-model="Class_type"
            placeholder="请选择类型"
            multiple
            @change="ClassCH"
          >
            <el-option
              v-for="item in Classtype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教学类别(多选)" required>
          <el-select
            v-model="teaching_categories"
            filterable
            placeholder="请选择"
            multiple
            :multiple-limit="5"
            @change="CategoryEvents"
          >
            <el-option
              v-for="item in category"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
      </div>

      
      <div class="row">
          <el-form-item label="陪练主类目">
            <el-select
            v-model="user.main_category"
            placeholder="请选择"
            @change="changeCategory"
          >
            <el-option
              v-for="item in main_categories"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          </el-form-item>


          <el-form-item label="陪练类目(多选)">
          <el-select
            v-model="partner_categories"
            filterable
            placeholder="请选择"
            multiple
            :multiple-limit="3"
            @change="partnerCategoryEvents"
          >
            <el-option
              v-for="item in partner_category"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="row">
        <el-form-item label="服务人群(多选)" required>
          <el-select
            v-model="crowd_k"
            placeholder="请选择服务人群"
            @change="crowdCH"
            multiple
          >
            <el-option
              v-for="item in crowd"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务范围(公里)" required>
          <el-select
            v-model="service_kn"
            placeholder="请选择服务距离"
            @change="kn"
          >
            <el-option
              v-for="item in Range"
              :key="item.kn"
              :label="item.k"
              :value="item.kn"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
         <el-form-item label="教练标签" >
         
            <el-tag
              :key="tag"
              v-for="tag in tagList"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="curTag"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加标签</el-button>
            </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="用户端隐藏">
          <el-switch
            v-model="user.hide"
            inactive-text="不隐藏"
            active-text="隐藏"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否毕业">
          <el-radio v-model="user.is_graduate" :label="true">毕业</el-radio>
          <el-radio v-model="user.is_graduate" :label="false">在校</el-radio>
        </el-form-item>
        <el-form-item label="毕业院校">
          <el-input
            v-model="user.college"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="院校证明">
        <images-uploader
          :limit="6"
          :urls="usercontrast.college_img"
          @getImgUrl="getCollegeImg"
        ></images-uploader>
      </el-form-item>
      <div class="row">
        <el-form-item label="教练宣传照(最多6张)">
          <images-uploader
            :limit="6"
            :urls="usercontrast.introduce_img"
            @getImgUrl="getImges"
          ></images-uploader>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="教练宣传视频">
          <video-upload
            :url="usercontrast.introduce_video"
            @change="getVideo"
          ></video-upload>
        </el-form-item>
      </div>
    </el-form>

    <div>
      <div class="h-border" />
      <el-form>
        <el-form-item label="">
          <div
            class="service-item flex al"
            v-for="(cate, i) in serviceList"
            :key="i"
          >
            <div class="service-name">{{ cate.category }}：</div>
            <div class="">
              <el-input
                type="number"
                class="servie-input"
                v-model="cate.price"
              />
            </div>
            <div class="">元/小时</div>
          </div>
        </el-form-item>
        <el-button type="success" @click="saveService">保存服务价格信息</el-button>
        <el-form-item label="陪练介绍">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="user.service_introduction"
          >
          </el-input>
        </el-form-item>
      </el-form>
      
    </div>

    <div class="h-border" />
    <div>
      <el-button type="warning" @click="teachPic = true" size="small">添加教师资格证</el-button>
      <div class="flexH" style="margin-top: 20px">
        <div
          v-for="(item, index) in certificate_pic"
          :key="index"
          class="honor-box"
        >
          <img :src="item" class="img" alt="" />
          <p style="margin-top: 10px">
            {{ Grade[class_certificate[index] * 1 - 1].name }}
          </p>
          <div class="honor-tb" sy>
            <i
              class="el-icon-circle-close"
              @click="deleteteachPic(index)"
              style="font-size: 30px; cursor: pointer"
            ></i>
          </div>
        </div>
      </div>
      <div>
        <el-button type="warning" size="small" @click="honoropen = true"
          >添加荣誉资质</el-button
        >
        <div
          v-if="!honor_qualification_imgs.length"
          style="font-size: 20px; margin-top: 20px"
        >
          教练没有填写荣誉资质
        </div>
        <div class="flexH" style="margin-top: 20px">
          <div
            v-for="(item, index) in honor_qualification_imgs"
            :key="index"
            class="honor-box"
          >
            <img :src="item" class="img" alt="" />
            <p style="margin-top: 10px">{{ honor_qualification[index] }}</p>
            <div class="honor-tb" sy>
              <i
                class="el-icon-circle-close"
                @click="honoreliminate(index)"
                style="font-size: 30px; cursor: pointer"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot flex jc-end" style="padding-right: 100px">
      <el-button type="primary" @click="ModifyCoach">修改教练信息</el-button>
    </div>
    <div style="font-size: 26px; color: #333333; margin-top: 30px">
      执教经历
    </div>
    <div class="h-border" />
    <el-button
      type="warning"
      @click="(dialogVisible = true), (AddModification = 1)"
      >添加执教经历</el-button
    >
    <div style="height: 20px"></div>
    <div class="grid2" v-if="record.length > 0">
      <div v-for="item in record" :key="item.id">
        <div style="margin-bottom: 10px">
          <el-button type="warning" @click="modify(item)" size="small"
            >修改</el-button
          >
          <el-button type="danger" @click="eliminate(item)" size="small"
            >删除</el-button
          >
        </div>
        <el-descriptions :column="1" border>
          <el-descriptions-item label="执教机构">
            <span>{{ item.institution }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="执教时间"
            >{{ item.start_time.split(" ")[0] }}-{{
              item.end_time.split(" ")[0]
            }}</el-descriptions-item
          >
          <el-descriptions-item label="执教职位">{{
            item.position
          }}</el-descriptions-item>
          <el-descriptions-item label="执教内容">{{
            item.content
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <hr style="margin-bottom: 30px; margin-top: 10px" />
    <div style="font-size: 26px; color: #333333; margin-top: 30px">
      教练场地
    </div>
    <div style="margin: 20px 0">
      <el-button type="warning" @click="openVenue = true">添加场地</el-button>
      <!-- <el-button type="danger" @click="openBind">绑定机构管理</el-button> -->
    </div>
    <div class="flex flex-wrap">
      <div
        style="margin: 0 20px 20px 0"
        v-for="item in areaList"
        :key="item.id"
      >
        <div style="margin-bottom: 10px">
          <!-- <el-button type="warning" @click="area(item)" size="small">修改</el-button> -->
          <el-button
            type="danger"
            v-if="item.mechanism_id == 0"
            @click="areadelete(item)"
            size="small"
            >删除</el-button
          >
        </div>
        <el-descriptions :column="1" border>
          <el-descriptions-item label="场馆名称">
            <span>{{ item.name }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="场馆地址">{{
            item.address
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>

    <el-dialog title="执教经历" :visible.sync="dialogVisible" width="500px">
      <el-form label-position="left" label-width="80px">
        <el-form-item label="执教职位" required>
          <el-input
            v-model="Coaching.position"
            placeholder="例如游泳教练"
          ></el-input>
        </el-form-item>
        <el-form-item label="执教时间" required>
          <el-date-picker
            @change="Time"
            v-model="date_time"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="执教机构" required>
          <el-input
            v-model="Coaching.institution"
            placeholder="请输入执教机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="执教内容" required>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="Coaching.content"
            placeholder="例如主要负责教授蛙泳等"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addto">{{
            AddModification ? "添 加" : "修 改"
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="荣誉资质" :visible.sync="honoropen" width="500px">
      <el-form label-position="left" label-width="80px">
        <el-form-item label="荣誉资质" required>
          <el-input
            type="textarea"
            placeholder="此填写您所获得荣誉"
            v-model="Honorarynote"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="相关照片" required v-if="honoropen">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="Honorimg"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="honormodify">添加</el-button>
          <el-button type="danger" @click="honoropen = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="添加场地" :visible.sync="openVenue" width="30%">
      <el-form label-position="top" label-width="80px">
        <el-form-item label="场馆名称" required>
          <el-input
            v-model="Venuedx.name"
            placeholder="请输入场馆名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择地区" required key="addi2">
          <addr-select
            key="add2"
            @getform="coachVenueAddr"
            :addr="{
              province: Venuedx.province,
              city: Venuedx.city,
              district: Venuedx.district,
            }"
          ></addr-select>
        </el-form-item>
        <el-form-item label="城市" required>
          <el-cascader
            :options="city"
            v-model="vaddress"
            :placeholder="vaddress.join('/')"
            :props="{
              children: 'childrens',
              label: 'district',
              multiple: false,
              value: 'district',
            }"
            @change="coachCityChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" required>
          <el-input
            v-model="Venuedx.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openVenue = false">取 消</el-button>
        <el-button type="primary" @click="OKtoadd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="教师资格证" :visible.sync="teachPic" width="500px">
      <el-form label-position="left" label-width="80px">
        <el-form-item label="证书等级" required>
          <el-select v-model="Gradevl" placeholder="请选择">
            <el-option
              v-for="item in Grade"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关照片" required v-if="teachPic">
          <avatar-uploader
            customStyle="width:100px;height:100px;"
            @getImgUrl="Teachercertificateimg"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="addteachPic">添加</el-button>
          <el-button type="danger" @click="teachPic = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <coachBind ref="bind" @success="getCoachBind"></coachBind>
  </div>
</template>

<script>
import cityjs from "../../api/city.js";
import { jsonp } from "vue-jsonp";
import coachBind from "./components/coachBind.vue";
import videoUpload from "../../components/upload/videoUpload.vue";
export default {
  components: {
    coachBind,
    videoUpload,
  },
  data() {
    return {
      nav: { firstNav: "教练管理", secondNav: "修改教练信息" },
      formInline: {
        user: "",
        region: "",
      },
      user: {},
      usercontrast: {},
      sex: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      city: cityjs,
      Classtype: [
        {
          name: "海马场地",
          id: "1",
        },
        {
          name: "上门教学",
          id: "2",
        },
        {
          name: "自带场馆",
          id: "3",
        },
        {
          name: "陪练",
          id: "4",
        },
      ],
      main_categories:[{
        name:'陪练',
        value:'陪练'
      },{
        name:'商务',
        value:'商务'
      },{
        name:'休闲生活',
        value:'休闲生活'
      },{
        name:'儿童陪伴',
        value:'儿童陪伴'
      },{
        name:'陪诊陪护',
        value:'陪诊陪护'
      },{
        name:'棋牌',
        value:'棋牌'
      }],
      Class_type: [],
      crowd: [
        {
          name: "成人",
          id: "1",
        },
        {
          name: "学龄前",
          id: "2",
        },
        {
          name: "学龄",
          id: "3",
        },
        {
          name: "亲子",
          id: "4",
        },
      ],
      crowd_k: [],
      Range: [],
      service_kn: "",
      address: [],
      teaching_categories: [],
      category: [],
      dialogVisible: false,
      record: [],
      Coaching: {},
      date_time: [],
      AddModification: 1,
      Grade: [
        {
          id: "1",
          name: "初级",
        },
        {
          id: "2",
          name: "中级",
        },
        {
          id: " 3",
          name: "高级",
        },
        {
          id: "4",
          name: "特级",
        },
      ],
      honoropen: false,
      honorimgs: "",
      Honorarynote: "",
      honor_qualification_imgs: [],
      honor_qualification: [],
      areaList: [],
      Venuedx: {
        address: "", //场馆地址
        latitude: "",
        longitude: "",
        name: "",
        province: "",
        city: "",
        district: "",
      },
      coach_id: "",
      Venueaddress: "",
      openVenue: false,
      bindList: [],
      class_certificate: [],
      certificate_pic: [],
      Gradevl: "",
      certificate_img: "",
      teachPic: false,
      vaddress: [],
      partner_category: [],
      partner_categories: [],
      p_price: "",
      serviceList: [],
      tagList:[],
      curTag:'',
      inputVisible:false
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.coach_id = this.$route.query.id;
    }
    this.$get("user/mechanismCategory/queryListPageChild", {
      status: 2,
      type: 2,
      source: "课程",
    }).then((res) => {
      this.category = res.data.data;
    });
    

    for (let i = 0; i <= 50; i += 5) {
      this.Range.push({
        kn: i,
        k: i == 0 ? "不限" : i,
      });
    }
    this.request();
    this.getCoachRecode();
    this.getCoachBind();
  },
  methods: {
     handleClose(tag) {
        this.tagList.splice(this.tagList.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.curTag;
        if (inputValue) {
          this.tagList.push(inputValue);
        }
        this.inputVisible = false;
        this.curTag = '';
      },
    changeCategory(){
      this.$get("user/mechanismCategory/queryListPageChild", {
      status: 2,
      type: 2,
      source: this.user.main_category,
    }).then((res) => {
      this.partner_category = res.data.data;
    });
    },

    saveService(){
        let data = {
					coach_id : this.coach_id,
					datas:this.serviceList,
				}
				this.$post('/user/coachServicePrice/batchInsert',data).then(res=>{
          this.$message('保存成功')
				})
    },
    getServiceList() {
      let data = {
        coach_id: this.coach_id,
        status: 1,
      };
      this.$get("/user/coachServicePrice/queryListPage", data).then((res) => {
        if (res.data.data.length > 0) {
          this.serviceList = res.data.data.map((item) => {
            return {
              category: item.category,
              price: item.price,
            };
          });
        }
      });
    },
    craeteService() {
      let list = [];
      this.partner_categories.forEach((item) => {
        list.push({
          category: item,
          price: this.p_price,
        });
      });

      let data = {
        coach_id: this.coach_id,
        datas: list,
      };

      this.$post("/user/coachServicePrice/batchInsert", data).then((res) => {
        this.$message("成功");
      });
    },
    getVideo(v) {
      this.user.introduce_video = v;
    },
    getImges(v) {
      this.user.introduce_img = v;
    },
    getCollegeImg(v) {
      this.user.college_img = v;
    },
    deleteteachPic(e) {
      this.class_certificate.splice(e, 1);
      this.certificate_pic.splice(e, 1);
    },
    addteachPic() {
      if (this.Gradevl && this.certificate_img) {
        this.class_certificate.push(this.Gradevl);
        this.certificate_pic.push(this.certificate_img);
        this.teachPic = false;
        this.Gradevl = "";
        this.certificate_img = "";
      } else {
        this.$message.error("请补充信息");
      }
    },
    Teachercertificateimg(e) {
      this.certificate_img = e;
    },
    openBind() {
      this.$refs.bind.display(true, this.coach_id, this.bindList);
    },
    // 教练是否绑定机构
    getCoachBind() {
      this.$axios({
        url: "user/coachMechanismConnected/query",
        params: {
          coach_id: this.$route.query.id,
          mechanism_id: -1,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.bindList = res.data.data;
          this.getarea();
        }
      });
    },
    areadelete(e) {
      if (e.mechanism_id) {
        this.$message.error("该地址已绑定机构，请前往解绑");
      } else {
        this.$get("user/coachMechanismConnected/delete", {
          id: e.id,
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "删除成功" });
            this.getarea();
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    VenueChange(e) {
      this.Venueaddress = e.join(",");
    },
    coachCityChange(v) {
      this.Venuedx.province = v[0];
      this.Venuedx.city = v[1];
      this.Venuedx.district = v[2];
    },
    OKtoadd() {
      if (!this.Venuedx.name || !this.Venuedx.city || !this.Venuedx.address) {
        this.$message.error("请补充信息");
        return;
      }
      this.Venuedx.coach_id = this.coach_id;
      this.Venuedx.mechanism_id = 0;
      this.$post("user/mechanism/venue/insert", this.Venuedx).then((res) => {
        if (res.data.code == 0) {
          this.Venuedx = {
            address: "", //场馆地址
            latitude: "",
            longitude: "",
            name: "",
            province: "",
            city: "",
            district: "",
          };
          this.getarea();
          this.Venueaddress = "";
          this.openVenue = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 场馆详细地址
    Venueblur() {
      let url = "https://apis.map.qq.com/ws/geocoder/v1";
      const params = {
        output: "jsonp",
        address: this.Venueaddress + "," + this.Venuedx.mechanism_addr,
        key: "SWPBZ-NPE3S-TXFON-6PJQC-55L5V-2UBAC",
      };
      jsonp(url, params).then((res) => {
        console.log(res);
        if (res.status == 0) {
          this.Venuedx.mechanism_latitude = res.result.location.lat;
          this.Venuedx.mechanism_longitude = res.result.location.lng;
          this.Venuedx.mechanism_id = 0;
          this.Venuedx.coach_id = this.coach_id;
        } else {
          this.$message.error("获取地址失败,请重新输入");
        }
      });
    },
    getarea() {
      this.$get("user/mechanism/venue/query", {
        coach_id: this.$route.query.id,
      }).then((res) => {
        this.areaList = res.data.data;
      });
    },
    addMecAddr(addr) {
      if (addr.province) {
        this.address = [];
        this.$set(this.user, "province", addr.province);
        this.$set(this.user, "city", addr.city);
        this.$set(this.user, "district", addr.district);
        this.$set(this.user, "address", addr.address);
        this.address.push(addr.province);
        this.address.push(addr.city);
        this.address.push(addr.district);
      }

      this.$set(this.user, "latitude", addr.addrPoint.lat);
      this.$set(this.user, "longitude", addr.addrPoint.lng);
    },
    coachVenueAddr(addr) {
      this.vaddress = [];
      console.log(addr);
      this.$set(this.Venuedx, "province", addr.province);
      this.$set(this.Venuedx, "city", addr.city);
      this.$set(this.Venuedx, "district", addr.district);
      this.$set(this.Venuedx, "latitude", addr.addrPoint.lat);
      this.$set(this.Venuedx, "longitude", addr.addrPoint.lng);
      this.$set(this.Venuedx, "address", addr.address);
      this.vaddress.push(addr.province);
      this.vaddress.push(addr.city);
      this.vaddress.push(addr.district);
    },
    request() {
      this.$get("user/coach/findById", {
        id: this.$route.query.id,
      }).then((res) => {
          let data = res.data.data;
          this.user = data;
          this.user.sex = data.sex + "";
          this.usercontrast = Object.assign({}, data);
          if (data.teaching_type) {
            this.Class_type = data.teaching_type.split(",");
          }
          if(data.tags){
            this.tagList = data.tags.split(',')
          }
          if (data.people_service) {
            this.crowd_k = data.people_service.split(",");
            console.log(data.people_service);
          }
          if (data.teaching_categories) {
            this.teaching_categories = data.teaching_categories.split(",");
          }
          if (data.partner_categories) {
            this.partner_categories = data.partner_categories.split(",");
            this.partner_categories.forEach((item) => {
              this.serviceList.push({
                category: item,
                price: "",
              });
            });

            this.getServiceList();
          }

          this.service_kn = data.distance_service;
          this.address = [];
          this.address.push(data.province);
          this.address.push(data.city);
          this.address.push(data.district);
          if (data.certificate_pic) {
            this.certificate_pic = data.certificate_pic.split(",");
            this.class_certificate = data.class_certificate
              .toString()
              .split(",");
          }
          // this.Gradevl = data. + ''
          if (data.honor_qualification_imgs) {
            this.honor_qualification_imgs =
              data.honor_qualification_imgs.split(",");
            this.honor_qualification = data.honor_qualification.split(",");
          }
      });
    },

    ModifyCoach() {
      this.$confirm("确定要修改教练信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.honor_qualification_imgs.length) {
          this.user.honor_qualification_imgs =
            this.honor_qualification_imgs.join(",");
          this.user.honor_qualification = this.honor_qualification.join(",");
        }
        if (this.class_certificate.length) {
          this.user.class_certificate = this.class_certificate.join(",");
          this.user.certificate_pic = this.certificate_pic.join(",");
        }
        this.user.tags = this.tagList.join(',')
        let data = this.ObjectChangedValue(this.usercontrast, this.user);
        console.log(Object.keys(data).length);
        if (Object.keys(data).length <= 0) {
          this.$message("没有修改的信息");
          return;
        }
        data.id = this.$route.query.id;
        this.$post("user/coach/update", data).then((res) => {
          this.$message({ message: "修改成功" });
          this.$router.back();
        });
      });
    },
    honoreliminate(e) {
      this.honor_qualification_imgs.splice(e, 1);
      this.honor_qualification.splice(e, 1);
    },
    honormodify() {
      if (this.honorimgs && this.Honorarynote) {
        this.honor_qualification_imgs.push(this.honorimgs);
        this.honor_qualification.push(this.Honorarynote);
        this.honor_qualification_imgs = [...this.honor_qualification_imgs];
        this.honor_qualification = [...this.honor_qualification];
        this.honoropen = false;
        this.honorimgs = "";
        this.Honorarynote = "";
      }
    },
    Honorimg(e) {
      this.honorimgs = e;
    },

    //执教经历
    getCoachRecode() {
      let url = "/user/coachTeachingRecord/queryListPage";
      let params = {
        coach_id: this.$route.query.id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.record = res.data.data;
        });
    },
    eliminate(e) {
      if (this.record.length != 1) {
        this.$get("user/coachTeachingRecord/delete", {
          id: e.id,
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "删除成功" });
            this.getCoachRecode();
          } else {
            this.$message({ message: res.data.message });
          }
        });
      }
    },
    Time(e) {
      this.Coaching.coach_id = this.$route.query.id;
      this.Coaching.start_time = e[0];
      this.Coaching.end_time = e[1];
    },
    modify(e) {
      this.date_time.push(e.start_time);
      this.date_time.push(e.end_time);
      this.Coaching = e;
      this.dialogVisible = true;
      this.AddModification = 0;
    },
    addto() {
      if (this.AddModification) {
        if (
          Object.keys(this.Coaching).length == 6 &&
          !Object.values(this.Coaching).includes("")
        ) {
          this.$post("user/coachTeachingRecord/insert ", this.Coaching).then(
            (res) => {
              if (res.data.code == 0) {
                this.$message({ message: "添加成功" });
                this.getCoachRecode();
                this.dialogVisible = false;
                this.Coaching = {};
                this.date_time = [];
              } else {
                this.$message({ message: res.data.message });
              }
            }
          );
        }
      } else {
        if (!Object.values(this.Coaching).includes("")) {
          this.$post("user/coachTeachingRecord/update", this.Coaching).then(
            (res) => {
              if (res.data.code == 0) {
                this.$message({ message: "修改成功" });
                this.getCoachRecode();
                this.dialogVisible = false;
                this.Coaching = {};
                this.date_time = [];
              } else {
                this.$message({ message: res.data.message });
              }
            }
          );
        }
      }
    },
    getImgUrl(e) {
      this.user.head_portrait = e;
    },
    handleChange(e) {
      let url = "https://apis.map.qq.com/ws/geocoder/v1";
      const params = {
        output: "jsonp",
        address: e.join(","),
        key: "SWPBZ-NPE3S-TXFON-6PJQC-55L5V-2UBAC",
      };
      jsonp(url, params).then((res) => {
        this.user.latitude = res.result.location.lat;
        this.user.longitude = res.result.location.lng;
        this.user.province = res.result.address_components.province;
        this.user.city = res.result.address_components.city;
        this.user.district = res.result.address_components.district;
      });
    },
    CategoryEvents(e) {
      this.user.teaching_categories = e.join(",");
    },
    partnerCategoryEvents(e) {
      this.user.partner_categories = e.join(",");
      let obj = {}
      let list = []
      this.serviceList.forEach(item=>{
        obj[item.category] = item.price
      })
      e.forEach(item=>{
        if(obj[item]){
             list.push({category:item,price: obj[item] })
        }else{
              list.push({category:item,price:0})
        }
      })
    
      this.serviceList = list

    },
    kn(e) {
      this.user.distance_service = e;
    },
    crowdCH(e) {
      this.user.people_service = e.join(",");
    },
    ClassCH(e) {
      this.user.teaching_type = e.join(",");
    },
  },
};
</script>

<style lang="less" scoped>

.honor-box {
  width: 100px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;

  .honor-tb {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .img {
    width: 100px;
    height: 100px;
  }
}

.h-border {
  height: 1px;
  margin-bottom: 30px;
  margin-top: 10px;
  background-color: rgb(175, 174, 174);
}

.row {
  width: 100%;
  display: flex;

  .el-form-item {
    width: 30%;
  }
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.foot {
  position: fixed;
  bottom: 0;
 right: 30px;
 left: 240px;
  padding: 20px 0;
  background-color: #fff;
}
.service-item{
  margin-bottom: 6px;
}
.service-name{
		min-width: 100px;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
	}
</style>